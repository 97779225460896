/* eslint-disable no-undef */
import { Dialog, Transition } from "@headlessui/react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { Formik } from "formik";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useUploadProfileMutation,
  useUserProfileQuery,
} from "store/Actions/authAction";
import * as Yup from "yup";
import userImage from "Assets/user.png";
import { loggedUserRole } from "utils/functions";

const UploadProfileForm = () => {
  const schema = Yup.object({});
  const [upload, setUpload] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [image, setImage] = useState("");
  const { data } = useUserProfileQuery();
  const [uploadProfile] = useUploadProfileMutation();

  useEffect(() => {
    setCurrentUser(data?.data.user);
  }, [data]);

  function closeModal() {
    setIsOpen(false);
    setImage("");
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <div className=" flex flex-col   visible w-full h-auto min-w-0 p-4 break-words bg-white border-0 opacity-100 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border lg:w-4/12 m-4 items-center xl:max-h-85 lg:max-h-96">
      <Formik
        enableReinitialize
        initialValues={{
          profile: currentUser?.profile || "",
        }}
        onSubmit={async (values) => {
          values.profile = upload;
          await uploadProfile(values).then((res) => {
            if (res.error) {
              toast.error(res.error.data.message);
            } else {
              toast.success(res.data?.message);
              setIsOpen(false);
            }
          });
          setImage("");
        }}
        validationSchema={schema}
      >
        {({ values, handleBlur, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className="w-full">
              <div className="w-full flex justify-center mb-5">
                <img
                  src={
                    currentUser?.profile
                      ? currentUser?.profile.startsWith("https://")
                        ? currentUser?.profile
                        : process.env.REACT_APP_UPLOADS + values?.profile
                      : userImage
                  }
                  alt="avatar image"
                  className="inline-flex items-center justify-center mr-2 text-white transition-all duration-200 ease-in-out w-50 h-50 text-sm rounded-circle preview-img"
                />
              </div>
              <h1 className="text-2xl text-center text-slate-700 font-bold mb-5">
                {loggedUserRole() == "Brokerage"
                  ? currentUser?.brokerage_name || ""
                  : (currentUser?.first_name || currentUser?.last_name) &&
                    currentUser?.first_name + " " + currentUser?.last_name}
              </h1>

              <div className=" flex items-center justify-center">
                <button
                  type="button"
                  onClick={openModal}
                  className="rounded-md  px-4 py-2 text-sm font-medium text-black border-[1px] w-full border-black/30 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
                >
                  Update image
                </button>
              </div>
              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black/25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900 "
                          >
                            Upload Image
                          </Dialog.Title>

                          <div className="mt-2 flex h-40 justify-evenly w-full">
                            <div className="h-2/3 w-1/4 rounded-full bg-slate-50 relative flex items-center justify-center p-5">
                              <label
                                htmlFor="profile"
                                className="cursor-pointer"
                              >
                                {/* Heroicon upload icon */}
                                <ArrowUpTrayIcon className="h-full w-full text-slate-700" />
                                {/* Text or additional styling can be added here */}
                              </label>
                              <input
                                name="profile"
                                type="file"
                                id="profile"
                                className="hidden"
                                accept="image/png, image/gif, image/jpeg , image/jpg  "
                                onChange={(e) => {
                                  setUpload(e.target.files[0]);
                                  setImage(
                                    URL.createObjectURL(event.target.files[0]),
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className="h-2/3 w-1/4  rounded-circle ">
                              <img
                                src={
                                  image
                                    ? image
                                    : values?.profile
                                      ? currentUser?.profile.startsWith(
                                          "https://",
                                        )
                                        ? currentUser?.profile
                                        : process.env.REACT_APP_UPLOADS +
                                          values?.profile
                                      : userImage
                                }
                                alt="avatar image"
                                className="h-full w-full rounded-circle preview-img"
                              />
                            </div>
                          </div>

                          <div className=" flex justify-end gap-3">
                            <button
                              type="submit"
                              className="inline-flex justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-blue-500 hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={closeModal}
                            >
                              cancel
                            </button>
                            <button
                              type="submit"
                              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={handleSubmit}
                            >
                              Upload
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UploadProfileForm;
