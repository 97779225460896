import 'react-loading-skeleton/dist/skeleton.css';
import './SkeletonLoaderFullpage.css'; // Import CSS file for styling
import { ClipLoader } from 'react-spinners';

const SkeletonLoaderFullpage = ({ loading }) => {
  return (
    <div className={`overlay ${loading ? 'show' : ''}`}>
      <div className="loader-container">
      <div className="min-h-50 flex justify-center items-center">
          <div className="flex items-center justify-center">
            <ClipLoader size={70} color={`#4821d1`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoaderFullpage;
