
const BrowserImg = ({ browser}) => {

    const browserList=["Chrome",
    "Opera",
    "Safari",
    "Edge",
    "DuckDuckGo",
    "Nokia",
    "Firefox",
    "Yahoo",!
    "Ruby",
    "Alexa",
    "Facebook",
    "Google",
    "Bing",
    "Samsung",
    "Chromium",
    "Grammarly",
    "Huawei",
    "BlackBerry",
    "UC",
    "Internet",
    "Vivaldi",
    "Pinterest",
    "Googlebot"
]
    if(browserList.includes(browser?.split(" ")[0])){
        return <img src={`/BrowserLogo/${browser?.split(" ")[0]}.svg`}  className="h-5 w-5"/>
    }else{
        return <img src="/BrowserLogo/Browser.svg" className="h-5 w-5" />
    }
}

export default BrowserImg

