import CardList from "Components/CardList/Index";
import { useEffect, useState } from "react";
import { useSaveSearchListQuery } from "store/Actions/guestSaved";
import EditModal from "./EditModal";
import PrimaryButton from "Components/Butttons/PrimaryButton";

const SavedSearch = () => {
  const [openModal, setOpenModal] = useState(false);
  const [defaultData, setDefaultData] = useState("");

  const [filter, setFilter] = useState({
    perPage: 9,
    page: 1,
  });

  useEffect(() => {
    if (!openModal) {
      setDefaultData("");
    }
  }, [openModal]);

  const { data, refetch, isLoading } = useSaveSearchListQuery(filter);

  return (
    <div>
      <div className="lg:flex">
        <div className="flex justify-center w-full my-5">
          <h5 className=" dark:text-white font-bold text-3xl text-blue-800">
            Saved Search
          </h5>
          <p className="mb-0 leading-normal text-sm"></p>
        </div>
      </div>
      <div className="lg:flex justify-end px-12">
        <div className="my-auto flex ml-auto lg:mt-0 ">
          <div className="my-auto ml-auto">
            <PrimaryButton
              text=" Create Search"
              event={() => setOpenModal(true)}
            />
          </div>
        </div>
      </div>
      {data?.data.total > 0 ? (
        <CardList
          ListQuery={{ data: data?.data, isLoading: isLoading, refetch }}
          filterProps={{ filter, setFilter }}
          setOpenModal={setOpenModal}
          setDefaultData={setDefaultData}
        />
      ) : (
        <div className="flex justify-center my-6">
          {" "}
          <h5 className="dark:text-white font-bold text-xl text-center text-red-800 w-240">
            Please fill out the search form and save your criteria to be
            notified when listings matching your search criteria hit the market!
          </h5>
        </div>
      )}
      <EditModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        defaultData={defaultData}
      />
    </div>
  );
};

export default SavedSearch;
