import ActivityLog from "pages/Activity/Index";
import BoatInquiry from "pages/BoatInquiry/Index";
import BrandsAnalytics from "pages/BrandsAnalytics/Index";
import BrokerDetail from "pages/Broker/BrokerDetail";
import CreateBroker from "pages/Broker/CreateBroker";
import BrokerList from "pages/Broker/Index";
import BrokerageDetail from "pages/Brokerage/BrokerageDetail";
import CreateBrokerage from "pages/Brokerage/CreateBrokerage";
import BrokerageList from "pages/Brokerage/Index";
import CategoriesAnalytics from "pages/CategoriesAnalytics/Index";
import AccountDetails from "pages/DetailsPage/AccountDetails";
import FollowedYachts from "pages/FollowedYachts/Index";
import GuestDetailsAnalytics from "pages/GuestAnalytics/GuestDetailsAnalytics";
import GuestAnalytics from "pages/GuestAnalytics/Index";
import Login from "pages/Login/Index";
import Profile from "pages/Profile/Index";
import Visiters from "pages/Reports/Index";
import VesselsDetails from "pages/Reports/VesselsDetails";
import ResetPassword from "pages/ResetPassword/Index";
import Roles from "pages/Role/Index";
import SavedSearch from "pages/SavedSearch/Index";
import SearchHistory from "pages/SearchHistory/Index";
import SearchText from "pages/SearchText/Index";
import Setting from "pages/Setting/Index";
import VesselAnalyticsGraph from "pages/VesselAnalyticsGraph/Index";
import AdminVesselsAnalytics from "pages/VesselsAnalytics/Index";
import VesselDetails from "pages/DetailsPage/VesselDetails";
import VisitorsAnalytics from "pages/VisitorsAnalytics/Index";
import VisitorsDetailsAnalytics from "pages/VisitorsAnalytics/VisitorsDetailsAnalytics";
import ForgetPassword from "pages/forgetPassword/Index";
import { Navigate } from "react-router-dom";
import Dashboard from "pages/Dashboard/Index";
import Blogs from "pages/Blogs/Index";
import BlogDetail from "pages/Blogs/BlogDetails";

export const routesData = [
  // Authenticate require as per need

  {
    name: "Dashboard",
    path: "/",
    element: <Dashboard />,
    accessTo: ["Admin", "Brokerage", "Broker"],
  },
  {
    name: "Roles",
    path: "/roles",
    element: <Roles />,
    accessTo: ["Admin"],
  },

  {
    name: "Brokerage List",
    path: "/brokerage",
    element: <BrokerageList />,
    accessTo: ["Admin"],
  },
  {
    name: "Create Brokerage",
    path: "/brokerage/create",
    element: <CreateBrokerage />,
    accessTo: ["Admin"],
  },
  {
    name: "Edit Brokerage",
    path: "/brokerage/edit/:id",
    element: <CreateBrokerage />,
    accessTo: ["Admin"],
  },
  {
    name: "View Brokerage",
    path: "/brokerage/view/:id",
    element: <BrokerageDetail />,
    accessTo: ["Admin"],
  },
  {
    name: "Broker List",
    path: "/broker",
    element: <BrokerList />,
    accessTo: ["Brokerage"],
  },
  {
    name: "Create Broker",
    path: "/broker/create",
    element: <CreateBroker />,
    accessTo: ["Brokerage"],
  },
  {
    name: "Edit Broker",
    path: "/broker/edit/:id",
    element: <CreateBroker />,
    accessTo: ["Brokerage"],
  },
  {
    name: "View Broker",
    path: "/broker/view/:id",
    element: <BrokerDetail />,
    accessTo: ["Brokerage"],
  },
  {
    name: "Visitors Report",
    path: "/reports/visitors",
    element: <Visiters />,
    accessTo: ["Brokerage", "Broker"],
  },
  {
    name: "Vessels Report",
    path: "/reports/visitors/view/:vesselId",
    element: <VesselsDetails />,
    accessTo: ["Brokerage", "Broker"],
  },

  {
    name: "My Profile",
    path: "/profile",
    element: <Navigate to="/my-profile" />,
    accessTo: ["Brokerage", "Admin", "Broker", "Guest"],
  },
  {
    name: "My Profile",
    path: "/my-profile",
    element: <Profile />,
    accessTo: ["Brokerage", "Admin", "Broker", "Guest"],
  },

  {
    name: "Activity Log",
    path: "/activity",
    element: <ActivityLog />,
    accessTo: ["Admin"],
  },
  {
    name: "Followed Yachts",
    path: "/followed-yachts",
    element: <FollowedYachts />,
    accessTo: ["Guest"],
  },
  {
    name: "My Inquiries",
    path: "/my-inquiries",
    element: <BoatInquiry />,
    accessTo: ["Guest"],
  },
  {
    name: "Saved Search Notifications",
    path: "/saved-search",
    element: <SavedSearch />,
    accessTo: ["Guest"],
  },
  {
    name: "Setting",
    path: "/setting",
    element: <Setting />,
    accessTo: ["Admin"],
  },
  {
    name: "Boat Inquiry",
    path: "/boat-inquiry",
    element: <BoatInquiry />,
    accessTo: ["Brokerage", "Broker"],
  },
  {
    name: "Categories Analytics",
    path: "/categories-analytics",
    element: <CategoriesAnalytics />,
    accessTo: ["Admin"],
  },
  {
    name: "Brands Analytics",
    path: "/brands-analytics",
    element: <BrandsAnalytics />,
    accessTo: ["Admin"],
  },
  {
    name: "Search Text",
    path: "/search-text",
    element: <SearchText />,
    accessTo: ["Admin"],
  },
  {
    name: "Search History",
    path: "/search-history",
    element: <SearchHistory />,
    accessTo: ["Admin"],
  },
  // {
  //   name: "Google Analytics",
  //   path: "/google-analytics",
  //   element: <GoogleAnalytics />,
  //   accessTo: ["Admin"],
  // },
  {
    name: "Brokerage Analytics",
    path: "/brokerage-analytics",
    element: <VesselAnalyticsGraph />,
    accessTo: ["Admin"],
  },
  {
    name: "Vessels Analytics",
    path: "/vessels-analytics",
    element: <AdminVesselsAnalytics />,
    accessTo: ["Admin"],
  },
  {
    name: "Vessel Details",
    path: "/vessel-details/view/:vesselId",
    element: <VesselDetails />,
    accessTo: ["Admin", "Broker", "Brokerage"],
  },
  {
    name: "Visitors Analytics",
    path: "/visitors-analytics",
    element: <VisitorsAnalytics />,
    accessTo: ["Admin"],
  },
  {
    name: "Visitor Data",
    path: "/visitors-analytics/view/:deviceId",
    element: <VisitorsDetailsAnalytics />,
    accessTo: ["Admin"],
  },
  {
    name: "Guest Analytics",
    path: "/guest-analytics",
    element: <GuestAnalytics />,
    accessTo: ["Admin"],
  },
  {
    name: "Guest Data",
    path: "/guest-analytics/view/:deviceId/:userId",
    element: <GuestDetailsAnalytics />,
    accessTo: ["Admin"],
  },
  {
    name: "Vessel Inquiries",
    path: "/vessel-inquiries",
    element: <BoatInquiry />,
    accessTo: ["Admin"],
  },
  {
    name: "Blogs",
    path: "/blogs",
    element:  <Blogs />,
    accessTo: ["Admin"],
  },
  {
    name: "Blogs",
    path: "/blog/view/:blogId",
    element:  <BlogDetail />,
    accessTo: ["Admin"],
  },
  {
    name: "Account Details",
    path: "/account-details/view/:deviceId",
    element: <AccountDetails />,
    accessTo: ["Admin", "Brokerage", "Broker"],
  },
  //   ... as per need
];

export const openRoute = [
  {
    name: "Login",
    path: "/login",
    element: <Login />,
  },
  {
    name: "Forget Password",
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    name: "Reset Password",
    path: "/reset-password/:resetToken",
    element: <ResetPassword />,
  },
];
