import LoginButton from "Components/Butttons/LoginButton";
import CustomInput from "Components/Input/Input";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "store/Actions/authAction";
import * as Yup from "yup";

export default function LoginForm() {
  // Creating schema
  const schema = Yup.object({
    username: Yup.string()
      .required("Username is required!!"),
    password: Yup.string().required("Password required!"),
  });
  const nav = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  return (
    <div>
      <Formik
        initialValues={{
          username: "",
          password: "",
          remember: true,
        }}
        onSubmit={async (values) => {
          const payload = {
            ...values,
            remember: true,
          };
          !isLoading &&
            login(payload).then((res) => {
              if (res.error) {
                toast.error(res.error.data.message);
              } else {
                localStorage.setItem("token", res.data?.data?.token);
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(res.data?.data),
                );
                toast.success(res?.data?.message);
                if(res?.data?.data?.role==="Guest"){
                  nav("/followed-yachts");
                }else{

                  nav("/");
                }
              }
            });
        }}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <CustomInput
                  name="username"
                  values={values}
                  type="text"
                  label="username"
                  placeholder="username"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  isRequire={true}
                />
              </div>
              <div className="mb-4">
                <CustomInput
                  name="password"
                  values={values}
                  type="password"
                  label="Password"
                  placeholder="password"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  isRequire={true}
                />
              </div>
              
              <div className="text-center">
                <LoginButton isLoading={isLoading} text="Sign In" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}
