import moment from "moment";

export const loggedUserRole = () => {
  if (localStorage.getItem("userInfo")) {
    return JSON.parse(localStorage.getItem("userInfo")).role;
  }
};

export const loggedUserCode = () => {
  if (localStorage.getItem("userInfo")) {
    return JSON.parse(localStorage.getItem("userInfo")).user_code;
  }
};

export const loggedBackId = () => {
  if (localStorage.getItem("userInfo")) {
    return JSON.parse(localStorage.getItem("userInfo")).user_id;
  }
};

export const remainingWords = (inputString, totalWordCount) => {
  const word = totalWordCount - inputString.split("").length;
  if (word > 0) {
    return { val: true, text: ` ${word}  characters left` };
  } else {
    return { val: false, text: `last ${Math.abs(word)} will be skipped` };
  }
};

export const currentYear = () => {
  return moment().year();
};
export const currentMonth = () => {
  return moment().month() + 1;
};
export const currentDate = () => {
  return moment().date();
};
export const getBlogImagePrefix=(imagePath)=>{
  return `${process.env.REACT_APP_UPLOADS+imagePath}`
}