import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrimaryButton from "Components/Butttons/PrimaryButton";
import SkeletonLoaderFullpage from "Components/Loader/SkeletonLoaderFullpage";
import DataTable from "Components/Table/Index";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  useBrokerageDeleteMutation,
  useBrokerageListQuery,
} from "store/Actions/brokerageManagment";
import { useSwitchToBrokerageMutation } from "store/Actions/switchAction";
import Swal from "sweetalert2";

const BrokerageList = () => {
  const [filter, setFilter] = useState({
    sort: "brokerage_name",
    order: "asc",
    perPage: 50,
    page: 1,
    search: "",
  });
  const { data, refetch, isFetching, isLoading } =
    useBrokerageListQuery(filter);
  const nav = useNavigate();
  const [brokerageDelete, brokerageDeleteAPI] = useBrokerageDeleteMutation();
  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        brokerageDelete(id).then((res) => {
          if (res.error) {
            toast.error(res.error.data.message);
          } else {
            toast.success(res.data?.message);
            if (data?.data?.data.length === 1) {
              setFilter((filter) => ({
                ...filter,
                page: data.data.last_page - 1,
              }));
            } else {
              refetch();
            }
          }
        });
      }
    });
  };
  const [switchToBrokerage, switchToBrokerageAPI] =
    useSwitchToBrokerageMutation();
  const switchUser = (id) => {
    switchToBrokerage(id).then((res) => {
      if (res.error) {
        toast.error(res.error.data.message);
      } else {
        localStorage.setItem("token", res.data?.data?.token);
        localStorage.setItem("userInfo", JSON.stringify(res.data?.data));
        toast.success(res?.data?.message);
        nav("/broker");
      }
    });
  };
  const columns = useMemo(
    () => [
      {
        header: "brokerage name",
        accessor: "brokerage_name",
      },
      {
        header: "contact person",
        accessor: "contact_person",
      },
      {
        header: "email",
        accessor: "email",
      },
      {
        header: "brokerage id",
        accessor: "related_entity_id",
      },

      {
        header: "zip code",
        accessor: "zip_code",
      },
      {
        header: "country",
        accessor: "country",
      },

      {
        header: "status",
        accessor: "status",
        Cell: ({ cell: { value } }) => {
          return (
            <span
              className={`py-2.2 px-3.6 text-xs rounded-1.8 inline-block whitespace-nowrap text-center ${
                value === "active"
                  ? "bg-lime-200 text-lime-600 "
                  : "bg-red-200 text-red-600 "
              } align-baseline font-bold capitalize leading-none`}
            >
              {value}
            </span>
          );
        },
      },

      {
        header: "Action",
        accessor: "id",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="flex gap-2  items-center  font-semibold ">
              <span
                className="cursor-pointer hover:text-orange-600 hover:underline "
                onClick={() => nav(`/brokerage/view/${value}`)}
              >
                view
              </span>
              {" | "}
              <span
                className="cursor-pointer hover:text-blue-600  hover:underline  "
                onClick={() => nav(`/brokerage/edit/${value}`)}
              >
                Edit
              </span>
              {" | "}
              <span
                className="cursor-pointer hover:text-red-600  hover:underline "
                onClick={() => onDelete(value)}
              >
                Delete
              </span>
              {" | "}
              <span
                className="cursor-pointer hover:text-green-600  hover:underline "
                onClick={() => switchUser(value)}
                title="Switch to Brokerage"
              >
                <FontAwesomeIcon icon={faUserSecret} />
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <>
      <div className="flex flex-wrap my-6 -mx-3">
        <div className="w-full max-w-full px-3 flex-0">
          <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
              <div className="lg:flex">
                <div>
                  <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                    All Brokerage
                  </h5>
                  <p className="mb-0 leading-normal text-sm"></p>
                </div>
                <div className="my-auto flex ml-auto lg:mt-0 ">
                  <div className="my-auto ml-auto">
                    <PrimaryButton
                      event={() => nav("/brokerage/create")}
                      text=" Add brokerage"
                    />
                  </div>
                </div>
              </div>
            </div>

            <DataTable
              ListQuery={{ data: data?.data, isLoading, isFetching }}
              filterProps={{ filter, setFilter }}
              columns={columns}
              sortingField={["brokerage_name", "contact_person", "status"]}
              searchFields={[
                "brokerage_name",
                "email",
                "contact_person",
                "related_entity_id",
              ]}
            />
          </div>
        </div>
      </div>
      <SkeletonLoaderFullpage
        loading={
          switchToBrokerageAPI?.isLoading || brokerageDeleteAPI?.isLoading
        }
      />
    </>
  );
};

export default BrokerageList;
