import SubmitButton from "Components/Butttons/SubmitButton";
import CustomInput from "Components/Input/Input";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useChangePasswordMutation } from "store/Actions/authAction";
import * as Yup from "yup";

const ChangePasswordFrom = () => {
  const schema = Yup.object({
    password: Yup.string().min(6, "Required").required("old password Required"),
    new_password: Yup.string()
      .min(6, "Required")
      .required("new password Required"),
    confirm_password: Yup.string().required("Please confirm your password"),
  });

  const nav = useNavigate();
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  return (
    <div className=" flex flex-col visible w-full h-auto min-w-0 p-4 break-words bg-white border-0 opacity-100 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border lg:w-8/12 m-4  ">
      <Formik
        enableReinitialize
        initialValues={{
          password: "",
          new_password: "",
          confirm_password: "",
        }}
        onSubmit={async (values) => {
          !isLoading &&
            changePassword(values).then((res) => {
              if (res.error) {
                toast.error(res.error.data.message);
              } else {
                toast.success(res.data?.message);
                nav("/myprofile");
              }
            });
        }}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <div>
                  <div className="flex flex-wrap mt-4 -mx-3">
                    <div className="w-full max-w-full  px-3 flex-0 ">
                      <CustomInput
                        name="password"
                        values={values}
                        type="password"
                        label="Password"
                        placeholder="old password"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        isRequire={true}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap mt-4 -mx-3">
                    <div className="w-full max-w-full  px-3 flex-0 ">
                      <CustomInput
                        name="new_password"
                        values={values}
                        type="password"
                        label="new password"
                        placeholder="new password"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        isRequire={true}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap mt-4 -mx-3">
                    <div className="w-full max-w-full  px-3 flex-0 ">
                      <CustomInput
                        name="confirm_password"
                        values={values}
                        type="password"
                        label="Confirm password"
                        placeholder="Confirm password"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        isRequire={true}
                      />
                    </div>
                  </div>

                  <div className="flex mt-6">
                    <SubmitButton isLoading={isLoading} text="submit" />
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChangePasswordFrom;
