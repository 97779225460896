import { useEffect, useState } from "react";
import { loggedUserRole } from "utils/functions";
import BrokerageReport from "./BrokerageReport";
import BrokerReport from "./BrokerReport";

const Visiters = () => {
  const [role, setRole] = useState(null);
  useEffect(() => {
    setRole(loggedUserRole());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserRole()]);

  if (role === "Brokerage") {
    return <BrokerageReport />;
  } else if (role === "Broker") {
    return <BrokerReport />;
  }
};

export default Visiters;
