// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating brokerManagement by enhancing and injecting endpoints using serverApi
const brokerAnalytics = serverApi
  .enhanceEndpoints({ tagTypes: [""] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of brokers
      brokerVessels: builder.query({
        query(filter) {
          return {
            url: `vessel-analytics?page=${filter.page || 1}&per_page=${
              filter?.perPage || 50
            }&sort_field=${filter?.sort || ""}&sort_order=${
              filter?.order || ""
            }&search_value=${filter.searchValue || ""}&search_field=${
              filter.searchField || ""
            }&start_date=${filter?.start_date || ""}&end_date=${
              filter?.end_date || ""
            } `,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: ["vessels"],
        keepUnusedDataFor: 1,
      }),
      brokerVesselsDetails: builder.query({
        query(val) {
          return {
            url: `broker-vessel-analytics-details/${ val?.vesselId}?page=${val?.filter.page || 1}&per_page=${
              val?.filter?.perPage || 50
            }&start_date=${val?.filter?.start_date || ""}&end_date=${
              val?.filter?.end_date || ""
            }`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: ["vessels"],
        keepUnusedDataFor: 1,
      }),
      brokerVesselsList: builder.query({
        query(filter) {
          return {
            url: `broker-vessel-list?page=${filter.page || 1}&per_page=${
              filter?.perPage || 50
            }&search_value=${filter.searchValue || ""}&search_field=${
              filter.searchField || ""
            }&start_date=${filter?.start_date || ""}&end_date=${
              filter?.end_date || ""
            } `,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: ["vessels"],
        keepUnusedDataFor: 1,
      }),
    }),
  });

export const { useBrokerVesselsQuery ,useBrokerVesselsDetailsQuery , useBrokerVesselsListQuery } = brokerAnalytics;
