import HighChart from "Components/Chart/HighChart";
import SkeletonLoader from "Components/Loader/SkeletonLoader";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAdminVesselsQuery } from "store/Actions/adminAnalytics";
import { loggedUserRole } from "utils/functions";

const VesselsGraph = ({ duration }) => {
  const [chartOptions, setChartOptions] = useState(null);
  const [filter, setFilter] = useState({
    sort: "visitor_counts",
    order: "desc",
    start_date: moment(
      new Date() - Number(duration) * 24 * 60 * 60 * 1000
    ).format("MM-DD-Y"),
    end_date: moment(new Date()).format("MM-DD-Y"),
  });
  useEffect(() => {
    const endDate = moment(new Date()).format("MM-DD-Y");
    const startDate = moment(
      new Date() - Number(duration) * 24 * 60 * 60 * 1000
    ).format("MM-DD-Y");
    setFilter(() => {
      return {
        ...filter,
        start_date: startDate,
        end_date: endDate,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isLoading } = useAdminVesselsQuery(filter, {
    skip: !filter || !filter?.start_date,
  });

  useEffect(() => {
    if (data) {
      var categories = [];
      if (data?.data?.data && !isLoading) {
        categories = data?.data?.data
          ?.slice(0, 50)
          .map(
            (val) =>
              `${val.vessel_id} ${loggedUserRole() === "Admin" ? val.brokerage_name : loggedUserRole() === "Brokerage" ? val.broker_name : ""} - ${val.display_length_feet}' ${val.manufacturer}  ${val.year} `
          );
      }
      const sData = [
        {
          name: "visitors",
          data: data?.data?.data
            ?.slice(0, 50)
            .map((val) => Number(val.visitor_counts)),
        },
        {
          name: "guest",
          data: data?.data?.data
            ?.slice(0, 50)
            .map((val) => Number(val.guest_counts)),
        },
      ];
      const options = {
        rangeSelector: {
          selected: 4,
        },
        chart: {
          renderTo: "chartContainer",
          type: "column",
          showAxes: true,
          marginRight: 0,
          spacingRight: 0,
          plotAreaHeight: 300,
        },
        title: {
          text: `Vessels Analytics`,
        },
        xAxis: {
          allowDecimals: false,
          min: 0,

          minPadding: 2,
          maxPadding: 2,
          categories: [...categories],
          tickLength: 0,
          lineColor: "#efefef",
          labels: {
            rotation: -90,
            align: "right",
            style: {
              fontSize: "13px",
              fontFamily: "Verdana, sans-serif",
            },
            formatter: function () {
              return this.value.length > 8
                ? `${this.value.substring(6, 0)}...`
                : this.value;
            },
          },
        },
        yAxis: {
          allowDecimals: false,
          gridLineColor: "#ffffff",
          showFull: false,
          title: {
            text: "",
          },
          stackLabels: {
            enabled: false,
          },
        },
        tooltip: {
          enabled: true,
          formatter: function () {
            let tooltipContent = `<div style="height:130px; text-transform: capitalize;"> <b>${this?.x}</b><br/>`;
            tooltipContent += ` ${this.series.name} count: ${this?.y}<br/></div> `;

            return tooltipContent;
          },
        },
        legend: {
          enabled: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            stacking: "normal",
            cursor: "pointer",
            borderRadius: 8,
            turboThreshold: 5000, //set it to a larger threshold, it is by default to 1000
          },
          column: {
            clip: false,
          },
        },
        series: [...sData],
      };

      setChartOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-4/12 lg:flex-none">
      <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid dark:bg-gray-950 border-black-125 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border p-2 ">
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <HighChart chartOptions={chartOptions} />
        )}
        <div className="flex justify-center  text-sm font-semibold">
          {" "}
          {filter.start_date && (
            <span>{`${filter.start_date} to ${filter.end_date}`}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default VesselsGraph;
