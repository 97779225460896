import ButtonLoader from "Components/Loader/ButtonLoader";

const SubmitButton = ({ isLoading, text, width }) => {
  return (
    <button
      disabled={isLoading}
      type="submit"
      className={`inline-block  ${
        width ? width : ""
      } px-6 py-3 mb-0 ml-auto font-bold  text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer hover:scale-102 active:opacity-85 hover:shadow-soft-xs dark:bg-gradient-to-tl dark:from-slate-850 dark:to-gray-850 bg-gradient-to-tl from-gray-900 to-slate-800 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 text-center justify-center  `}
    >
      {isLoading ? <ButtonLoader /> : text}
    </button>
  );
};

export default SubmitButton;
