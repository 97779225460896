import { ArrowLeftEndOnRectangleIcon } from "@heroicons/react/24/outline";
import SkeletonLoaderFullpage from "Components/Loader/SkeletonLoaderFullpage";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSwitchToAdminMutation } from "store/Actions/switchAction";
import { loggedBackId, loggedUserCode } from "utils/functions";

const SwitchBackButton = ({ setSidebarOpen }) => {
  const nav = useNavigate();
  const [switchToAdmin, switchToAdminAPI] = useSwitchToAdminMutation();
  const switchUser = () => {
    switchToAdmin(loggedBackId()).then((res) => {
      if (res.error) {
        toast.error(res.error.data.message);
      } else {
        localStorage.setItem("token", res.data?.data?.token);
        localStorage.setItem("userInfo", JSON.stringify(res.data?.data));
        toast.success(res?.data?.message);
        nav("/brokerage");
      }
    });
  };
  if (loggedUserCode() == 1002) {
    return (
      <>
        <hr className="h-px mt-5 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />
        <div
          onClick={() => {
            switchUser();
            setSidebarOpen && setSidebarOpen(false);
          }}
          className={`
               hover:bg-white  hover:lg:shadow-soft-xl hover:rounded-lg mt-5
              ease-soft-in-out text-sm py-2.7 active  my-0 mx-4 flex items-center whitespace-nowrap px-4 font-medium text-slate-500 shadow-none transition-colors cursor-pointer dark:text-white dark:opacity-80 `}
        >
          <div className="stroke-none shadow-soft-sm bg-gradient-to-tl from-yachtrDarkBlue to-yachtrBlue mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center fill-current p-1 text-center text-white">
            <ArrowLeftEndOnRectangleIcon />
          </div>

          <div className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-slate-700">
            Switch to Admin
          </div>
        </div>
        <SkeletonLoaderFullpage loading={switchToAdminAPI?.isLoading} />
      </>
    );
  }
};

export default SwitchBackButton;
