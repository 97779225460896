import SkeletonLoaderFullpage from "Components/Loader/SkeletonLoaderFullpage";
import DataTable from "Components/Table/Index";
import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";
import {
  useDeleteSearchTextMutation,
  useSearchTextListQuery,
} from "store/Actions/searchText";
import Swal from "sweetalert2";

const SearchText = () => {
  const [filter, setFilter] = useState({
    sort: "created_at",
    order: "desc",
    perPage: 50,
    page: 1,
    searchField: " ",
    searchValue: " ",
  });

  const { data, refetch, isLoading, isFetching } =
    useSearchTextListQuery(filter);

  const [deleteSearchText, deleteSearchTextAPI] = useDeleteSearchTextMutation();

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSearchText(id).then((res) => {
          if (res.error) {
            toast.error(res.error.data.message);
          } else {
            toast.success(res.data?.message);
            if (data?.data?.data.length === 1) {
              setFilter((filter) => ({
                ...filter,
                page: data.data.last_page - 1,
              }));
            } else {
              refetch();
            }
          }
        });
      }
    });
  };

  const columns = [
    {
      header: "Text",
      accessor: "search_text",
    },

    {
      header: "count",
      accessor: "count",
    },
    {
      header: "created at",
      accessor: "created_at",
      Cell: ({ cell: { value } }) =>
        `${moment(value).format("MM-DD-YYYY HH:mm:ss")}`,
    },
    {
      header: "Updated at",
      accessor: "updated_at",
      Cell: ({ cell: { value } }) =>
        `${moment(value).format("MM-DD-YYYY HH:mm:ss")}`,
    },
    {
      header: "Action",
      accessor: "id",
      Cell: ({ cell: { value } }) => {
        return (
          <div className="flex gap-2  items-center  font-semibold ">
            <span
              className="cursor-pointer hover:text-red-600 hover:underline"
              onClick={() => onDelete(value)}
            >
              Delete
            </span>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="flex flex-wrap my-6 -mx-3">
        <div className="w-full max-w-full px-3 flex-0">
          <div className="relative flex flex-col p-6 min-w-0 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
              <div className="lg:flex">
                <div>
                  <h5 className="mb-0 dark:text-white font-bold text-3xl text-blue-800">
                    Text Search
                  </h5>
                  <p className="mb-0 leading-normal text-sm"></p>
                </div>
              </div>
            </div>
            <div className=" overflow-x-auto p-3">
              {/* <ListTable columns={columns} data={data}/> */}
            </div>

            <DataTable
              ListQuery={{ data: data?.data, isLoading, isFetching }}
              filterProps={{ filter, setFilter }}
              columns={columns}
              sortingField={[
                "search_text",
                "count",
                "created_at",
                "updated_at",
              ]}
              searchFields={[]}
            />
          </div>
        </div>
      </div>
      <SkeletonLoaderFullpage loader={deleteSearchTextAPI?.isLoading} />
    </>
  );
};

export default SearchText;
