import DataTable from "Components/Table/Index"
import moment from "moment";
import { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAdminMyVisitsQuery } from "store/Actions/adminAnalytics";

const MyVisits = () => {
    const { deviceId } = useParams();
    const [filter, setFilter] = useState({
        perPage: 50,
        page: 1,
        // start_date: `${currentMonth()}-01-${currentYear()}`,
        // end_date: `${currentMonth()}-${currentDate()}-${currentYear()}`,
    });

    const { data: list, isFetching } = useAdminMyVisitsQuery({ deviceId, filter });

    const columns = useMemo(
        () => [
            {
                header: "vessel id",
                accessor: "vessel_id",
                Cell: ({ cell: { row } }) => {
                    const name = `${row.original.vessel_id} ${row.original.brokerage_name} - ${row.original.display_length_feet}' ${row.original.manufacturer}  ${row.original.year} `;
                    return (

                        <Link to={`/vessel-details/view/${row.original.vessel_id}`} className="flex items-center gap-3 flex-wrap cursor-pointer text-blue-700 hover:text-blue-800 w-fit">
                            <img
                                src={row?.original?.vessel_image_url}
                                className=" w-20 h-full shadow-soft-sm rounded-sm object-cover preview-img "
                            />
                            <div
                                dangerouslySetInnerHTML={{ __html: name }}
                                className="text-lg "
                            ></div>
                        </Link>
                    );
                },
            },
            {
                header: "count",
                accessor: "visit_count",
                Cell: ({ cell: { value } }) => {
                    return (
                        <span
                            className={``}
                        >
                            {value?.toLocaleString()}
                        </span>
                    );
                },

            },

            {
                header: "Date",
                accessor: "updated_at",
                Cell: ({ cell: { value } }) => {
                    return (
                        <span className={``}>
                            {moment(value).format("MM-DD-YYYY")}
                        </span>
                    );
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
    );

  return (
    <DataTable
    ListQuery={{ data: list?.data, isLoading: isFetching }}
    filterProps={{ filter, setFilter }}
    columns={columns}
    sortingField={["created_at",]}
    SearchId={true}
    searchPlaceHolder="Vessel Id"
    hideFilter={true}
/>
  )
}

export default MyVisits