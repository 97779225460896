import CreateBrokerageForm from "Components/Forms/CreateBrokerageForm";

const CreateBrokerage = () => {
  return (
    <div className="w-full p-6 mx-auto">
      <div className="flex flex-wrap -mx-3">
        <div className="w-full max-w-full px-3 flex-0">
          <div className="mb-12">
            <div className="flex flex-wrap -mx-3">
              <div className="w-full max-w-full px-3 m-auto flex-0 lg:w-8/12">
                <div className="relative mb-32">
                  <CreateBrokerageForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBrokerage;
