import { sidebarList } from "Assets/Data/sidebarList";
import logo from "Assets/yachtr.png";
import { ExpandedIcon } from "Components/SidebarMenu/ExpandedIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loggedUserRole } from "utils/functions";
import SwitchBackButton from "./SwitchBackButton";

const SidebarMenu = ({ setSidebarOpen }) => {
  const nav = useNavigate();
  const { pathname } = useLocation();

  return (
    <div>
      <div>
        <div
          className="flex  cursor-pointer items-center justify-center  px-8 pt-7 m-0 text-sm whitespace-nowrap text-slate-700 dark:text-white w-full"
          onClick={() => nav("/")}
        >
          <img
            src={logo}
            className="inline-block h-full w-full transition-all duration-200 ease-soft-in-out max-w-60/100 max-h-60max-w-60/100  dark:hidden"
            alt="main_logo"
          />

          {/* <span className="ml-2 font-semibold transition-all duration-200 ease-soft-in-out text-2xl">Yachtr</span> */}
        </div>
      </div>
      <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />
      <div role="list" className="pt-5 ">
        {sidebarList.map(
          (item) =>
            item?.accessTo?.includes(loggedUserRole()) && (
              <div key={item.name}>
                {!item.children ? (
                  <Link
                    to={`${item.href}`}
                    onClick={() => {
                      setSidebarOpen && setSidebarOpen(false);
                    }}
                    className={` ${
                      pathname === item.href
                        ? "bg-white shadow-soft-xl rounded-lg"
                        : ""
                    } ease-soft-in-out text-sm py-2.7 active   mx-4 flex items-center whitespace-nowrap px-4 font-medium text-slate-500 shadow-none transition-colors cursor-pointer dark:text-white dark:opacity-80  hover:bg-white  hover:lg:shadow-soft-xl hover:rounded-lg my-2 min-w-48  text-wrap`}
                  >
                    <div className="stroke-none shadow-soft-sm bg-gradient-to-tl from-yachtrDarkBlue from-10%  to-yachtrBlue  to-90% mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center fill-current p-1 text-center text-white min-w-8">
                      <item.icon />
                    </div>

                    <div className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-slate-700 ">
                      {item.name}
                    </div>
                  </Link>
                ) :( 
                  <ExpandedIcon item={item} setSidebarOpen={setSidebarOpen} />
                )}
              </div>
            )
        )}
      </div>
      <SwitchBackButton setSidebarOpen={setSidebarOpen} />
    </div>
  );
};

export default SidebarMenu;

/// ease-soft-in-out text-sm py-2.7 active lg:shadow-soft-xl my-2 mx-4 flex items-center whitespace-nowrap rounded-lg bg-white px-4 font-semibold text-slate-700 transition-all w-full  dark:text-white dark:opacity-80
