import Main from "Layout/Main/Index";
import ActionReport from "pages/ActionReport/ActionReport";
import ErrorPage from "pages/ErrorPage/Index";
import { Route, Routes } from "react-router-dom";
import ProtectedLogin from "./ProtectedLogin";
import ProtectedLogout from "./ProtectedLogout";
import { openRoute, routesData } from "./RoutesData";

const Routers = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <ProtectedLogin>
            {" "}
            <ErrorPage />
          </ProtectedLogin>
        }
      />
      <Route element={<Main />}>
        {routesData?.map((route, key) => {
          return (
            <Route
              key={key}
              path={route?.path}
              element={
                <ProtectedLogin accessTo={route?.accessTo} name={route?.name}>
                  {route?.element}{" "}
                </ProtectedLogin>
              }
            />
          );
        })}
      </Route>
      <Route>
        {openRoute?.map((route, key) => {
          return (
            <Route
              key={key}
              path={route?.path}
              element={
                <ProtectedLogout name={route?.name}>
                  {route?.element}{" "}
                </ProtectedLogout>
              }
            />
          );
        })}
      </Route>
      <Route path="/report-via-actions" element={<ActionReport />} />
    </Routes>
  );
};

export default Routers;
