// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating authAction by enhancing and injecting endpoints using serverApi
const authAction = serverApi
  .enhanceEndpoints({ tagTypes: ["userDetails"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Mutation endpoint for user login
      login: builder.mutation({
        query(body) {
          return {
            url: `login`,
            method: "POST",
            body: body,
          };
        },
        invalidatesTags: ["userDetails"],
      }),
      logOut: builder.mutation({
        query(body) {
          return {
            url: `logout`,
            method: "POST",
            body: body,
          };
        },
      }),

      // Mutation endpoint for forgot password
      forgetPassword: builder.mutation({
        query(body) {
          return {
            url: `forgot-password`,
            method: "POST",
            body: body,
          };
        },
      }),

      // Mutation endpoint for resetting password
      resetPassword: builder.mutation({
        query(body) {
          return {
            url: `reset-password/${body.token}`,
            method: "POST",
            body: body,
          };
        },
      }),

      // Mutation endpoint for changing password
      changePassword: builder.mutation({
        query(body) {
          return {
            url: `change-password`,
            method: "POST",
            body: body,
          };
        },
        // Invalidates user tags after changing the password
        invalidatesTags: ["userDetails"],
      }),

      // Query endpoint for retrieving user profile
      userProfile: builder.query({
        query() {
          return {
            url: `user-profile`,
            method: "GET",
          };
        },
        // Provides user tags for caching user profile data
        keepUnusedDataFor: 0,
        providesTags: ["userDetails"],
      }),

      // Mutation endpoint for updating login details
      updateLoginDetails: builder.mutation({
        query(body) {
          return {
            url: `update-login-details`,
            method: "PUT",
            body: body,
          };
        },
        // Invalidates user tags after updating login details
        invalidatesTags: ["userDetails"],
      }),

      // Mutation endpoint for updating user details
      updateUserDetails: builder.mutation({
        query(body) {
          return {
            url: `update-user-details`,
            method: "PUT",
            body: body,
          };
        },
        // Invalidates user tags after updating user details
        invalidatesTags: ["userDetails"],
      }),

      // Mutation endpoint for uploading user profile
      uploadProfile: builder.mutation({
        query(body) {
          // Helper function to convert object into FormData
          const getFormData = (object) =>
            Object.keys(object).reduce((formData, key) => {
              formData.append(key, object[key]);
              return formData;
            }, new FormData());

          // Creating FormData from the body object
          const myForm = getFormData(body);

          return {
            url: `upload`,
            method: "POST",
            body: myForm,
          };
        },
        // Invalidates user tags after uploading profile
        invalidatesTags: ["userDetails"],
      }),
      deleteGuestAccount: builder.mutation({
        query() {
          return {
            url: `delete-account`,
            method: "POST",
          };
        },
        // Invalidates broker tags after deleting a broker
        invalidatesTags: [""],
      }),
    }),
  });

// Destructuring authAction to get individual hooks for components
export const {
  useLoginMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useUserProfileQuery,
  useUpdateLoginDetailsMutation,
  useUpdateUserDetailsMutation,
  useUploadProfileMutation,
  useLogOutMutation,
  useDeleteGuestAccountMutation,
} = authAction;
