import CardList from "Components/CardList/Index";
import { useState } from "react";

import { useFollowListQuery } from "store/Actions/guestFollow";

const FollowedYachts = () => {
  const [filter, setFilter] = useState({
    sort: "Year",
    order: "desc",
    perPage: 12,
    page: 1,
  });
  const { data, refetch, isFetching } = useFollowListQuery(filter);

  return (
    <div>
      <div className="lg:flex">
        <div className="flex justify-center w-full my-5">
          <h5 className=" dark:text-white font-bold text-3xl text-blue-800">
            Followed Yachts
          </h5>
          <p className="mb-0 leading-normal text-sm"></p>
        </div>
      </div>
      {data?.data.total > 0 ? (
        <CardList
          ListQuery={{ data: data?.data, isLoading: isFetching, refetch }}
          filterProps={{ filter, setFilter }}
          setOpenModal={{}}
          setDefaultData={""}
          type={"followYacht"}
        />
      ) : (
        <>
          {" "}
          <h5 className="dark:text-white font-bold text-xl text-center text-red-800">
            Please bookmark some yachts on Yachtr.com
          </h5>
        </>
      )}
    </div>
  );
};

export default FollowedYachts;
