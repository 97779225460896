import ErrorPage from "pages/ErrorPage/Index";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { loggedUserRole } from "utils/functions";
/**
 * ? condition that only if there is token there the children is render
 */
const ProtectedLogin = ({ accessTo, children, name }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const showTitle = name ?? "";
    document.title = showTitle ? `Yachtr | ${showTitle}` : "Yachtr";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  if (localStorage.getItem("token")) {
    if (!accessTo?.includes(loggedUserRole())) {
      return <ErrorPage />;
    } else {
      return children;
    }
  }
  return <Navigate to="/login" replace />;
};

export default ProtectedLogin;
