import LoginButton from "Components/Butttons/LoginButton";
import CustomInput from "Components/Input/Input";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useForgetPasswordMutation } from "store/Actions/authAction";
import * as Yup from "yup";

// Creating schema
const schema = Yup.object({
  username: Yup.string()
    .required("Username is required!!"),
});

export default function ForgetPasswordForm() {
  const nav = useNavigate();
  const [forgetPassword, { isLoading }] = useForgetPasswordMutation();
  return (
    <>
      <Formik
        initialValues={{
          username: "",
        }}
        onSubmit={async (values) => {
          !isLoading &&
            forgetPassword(values).then((res) => {
              if (res.error) {
                toast.error(res.error.data.message);
              } else {
                toast.success(res.data?.message);
                nav("/login");
              }
            });
        }}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <CustomInput
                name="username"
                values={values}
                type="text"
                label="username"
                placeholder="Username"
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                isRequire={true}
              />

              <div className="text-center">
                <LoginButton isLoading={isLoading} text="Send Mail" />
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
}
