import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import BlogEditForm from "Components/Forms/BlogEditForm";
import { Fragment } from "react";

const EditModel = ({ isOpen, closeModal, id,refetch }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 min-h-60/100"
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl min-h-85-screen  transform overflow-visible rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <button
                  type="button"
                  className="absolute top-4 right-4 inline-flex items-center justify-center rounded-full p-2 text-gray-700 hover:text-gray-800 focus:outline-none"
                  onClick={closeModal}
                >
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>{" "}
                <Dialog.Title
                  as="h3"
                  className="text-xl pb-5 font-medium  leading-6 text-gray-900 "
                >
                  Update Blog Details
                </Dialog.Title>
                <BlogEditForm closeModal={closeModal} id={id} refetch={refetch}/>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditModel;
