import LoginButton from "Components/Butttons/LoginButton";
import CustomInput from "Components/Input/Input";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "store/Actions/authAction";
import * as Yup from "yup";

export default function ResetPasswordForm() {
  const { resetToken } = useParams();

  const [searchParams] = useSearchParams();

  const tokenName = searchParams.get("username");

  // Creating schema
  const schema = Yup.object({
    username: Yup.string().required("Username is required!!"),
    password: Yup.string().min(6, "Required").required("Required"),
    password_confirmation: Yup.string().min(6, "Required").required("Required"),
  });
  const nav = useNavigate();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  return (
    <>
      <Formik
        initialValues={{
          username: tokenName,
          password: "",
          password_confirmation: "",
          token: resetToken,
        }}
        onSubmit={async (values) => {
          !isLoading &&
            resetPassword(values).then((res) => {
              if (res.error) {
                toast.error(res.error.data.message);
              } else {
                toast.success(res.data?.message);
                nav("/login");
              }
            });
        }}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <CustomInput
                  name="username"
                  values={values}
                  type="text"
                  label="username"
                  placeholder="Username"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  isRequire={true}
                />
              </div>

              <div className="mb-4">
                <CustomInput
                  name="password"
                  values={values}
                  type="password"
                  label="New Password"
                  placeholder="Enter New Password"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  isRequire={true}
                />
              </div>

              <div className="mb-4">
                <CustomInput
                  name="password_confirmation"
                  values={values}
                  type="password"
                  label="confirm password"
                  placeholder="Enter confirm Password"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  isRequire={true}
                />
              </div>

              <div className="text-center">
                <LoginButton isLoading={isLoading} text="Reset Password" />
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
}
