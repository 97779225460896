import moment from "moment";
import { useEffect, useState } from "react";
import BrokerageGraph from "./BrokerageGraph";
import BrokerGraph from "./BrokerWiseGraph";
import VesselGraph from "./VesselWiseGraph";

function BrokerageAnalytics({ duration }) {
  const [filter, setFilter] = useState({
    sort: "count_vessel_id_wise",
    order: "desc",
    start_date: moment(
      new Date() - Number(duration) * 24 * 60 * 60 * 1000
    ).format("MM-DD-Y"),
    end_date: moment(new Date()).format("MM-DD-Y"),
  });
  const [graphInfo, setGraphInfo] = useState({
    currentGraph: "brokerage",
    broker: null,
    brokerage: null,
  });
  useEffect(() => {
    const endDate = moment(new Date()).format("MM-DD-Y");
    const startDate = moment(
      new Date() - Number(duration) * 24 * 60 * 60 * 1000
    ).format("MM-DD-Y");
    setFilter(() => {
      return {
        ...filter,
        start_date: startDate,
        end_date: endDate,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-4/12 lg:flex-none">
      <div className="relative z-20 flex flex-col min-w-0 break-words bg-white border-0 border-solid dark:bg-gray-950 border-black-125 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border p-2 ">
        <div className="my-auto flex ml-auto lg:mt-0 ">
          {graphInfo.currentGraph !== "brokerage" && (
            <div>
              {" "}
              <span
                className="text-blue-800 hover:underline cursor-pointer"
                onClick={() =>
                  setGraphInfo((graphInfo) => ({
                    ...graphInfo,
                    currentGraph: "brokerage",
                    broker: null,
                    brokerage: null,
                  }))
                }
              >
                Brokerage
              </span>{" "}
              {graphInfo?.brokerage && (
                <span
                  className="text-blue-800 hover:underline cursor-pointer"
                  onClick={() =>
                    setGraphInfo((graphInfo) => ({
                      ...graphInfo,
                      currentGraph: "broker",
                      broker: null,
                    }))
                  }
                  dangerouslySetInnerHTML={{
                    __html: `/ ${graphInfo?.brokerage}`,
                  }}
                ></span>
              )}
              {graphInfo?.broker && (
                <span
                  className="text-blue-800 hover:underline cursor-pointer hover:text-blue-900"
                  dangerouslySetInnerHTML={{
                    __html: `/ ${graphInfo?.broker}`,
                  }}
                ></span>
              )}
            </div>
          )}
        </div>
        <div className="overflow-y-auto">
          {graphInfo.currentGraph === "brokerage" ? (
            <BrokerageGraph
              graphState={{ graphInfo, setGraphInfo }}
              filter={filter}
            />
          ) : graphInfo.currentGraph === "broker" ? (
            <BrokerGraph
              graphState={{ graphInfo, setGraphInfo }}
              filter={filter}
            />
          ) : (
            <VesselGraph
              graphState={{ graphInfo, setGraphInfo }}
              filter={filter}
            />
          )}
        </div>

        <div className="flex justify-center  text-sm font-semibold">
          {" "}
          {filter.start_date && (
            <span>{`${filter.start_date} to ${filter.end_date}`}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default BrokerageAnalytics;
