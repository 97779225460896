import moment from "moment";
import { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  loggedUserRole,
} from "utils/functions";
import VesselsGuest from "../VesselsAnalytics/VesselsGuest";
import VesselsVisitor from "../VesselsAnalytics/VesselsVisitor";
import { useAdminVesselsDetailsQuery } from "store/Actions/adminAnalytics";
import VesselsInquires from "../VesselsAnalytics/VesselsInquires";
import VesselsGraph from "../VesselsAnalytics/VesselsGraph";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import ComponentLoader from "Components/Loader/ComponentLoader";
const VesselDetails = () => {
  const { vesselId } = useParams();
  const [searchParams] = useSearchParams();

  const [filter, setFilter] = useState({
    perPage: 50,
    page: 1,
    start_date: `${moment(new Date()).startOf("month").format("MM-DD-YYYY")}`,
    end_date: `${moment(new Date()).format("MM-DD-YYYY")}`,
  });

  useEffect(() => {
    if (searchParams.get("from")) {
      const startDate = searchParams.get("from");
      const endDate = searchParams.get("to");
      setFilter(() => {
        return {
          ...filter,
          start_date: startDate,
          end_date: endDate,
        };
      });
    } else {
      setFilter(() => {
        return {
          ...filter,
          start_date: `${moment(new Date()).startOf("month").format("MM-DD-YYYY")}`,
          end_date: `${moment(new Date()).format("MM-DD-YYYY")}`,
        };
      });
    }
  }, [searchParams]);

  const [vesselsDetails, setVesselsDetails] = useState({});
  const { data, isLoading } = useAdminVesselsDetailsQuery({ vesselId, filter });

  const setDate = (newValue) => {
    const startDate = moment(newValue[0]).format("MM-DD-Y");
    const endDate = moment(newValue[1]).format("MM-DD-Y");
    setFilter(() => {
      return {
        ...filter,
        start_date: startDate,
        end_date: endDate,
      };
    });
  };
  useEffect(() => {
    setVesselsDetails(data?.data?.vesselData);
  }, [data]);
  const navigate = useNavigate();
  return (
    <>
      {isLoading ? (
        <ComponentLoader />
      ) : (
        <div className="flex flex-wrap my-6 -mx-3">
          <div className="w-full max-w-full px-3 flex-0">
            <div className="px-0 py-5">
              <button
                className="px-4 py-2 m-0 text-xs w-40  text-center  transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft  bg-150 bg-x-25 hover:scale-102 active:opacity-85   whitespace-nowrap  text-slate-800 align-baseline font-bold uppercase   flex gap-1 items-center "
                onClick={() => navigate(-1)}
              >
                {" "}
                <ChevronDoubleLeftIcon className="h-5 w-5" /> go back
              </button>
            </div>
            <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid  pb-0">
              <div className="lg:flex justify-between items-center px-5">
                <div className="flex items-center gap-2">
                  <img
                    src={vesselsDetails?.vessel_image_url}
                    className=" w-20 h-full shadow-soft-sm rounded-sm object-cover preview-img "
                  />
                  <div className="text-xl text-blue-800 ">{`${vesselId || ""} ${loggedUserRole() === "Admin" ? vesselsDetails?.brokerage_name : loggedUserRole() === "Brokerage" ? vesselsDetails?.broker_name : ""} - ${vesselsDetails?.display_length_feet || ""}' ${vesselsDetails?.manufacturer || ""}  ${vesselsDetails?.year || ""} `}</div>
                </div>
                <div className="">
                  <Flatpickr
                    options={{
                      mode: "range",
                      dateFormat: "m-d-Y",
                      defaultDate: searchParams.get("from")
                        ? [searchParams.get("from"), searchParams.get("to")]
                        : [
                            `${moment(new Date()).startOf("month").format("MM-DD-YYYY")}`,
                            `${moment(new Date()).format("MM-DD-YYYY")}`,
                          ],
                      // defaultDate:[filter.start_date,filter.end_date]
                    }}
                    fromdateid="DashboardEndDatePicker"
                    selectvalue={[]}
                    className="form-control clickable  focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-fit appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mx-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                    onChange={setDate}
                    placeholder="Select Date Range 🗓️"
                  />
                </div>
              </div>
            </div>
            {/* <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
         

          <DataTable
            ListQuery={{ data: data?.data?.visitorAnalytics, isLoading: isLoading }}
            filterProps={{ filter, setFilter }}
            columns={columns}
            sortingField={[]}
            searchFields={[]}
          />
        </div> */}
            <div className="">
              <div className="flex flex-wrap mt-6 -mx-3 ">
                <div className="w-full max-w-full p-3 mt-0 mb-6 lg:mb-0 lg:flex-none">
                  <div className="relative z-20 flex flex-col min-w-0 break-words bg-white border-0 border-solid dark:bg-gray-950 border-black-125 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border p-2   overflow-auto">
                    <VesselsGraph filterProps={{ filter, setFilter }} />
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="max-h-8/10">
              <div className="flex flex-wrap mt-6 -mx-3 ">
                <div className="w-full max-w-full p-3 mt-0 mb-6 lg:mb-0 lg:w-6/12 lg:flex-none">
                  <div className="relative z-20 flex flex-col min-w-0 break-words bg-white border-0 border-solid dark:bg-gray-950 border-black-125 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border p-2  max-h-[40vh] overflow-auto">
                    <VesselsVisitor
                      filterProps={{ filter, setFilter }}
                      totalCount={data?.data?.visitorData?.visitor_counts || 0}
                    />
                  </div>{" "}
                </div>
                <div className="w-full max-w-full p-3 mt-0 mb-6 lg:mb-0 lg:w-6/12 lg:flex-none">
                  <div className="relative z-20 flex flex-col min-w-0 break-words bg-white border-0 border-solid dark:bg-gray-950 border-black-125 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border p-2  max-h-[40vh] overflow-auto">
                    <VesselsGuest
                      filterProps={{ filter, setFilter }}
                      totalCount={data?.data?.guestData?.guest_counts || 0}
                    />
                  </div>{" "}
                </div>
              </div>
            </div>

            <div className="max-h-8/10">
              <div className="flex flex-wrap mt-6 -mx-3 ">
                <div className="w-full max-w-full p-3 mt-0 mb-6 lg:mb-0 lg:flex-none">
                  <div className="relative z-20 flex flex-col min-w-0 break-words bg-white border-0 border-solid dark:bg-gray-950 border-black-125 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border p-2  max-h-[70vh] overflow-auto">
                    <VesselsInquires
                      filterProps={{ filter, setFilter }}
                      totalCount={data?.data?.inquiryData?.inquiry_counts || 0}
                    />
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}{" "}
    </>
  );
};

export default VesselDetails;
