export const statusOptions = [
  { id: "active", name: "Active" },
  { id: "inactive", name: "Inactive" },
];

export const perPageOptions = [
  { id: "10", name: "10" },
  { id: "20", name: "20" },
  { id: "50", name: "50" },
  { id: "100", name: "100" },
];

export const perPageCardOptions = [
  { id: "6", name: "6" },
  { id: "9", name: "9" },
  { id: "12", name: "12" },
  { id: "18", name: "18" },
  { id: "24", name: "24" },
];
export const perPageFollowCardOptions = [
  { id: "8", name: "8" },
  { id: "12", name: "12" },
  { id: "20", name: "20" },
  { id: "28", name: "28" },
  { id: "36", name: "36" },
];
