import { Tab } from "@headlessui/react";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import BrowserImg from "Components/Browser/BrowserImg";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useAdminVisitorDetailsQuery } from "store/Actions/adminAnalytics";
import MyInquires from "./MyInquries";
import MyVisits from "./MyVisits";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
const AccountDetails = () => {
    const { deviceId } = useParams();
    const [visitorData, setVisitorData] = useState({})
    const { isFetching, data } = useAdminVisitorDetailsQuery({ deviceId });

    useEffect(() => {
        setVisitorData(()=>({...data?.data?.device,...data?.data?.visitor }))
    }, [data])
    const navigate = useNavigate();

    const openMap = () => {
        if (visitorData?.longitude && visitorData?.longitude) {
            window.open("https://maps.google.com?q=" + visitorData?.latitude + "," + visitorData?.longitude);
        } else {
            toast.error("Can't Find Location")
        }
    };
    return (
        <div className="flex flex-wrap my-6 -mx-3">
            <div className="w-full max-w-full px-3 flex-0">
                <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
                    <div>   <button className=" px-4 py-2 m-0 text-xs w-40  text-center  transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft  bg-150 bg-x-25 hover:scale-102 active:opacity-85   whitespace-nowrap  text-slate-800 align-baseline font-bold uppercase   flex gap-1 items-center "
                        onClick={() => navigate(-1)} > <ChevronDoubleLeftIcon className="h-5 w-5" /> go back</button></div>
                    {isFetching ? <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0"> <Skeleton count={10} /></div> : <>
                        <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                            <div className="lg:flex justify-between">
                                <div>
                                    <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                                        Account Details
                                    </h5>
                                    <div className=""><span>Id: </span><span ></span> <span className="font-semibold">{visitorData?.id}</span> </div>
                                </div>
                                <div>
                                    {visitorData?.browser?.name == "Googlebot" ? <span className="py-2.2 px-3.6 text-xs rounded-1.8 inline-block whitespace-nowrap text-center bg-slate-650 text-slate-750 align-baseline font-bold uppercase leading-none">Google Bot</span> : <></>}
                                </div>
                            </div>
                        </div>
                        <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />

                        <div className="flex justify-between px-6 my-2">
                            <div className="w-1/3 ">
                                <div className=""><span>Name: </span><span className="font-semibold">{(!visitorData?.first_name || !visitorData?.last_name) ? "Visitor" : `${visitorData?.first_name} ${visitorData?.last_name} `}</span></div>
                                <div className=""><span>City: </span><span className="font-semibold">{visitorData?.city?.name}</span></div>
                                <div className="flex"><span>Mobile: </span><span className="font-semibold">{visitorData?.phone && <a href={`tel: ${visitorData?.phone}`} className={`normal-case py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-semibold bg-slate-200 text-slate-700  leading-none flex flex-wrap w-fit`} >{visitorData?.phone}</a>}</span></div>
                            </div>
                            <div className="w-1/3">
                                <div className="flex"><span>Email: </span><span className="font-semibold">{visitorData?.email && <a href={`mailto: ${visitorData?.email}`} className="normal-case py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-semibold bg-slate-200 text-slate-700  leading-none flex flex-wrap w-fit ">{visitorData?.email}</a>}</span></div>
                                <div className=""><span>Region: </span><span className="font-semibold">{visitorData?.region?.name}</span></div>
                            </div>
                            <div className="w-1/3">
                                <div className=""><span>IP: </span><span className="font-semibold">{visitorData?.ip}</span></div>
                                <div className=""><span>Country: </span><span className="font-semibold">{visitorData?.country?.name}</span></div>
                            </div>
                        </div>
                        <h5 className="mb-2 dark:text-white font-bold text-xl text-blue-800  px-6 ">
                            Access History
                        </h5>

                        <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />
                        <div className="flex justify-between px-6 my-2 ">
                            <div className="w-1/3">
                                <div className="flex items-center gap-1"><span>Browser: </span> <BrowserImg browser={visitorData?.browser?.name} />   <span className="font-semibold mt-0.5">{visitorData?.browser?.name}</span></div>
                                <div className="flex items-center"><span>Location: </span><span className="font-semibold"><button className=" px-4 py-2 m-0 text-xs w-40  text-center  transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft  bg-150 bg-x-25 hover:scale-102 active:opacity-85   whitespace-nowrap  text-slate-800 align-baseline font-bold uppercase   flex gap-1 items-center "
                                    onClick={openMap} > <img src="/BrowserLogo/googleMap.svg" className="h-5 w-5" /> open map</button></span></div>
                            </div>
                            <div className="w-1/3">
                                <div className=""><span>Version: </span><span className="font-semibold">{visitorData?.browser_version?.name}</span></div>
                                <div className=""></div>
                            </div>
                            <div className="w-1/3">
                                <div className="">{" "}</div>
                                <div className=""><span>OS: </span><span className="font-semibold">{visitorData?.os?.name}</span></div>

                            </div>
                        </div></>}



                    <Tab.Group>
                        <Tab.List className="flex flex-col sm:flex-row  space-x-1 mx-4 rounded-xl  p-1 mt-5">

                            <Tab
                                className={({ selected }) =>
                                    classNames(
                                        "w-auto rounded-lg p-4 mx-2 text-sm  font-medium leading-5",
                                        "inline-block px-8 py-2 m-0 text-sm font-bold text-center   align-middle transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft  bg-150 bg-x-25 hover:scale-102 active:opacity-85 ",
                                        selected
                                            ? " text-blue-50  bg-gradient-to-tl from-yachtrDarkBlue to-yachtrBlue shadow-soft-md"
                                            : " hover:bg-white/[0.12] hover:text-gray-800",
                                    )
                                }
                            >
                                My Visit
                            </Tab>

                            <Tab
                                className={({ selected }) =>
                                    classNames(
                                        "w-auto rounded-lg p-4 mx-2 text-sm  font-medium leading-5",
                                        "inline-block px-8 py-2 m-0 text-sm font-bold text-center   align-middle transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft  bg-150 bg-x-25 hover:scale-102 active:opacity-85 ",
                                        selected
                                            ? " text-blue-50  bg-gradient-to-tl from-yachtrDarkBlue to-yachtrBlue shadow-soft-md"
                                            : " hover:bg-white/[0.12] hover:text-gray-800",
                                    )
                                }
                            >
                                My Inquires
                            </Tab>

                        </Tab.List>
                        <Tab.Panel>
                            <MyVisits />

                        </Tab.Panel>
                        <Tab.Panel>
                            <MyInquires />
                        </Tab.Panel>

                    </Tab.Group>

                </div>
            </div>
        </div>
    )
}

export default AccountDetails

