import ResetButton from "Components/Butttons/ResetButton";
import SubmitButton from "Components/Butttons/SubmitButton";
import CustomInput from "Components/Input/Input";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
  useBrokerCreateMutation,
  useBrokerDetailsMutation,
  useBrokerUpdateMutation,
} from "store/Actions/broker";
import { statusOptions } from "utils/data";

import * as Yup from "yup";

const CreateBrokerForm = () => {
  const { id } = useParams();
  // Creating schema
  const schema = Yup.object({
    email: Yup.string()
      .email("Invalid email format!!")
      .required("Mail is required!!"),
    password: id
      ? Yup.string().min(6, "Too Short!")
      : Yup.string().min(6, "Too Short!").required("Password required!"),
      username: Yup.string().required("username is Required"),
    related_entity_id: Yup.string().required(" broker id  Required"),
    first_name: Yup.string().required(" first name Required"),
    last_name: Yup.string().required(" last name Required"),
  });

  const [currentBroker, setCurrentBroker] = useState({});
  const nav = useNavigate();
  const [brokerCreate, { isLoading: createLoading }] =
    useBrokerCreateMutation();
  const [brokerUpdate, { isLoading: updateLoading }] =
    useBrokerUpdateMutation();
  const [brokerDetails] = useBrokerDetailsMutation();
  useEffect(() => {
    if (id) {
      brokerDetails(id).then((res) => {
        if (res?.error) {
          nav("/broker");
        } else {
          setCurrentBroker(res?.data?.data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: id ? currentBroker?.email : "",
        password: id ? currentBroker?.password : "",
        related_entity_id: id ? currentBroker?.related_entity_id : null,
        username: id ? currentBroker?.username : "",
        first_name: id ? currentBroker?.first_name : "",
        last_name: id ? currentBroker?.last_name : "",
        status: id ? currentBroker?.status : "Active",
      }}
      onSubmit={async (values) => {
        if (!updateLoading && id) {
          brokerUpdate({ id, values }).then((res) => {
            if (res.error) {
              toast.error(res.error.data.message);
            } else {
              toast.success(res.data?.message);
              nav("/broker");
            }
          });
        } else if (!createLoading && !updateLoading) {
          brokerCreate(values).then((res) => {
            if (res.error) {
              toast.error(res.error.data.message);
            } else {
              toast.success(res.data?.message);
              nav("/broker");
            }
          });
        }
      }}
      validationSchema={schema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div
              form="user"
              className="absolute top-0 left-0 flex flex-col visible w-full h-auto min-w-0 p-4 break-words bg-white border-0 opacity-100 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border"
            >
              <h5 className="mb-0 font-bold dark:text-white">
                {" "}
                {id ? " Edit Broker" : " Create Broker"}
              </h5>
              <p className="mb-0 leading-normal text-sm">
                Fill Following details
              </p>
              <div>
                {/* row 1 */}
                <div className="flex flex-wrap mt-4 -mx-3">
                  <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                    <CustomInput
                      name="first_name"
                      values={values}
                      type="text"
                      label="first name"
                      placeholder="first name"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      isRequire={true}
                    />
                  </div>
                  <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                    <CustomInput
                      name="last_name"
                      values={values}
                      type="text"
                      label="last name"
                      placeholder="last name"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      isRequire={true}
                    />
                  </div>
                </div>
                {/* row 2 */}
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full max-w-full px-3 mt-4 flex-0 sm:mt-0 sm:w-6/12 ">
                    <label className="inline-block w-full mb-2 ml-1 font-bold text-slate-700 text-xs">
                      <div className="flex justify-between">
                        <span className="font-semibold tracking-wide text-sm">
                          Status
                        </span>
                      </div>
                    </label>
                    <select
                      disabled={!id}
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none ${
                        id ? " cursor-pointer" : "cursor-not-allowed"
                      }`}
                    >
                      {statusOptions?.map((option) => (
                        <option key={option.name} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                    <CustomInput
                      name="related_entity_id"
                      values={values}
                      type="number"
                      label="Broker id"
                      placeholder="Broker id"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      isRequire={true}
                    />
                  </div>
                </div>
                {/* row 3 */}
                <div className="flex flex-wrap mt-4 -mx-3">
                  <div className="w-full max-w-full px-3 flex-0 sm:w-6/12 ">
                    <CustomInput
                      name="email"
                      values={values}
                      type="text"
                      label="Email"
                      placeholder="email id"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      isRequire={true}
                    />
                  </div>
                  <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                    <CustomInput
                      name="username"
                      values={values}
                      type="text"
                      label="username"
                      placeholder="username"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      isRequire={!id}
                    />
                  </div>
                </div>
                {/* row 4 */}
                <div className="flex flex-wrap mt-4 -mx-3">
                  
                  <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                    <CustomInput
                      name="password"
                      values={values}
                      type="password"
                      label="Password"
                      placeholder="password"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      isRequire={!id}
                    />
                  </div>
                </div>

                <div className="flex justify-end gap-3   flex-wrap ">
                  <div className="flex mt-6">
                    <ResetButton event={() => nav("/broker")} />
                  </div>
                  <div className="flex mt-6">
                    <SubmitButton
                      isLoading={createLoading || updateLoading}
                      text={id ? " Update Broker" : " Create Broker"}
                      width="w-46"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default CreateBrokerForm;
