const ResetButton = ({ event, width, text }) => {
  return (
    <button
      type="reset"
      className={`inline-block px-6  ${
        width ? width : ""
      }  py-3 mb-0 ml-auto font-bold text-center text-gray-900 uppercase align-middle transition-all border-0 rounded-lg cursor-pointer hover:scale-102 active:opacity-85 hover:shadow-soft-xs dark:bg-gradient-to-tl bg-gradient-to-tl from-gray-50 to-slate-100 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25`}
      onClick={event}
    >
      {text || "Cancel"}
    </button>
  );
};

export default ResetButton;
