import { EyeIcon } from "@heroicons/react/24/outline";
import DataTable from "Components/Table/Index";
import moment from "moment";
import { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAdminMyInquiresQuery } from "store/Actions/adminAnalytics";

const MyInquires = () => {
    const { deviceId } = useParams();
    const [filter, setFilter] = useState({
        perPage: 50,
        page: 1,
        // start_date: `${currentMonth()}-01-${currentYear()}`,
        // end_date: `${currentMonth()}-${currentDate()}-${currentYear()}`,
    });

    const { data: list, isFetching } = useAdminMyInquiresQuery({ deviceId, filter });

    const columns = useMemo(
        () => [
          {
            header: "Vessel ID",
            accessor: "data.vesselid",
          },
          {
            header: "Contact  Details",
            accessor: "data.email",
    
            Cell: ({ cell: { row } }) => {
              return (
                <div className=" flex flex-wrap">
    
                  {row.original.data.fullname && <span className={` py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-semibold bg-slate-200 text-slate-700  leading-none m-1 flex flex-wrap w-fit`} >{row.original.data.fullname}</span>}
                   {/* {row.original.data.email && <a href={`mailto: ${row.original.data.email}`} className="normal-case py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-semibold bg-slate-200 text-slate-700  leading-none m-1 flex flex-wrap w-fit ">{row.original.data.email}</a>}
                  {row.original.data.phone && <a href={`tel: ${row.original.data.phone}`} className={` py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-semibold bg-slate-200 text-slate-700  leading-none m-1 flex flex-wrap w-fit`} >{row.original.data.phone}</a>}  */}
                </div>
              );
            },
          },
          {
            header: "Contacted At",
            accessor: "created_at",
            Cell: ({ cell: { value } }) => {
              return (
                <span className={``}>
                  {moment(value).format("MM-DD-YYYY HH:mm:ss")}
                </span>
              );
            },
          },
          {
            header: "View Vessel",
            accessor: "referrer.name",
            Cell: ({ cell: { row   } }) => {
              return (
                <Link
                to={`/vessel-details/view/${row.original?.data.vesselid}`}
                  target="_blank"
                  className="cursor-pointer py-2.2 px-3.6 text-xs rounded-1.8 whitespace-nowrap text-center align-baseline font-semibold capitalize leading-none bg-gray-50 text-gray-600 flex  w-fit"
                  rel="noreferrer"
                >
                  <EyeIcon className="h-3.4" /> view
                </Link>
              );
            },
          },
          { 
            header: "Message",
            accessor: "data.message",
            Cell: ({ cell: { value } }) => {
                return (
                  <div className=" w-120 whitespace-pre-wrap">
                    {/* <div className="break-words w-25"></div> */}
                    {value}
                  </div>
                );
              },
          },
          {
            header: " Brokerage Name",
            accessor: "data.brokeragename",
          },
          {
            header: " Broker Name",
            accessor: "data.brokername",
          },
          // {
          //   header: "more info",
          //   accessor: "count",
           
          // },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
      );

  return (
    <DataTable
    ListQuery={{ data: list?.data, isLoading: isFetching }}
    filterProps={{ filter, setFilter }}
    columns={columns}
    sortingField={["created_at",]}
    SearchId={true}
    searchPlaceHolder="Vessel Id"
    hideFilter={true}
/>
  )
}

export default MyInquires