import DataTable from "Components/Table/Index";
import moment from "moment";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useBlogsListQuery, useDeleteBlogMutation } from "store/Actions/blogs";
import Swal from "sweetalert2";
import EditModel from "./EditModel";
import AddBlogModal from "./AddBlogModal.jsx";
import Thumbnail from "Assets/thumbnail.jpeg";
import PrimaryButton from "Components/Butttons/PrimaryButton";
import { getBlogImagePrefix } from "utils/functions";
const Blogs = () => {
  const [id, setId] = useState(null);
  let [isOpen, setIsOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [filter, setFilter] = useState({
    sort: "created_at",
    order: "desc",
    perPage: 50,
    page: 1,
  });
  const { data, isFetching, refetch, isLoading } = useBlogsListQuery(filter);
  const [deleteBlog, { isLoading: deleteBlogLoading }] =
    useDeleteBlogMutation();

  function closeModal() {
    setIsOpen(false);
    setId(null);
  }

  function closeAddModal() {
    setIsAddOpen(false);
  }

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBlog(id).then((res) => {
          if (res.error) {
            toast.error(res.error.data.message);
          } else {
            toast.success(res.data?.message);
            if (res?.data?.data.length === 1) {
              setFilter((filter) => ({
                ...filter,
                page: res.data.data.last_page - 1,
              }));
            } else {
              refetch();
            }
          }
        });
      }
    });
  };

  function openModal(id) {
    setId(id);
    setIsOpen(true);
  }

  function openAddModal() {
    setIsAddOpen(true);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      header: "Title",
      accessor: "title",
      Cell: ({ cell: { row } }) => {
        const name = `${row?.original?.title}  `;
        return (
          <Link
            to={`/blog/view/${row?.original?.id}`}
            className="flex items-center gap-3 flex-wrap cursor-pointer text-blue-700 hover:text-blue-800 w-fit"
          >
            <img
              src={
                row?.original?.thumbnail
                  ? getBlogImagePrefix(row?.original?.thumbnail)
                  : Thumbnail
              }
              className=" w-20 h-full shadow-soft-sm rounded-sm object-cover preview-img "
            />
            <div
              dangerouslySetInnerHTML={{ __html: name }}
              className="text-lg "
            ></div>
          </Link>
        );
      },
    },
    {
      header: "Date",
      accessor: "date",
      Cell: ({ cell: { value } }) => {
        return <div className={``}>{moment(value).format("MM-DD-YYYY")}</div>;
      },
    },
    {
      header: "Created Date",
      accessor: "created_at",
      Cell: ({ cell: { value } }) => {
        return <div className={``}>{moment(value).format("MM-DD-YYYY")}</div>;
      },
    },
    {
      header: "Action",
      accessor: "id",
      Cell: ({ cell: { value } }) => {
        return (
          <div className="flex gap-2  items-center font-semibold ">
            <span
              className="cursor-pointer hover:text-blue-600 hover:underline "
              onClick={() => {
                openModal(value);
              }}
            >
              Edit
            </span>
            {" | "}
            <span
              className="cursor-pointer hover:text-red-600 hover:underline "
              onClick={() => onDelete(value)}
            >
              Delete
            </span>
          </div>
        );
      },
    },
  ]);

  const debounce = (func, delay) => {
    let timeoutId;

    return function (...args) {
      const later = () => {
        timeoutId = null;
        func.apply(this, args);
      };

      clearTimeout(timeoutId);

      timeoutId = setTimeout(later, delay);
    };
  };

  const selectGuest = useMemo(
    () =>
      debounce((key, val) => {
        setFilter((prev) => ({
          ...prev,
          [key]: val,
        }));
      }, 500),
    []
  );

  return (
    <div className="flex flex-wrap my-6 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div>
                <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                  Blogs
                </h5>
                <p className="mb-0 leading-normal text-sm"></p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex w-full gap-4  p-6 pb-0 max-w-6/12 ">
              <div className="flex w-full gap-4 max-w-1/2">
                <input
                  className={`focus:shadow-soft-primary-outline max-w-1/2 dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block  appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-cyan-300 focus:outline-none`}
                  placeholder={"Search By Title"}
                  onChange={(e) => {
                    selectGuest("title", e.target.value);
                  }}
                />
              </div>
              <div className="my-auto ml-auto">
                <PrimaryButton event={openAddModal} text={"Add Blog"} />
              </div>
            </div>
            <DataTable
              ListQuery={{
                data: data?.data,
                isLoading: deleteBlogLoading || isLoading,
                isFetching: isFetching,
              }}
              filterProps={{ filter, setFilter }}
              columns={columns}
              sortingField={["created_at", "date"]}
              hideFilter={true}
            />
          </div>
          <EditModel isOpen={isOpen} closeModal={closeModal} id={id} refetch={refetch} />
          <AddBlogModal
            isOpen={isAddOpen}
            closeModal={closeAddModal}
            refetch={refetch}
          />
        </div>
      </div>
    </div>
  );
};

export default Blogs;
