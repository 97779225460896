import DataTable from "Components/Table/Index";
import { useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import {
  useDeleteSearchMutation,
  useSearchHistoryListQuery,
} from "store/Actions/searchHistory";
import Swal from "sweetalert2";
import SearchHistoryEditModal from "./SearchHistoryEditModal";
const customStyles = {
  valueContainer: (base) => ({
    ...base,
    justifyContent: "center",
  }),
};
const SearchHistory = () => {
  const [id, setId] = useState(null);
  let [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState({
    sort: "created_at_list",
    order: "desc",
    perPage: 50,
    page: 1,
    searchField: "",
    searchValue: "",
    user_type: "",
  });

  const { data, refetch, isFetching } = useSearchHistoryListQuery(filter);

  const [deleteSearch] = useDeleteSearchMutation();

  function closeModal() {
    setIsOpen(false);
    setId(null);
  }

  function openModal(id, user_id) {
    setId({ id: id, user_id: user_id });
    setIsOpen(true);
  }
  const userType = [
    { value: "All", label: "All" },
    { value: "Visitor", label: "Visitor" },
    { value: "Guest", label: "Guest" },
  ];
  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSearch(id).then((res) => {
          if (res.error) {
            toast.error(res.error.data.message);
          } else {
            toast.success(res.data?.message);
            if (data?.data?.data.length === 1) {
              setFilter((filter) => ({
                ...filter,
                page: data.data.last_page - 1,
              }));
            } else {
              refetch();
            }
          }
        });
      }
    });
  };

  const columns = [
    {
      header: "name",
      accessor: "name",
    },
    {
      header: "Contact",
      accessor: "email",
      Cell: ({ cell: { value } }) => {
        return <span>{value || "-"}</span>;
      },
    },
    {
      header: "ip",
      accessor: "ip",
    },

    {
      header: "Search param",
      accessor: "data",
      Cell: ({ cell: { value } }) => {
        return (
          <div>
            <p className=" leading-normal text-xl flex flex-wrap max-w-60">
              {Object.keys(value).map((key) => (
                <span
                  key={key}
                  className={` py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-bold bg-slate-200 text-slate-700 iiiii leading-none m-1 flex flex-wrap w-fit`}
                >
                  <span className="font-normal">{key}-</span>
                  <span className="font-semibold">{value[key]}</span>
                </span>
              ))}
            </p>{" "}
          </div>
        );
      },
    },
    {
      header: "log / lat",
      accessor: "latitude",
      Cell: ({ cell: { row } }) => {
        return (
          <span className="flex flex-col">
            {" "}
            <span>{row.original.longitude}</span>{" "}
            <span>{row.original.latitude}</span>{" "}
          </span>
        );
      },
    },
    {
      header: "city",
      accessor: "city.name",
    },
    {
      header: "country",
      accessor: "country.name",
    },
    {
      header: "created at",
      accessor: "created_at_list",
    },
    {
      header: "Updated at",
      accessor: "updated_at_list",
    },

    {
      header: "Action",
      accessor: "id",
      Cell: ({ cell: { value, row } }) => {
        return (
          <div className="flex gap-2  items-center font-semibold ">
            <span
              className="cursor-pointer hover:text-blue-600 hover:underline "
              // onClick={() => nav(`/brokerage/edit/${value}`)}
              onClick={() => {
                openModal(value, row.original.user_id);
              }}
            >
              Edit
            </span>
            {" | "}
            <span
              className="cursor-pointer hover:text-red-600 hover:underline "
              onClick={() => onDelete(value)}
            >
              Delete
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <div className="flex flex-wrap my-6 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div>
                <h5 className="mb-0 dark:text-white font-bold text-3xl text-blue-800">
                  Search History
                </h5>
              </div>
              <div className="my-auto flex ml-auto lg:mt-0 ">
                <div className="my-auto ml-auto lg:w-60 md:w-60 sm:w-48">
                  <Select
                    isSearchable={false}
                    emoji={"hello"}
                    // isClearable
                    placeholder="All"
                    options={userType}
                    name="condition"
                    styles={customStyles}
                    defaultValue={"All"}
                    // value={values.condition}
                    // components={animatedComponents}
                    className="basic-multi-select focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg  bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none  "
                    classNamePrefix="select"
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        user_type: e ? e.value : null,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" overflow-x-auto p-3">
            <DataTable
              ListQuery={{ data: data?.data, isLoading: isFetching }}
              filterProps={{ filter, setFilter }}
              columns={columns}
              sortingField={[
                "name",
                "email",
                "city.name",
                "country.name",
                "created_at_list",
                "updated_at_list",
              ]}
              searchFields={[]}
            />
          </div>
        </div>
      </div>
      <SearchHistoryEditModal isOpen={isOpen} closeModal={closeModal} id={id} />
    </div>
  );
};

export default SearchHistory;
