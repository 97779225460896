import "Assets/css/datatable.css";
import "Assets/css/soft-ui-dashboard-tailwind.css";
import Routers from "Routes/Routers";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { store } from "store/rtkStore";
import "sweetalert2/src/sweetalert2.scss";
import "./App.css";
function App() {
  return (
    <>
      <Provider store={store}>
        <Routers />
        <Toaster reverseOrder={false} />
        {/* <FrontLogin/> */}
      </Provider>
    </>
  );
}

export default App;
