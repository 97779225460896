import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const HighChart = ({ chartOptions }) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={
        chartOptions || {
          title: {
            text: "Loading ...",
          },
        }
      } // Add fallback in case chartOptions is null
      immutable={false}
      containerProps={{ id: "chartContainer" }}
    />
  );
};

export default HighChart;
