import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";
import { useEffect, useState } from "react";

import Highcharts from "highcharts/highstock";

import HighChart from "Components/Chart/HighChart";
import SkeletonLoader from "Components/Loader/SkeletonLoader";
import CustomEvents from "highcharts-custom-events";
import { useNavigate } from "react-router-dom";
import { useVesselsAnalyticsGraphQuery } from "store/Actions/vesselsAnalyticsGraph";

CustomEvents(Highcharts);
const VesselGraph = ({ graphState, filter }) => {
  const { graphInfo } = graphState;
  const nav = useNavigate();
  const { data, isLoading } = useVesselsAnalyticsGraphQuery({
    filter,
    name: graphInfo?.broker,
  });
  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    if (data) {
      var categories = [];
      if (data?.data && !isLoading ) {
         categories = data?.data?.map((val) => val.vessel_id);
      }
      const sData = [
        {
          name: "visitors",
          data: data?.data?.map((val) => Number(val.visitor_count)),
        },
        {
          name: "guest",
          data: data?.data?.map((val) => Number(val.guest_count)),
        },
      ];

      const options = {
        chart: {
          renderTo: `chartContainer`,
          type: "column",
          showAxes: true,
          marginRight: 0,
          spacingRight: 0,
          zoomType: "x",
        },
        title: {
          text: "Vessels Analytics",
        },
        xAxis: {
          allowDecimals: false,
          min: 0,
          max: categories.length > 50 ? 50 : null,
          categories: [...categories],
          tickLength: 0,
          lineColor: "#ededed",
          labels: {
            rotation: -90,
            align: "right",
            style: {
              fontSize: "13px",
              fontFamily: "Verdana, sans-serif",
            },
            formatter: function () {
              return this.value.length > 8
                ? `${this.value.substring(6, 0)}...`
                : this.value;
            },
            events: {
              click: function () {
                nav(
                  `/vessel-details/view/${this.value}?from=${filter.start_date}&to=${filter.end_date}`
                );
              },
            },
          },
        },

        yAxis: {
          allowDecimals: false,
          showFull: false,
          title: {
            text: "",
          },
          stackLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.total, 0, "", "");
            },
          },
        },
        tooltip: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            const Vessel = data.data.filter(
              (val) => `${val.vessel_id}` == this.x
            )[0];
            let tooltipContent = `<div style="height:130px"> <b>${Vessel?.vessel_id}</b><br/>`;

            tooltipContent += `<img src="${
              Vessel?.vessel_image_url ||
              "https://www.yachtbroker.org/img/vessel_medium.jpg"
            }" style="max-width: 100px; max-height: 100px;" />`;

            tooltipContent += `${this.series.name} Count: ${this?.y}<br/>`;
            tooltipContent += `Display Length (Feet): ${Vessel?.display_length_feet}<br/>`;
            tooltipContent += `Year: ${Vessel?.year}<br/></div> `;

            return tooltipContent;
          },
        },
        legend: {
          enabled: true,
        },

        plotOptions: {
          series: {
            stacking: "normal",
            cursor: "pointer",
            turboThreshold: 5000, //set it to a larger threshold, it is by default to 1000
          },
          column: {
            stacking: "normal",
            point: {
              events: {
                click: function () {
                  nav(
                    `/vessel-details/view/${this?.category}?from=${filter.start_date}&to=${filter.end_date}`
                  );
                },
              },
            },
          },
        },
        series: [...sData],
      };

      setChartOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {" "}
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <HighChart chartOptions={chartOptions} />
      )}
    </>
  );
};
export default VesselGraph;
