const PrimaryButton = ({ event, text }) => {
  return (
    <button
      type="button"
      className="inline-block px-8 py-2 m-0 text-xs font-bold text-center text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft bg-gradient-to-tl from-yachtrDarkBlue to-yachtrBlue shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85"
      onClick={event}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
