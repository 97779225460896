// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating brokerManagement by enhancing and injecting endpoints using serverApi
const BrandAnalytics = serverApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    // Query endpoint for retrieving a list of brokers
    brandsGraph: builder.query({
      query(filter) {
        const { hide_googlebot, ...value } = filter
        const queryString = new URLSearchParams(value).toString();
        return {
          url: `brand-graphs?${queryString && queryString}${hide_googlebot ? "&hide_googlebot=" + hide_googlebot : ""}`,
          method: "GET",
        };
      },
      // Provides broker tags for caching broker list data
      keepUnusedDataFor: 10,
    }),
    brandsVesselGraph: builder.query({
      query({ brandsId, filter }) {
        const { hide_googlebot, ...value } = filter
        const queryString = new URLSearchParams(value).toString();
        return {
          url: `brand-vessel-graphs/${brandsId}?${queryString && queryString}${hide_googlebot ? "&hide_googlebot=" + hide_googlebot : ""}`,
          method: "GET",
        };
      },
      // Provides broker tags for caching broker list data
      keepUnusedDataFor: 10,
    }),
  }),
});

export const { useBrandsGraphQuery, useBrandsVesselGraphQuery } =
  BrandAnalytics;
