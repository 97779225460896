import ResetButton from "Components/Butttons/ResetButton";
import SubmitButton from "Components/Butttons/SubmitButton";
import CustomInput from "Components/Input/Input";
import ComponentLoader from "Components/Loader/ComponentLoader";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
  useBrokerageCreateMutation,
  useBrokerageDetailsMutation,
  useBrokerageUpdateMutation,
} from "store/Actions/brokerageManagment";
import { statusOptions } from "utils/data";

import * as Yup from "yup";

const CreateBrokerageForm = () => {
  const { id } = useParams();
  // Creating schema
  const schema = Yup.object({
    email: Yup.string()
      .email("Invalid email format!!")
      .required("Mail is required!!"),
    password: id
      ? Yup.string().min(6, "Too Short!")
      : Yup.string().min(6, "Too Short!").required("Password required!"),
    brokerage_name: Yup.string().required(" brokerage name Required"),
    related_entity_id: Yup.string().required(" brokerage id Required"),
    username: Yup.string().required("username is Required"),
    contact_person: Yup.string().required("contact person Required"),
    address_line1: Yup.string().required("address line 1 Required"),
    city: Yup.string().required("city Required"),
    state: Yup.string().required("state Required"),
    zip_code: Yup.string().required("zip code Required"),
    country: Yup.string().required("country Required"),
  });

  const [currentBrokerage, setCurrentBrokerage] = useState({});
  const nav = useNavigate();
  const [brokerageCreate, { isLoading: createLoading }] =
    useBrokerageCreateMutation();
  const [brokerageUpdate, { isLoading: updateLoading }] =
    useBrokerageUpdateMutation();
  const [brokerageDetails, brokerageDetailsAPI] = useBrokerageDetailsMutation();
  useEffect(() => {
    if (id) {
      brokerageDetails(id).then((res) => {
        if (res?.error) {
          nav("/brokerage");
        } else {
          setCurrentBrokerage(res?.data?.data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      {brokerageDetailsAPI?.isLoading ? (
        <ComponentLoader />
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            brokerage_name: id ? currentBrokerage?.brokerage_name : "",
            related_entity_id: id ? currentBrokerage?.related_entity_id : "",
            username: id ? currentBrokerage?.username : "",
            contact_person: id ? currentBrokerage?.contact_person : "",
            password: id ? currentBrokerage?.password : "",
            address_line1: id ? currentBrokerage?.address_line1 : "",
            address_line2: id ? currentBrokerage?.address_line2 : "",
            city: id ? currentBrokerage?.city : "",
            state: id ? currentBrokerage?.state : "",
            zip_code: id ? currentBrokerage?.zip_code : "",
            country: id ? currentBrokerage?.country : "",
            email: id ? currentBrokerage?.email : "",
            status: id ? currentBrokerage?.status : "Active",
          }}
          onSubmit={async (values) => {
            if (!updateLoading && id) {
              brokerageUpdate({ id, values }).then((res) => {
                if (res.error) {
                  toast.error(res.error.data.message);
                } else {
                  toast.success(res.data?.message);
                  nav("/brokerage");
                }
              });
            } else if (!createLoading && !updateLoading) {
              brokerageCreate(values).then((res) => {
                if (res.error) {
                  toast.error(res.error.data.message);
                } else {
                  toast.success(res.data?.message);
                  nav("/brokerage");
                }
              });
            }
          }}
          validationSchema={schema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div
                  form="user"
                  className="absolute top-0 left-0 flex flex-col visible w-full h-auto min-w-0 p-4 break-words bg-white border-0 opacity-100 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border"
                >
                  <h5 className="mb-0 font-bold dark:text-white">
                    {id ? " Edit Brokerage" : " Create Brokerage"}
                  </h5>
                  <p className="mb-0 leading-normal text-sm">
                    Fill Following details
                  </p>
                  <div>
                    {/* row 0 */}
                    <div className="flex flex-wrap mt-4 -mx-3">
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="related_entity_id"
                          values={values}
                          type="number"
                          label="brokerage id"
                          placeholder="brokerage id"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          isRequire={true}
                        />
                      </div>
                      <div className="w-full max-w-full px-3 mt-4 flex-0 sm:mt-0 sm:w-6/12 ">
                        <label className="inline-block w-full mb-2 ml-1 font-bold text-slate-700 text-xs">
                          <div className="flex justify-between">
                            <span className="font-semibold tracking-wide text-sm">
                              Status
                            </span>
                          </div>
                        </label>
                        <select
                          disabled={!id}
                          name="status"
                          value={values?.status || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none ${
                            id ? " cursor-pointer" : "cursor-not-allowed"
                          }`}
                        >
                          {statusOptions?.map((option) => (
                            <option key={option.name} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/* row 1 */}
                    <div className="flex flex-wrap mt-4 -mx-3">
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="brokerage_name"
                          values={values}
                          type="text"
                          label="Brokerage Name"
                          placeholder="Brokerage Name"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          isRequire={true}
                        />
                      </div>
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="email"
                          values={values}
                          type="text"
                          label="Email"
                          placeholder="email id"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          isRequire={true}
                        />
                      </div>
                    </div>
                    {/* row 2 */}
                    <div className="flex flex-wrap mt-4 -mx-3">
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="username"
                          values={values}
                          type="text"
                          label="Username"
                          placeholder="Username"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          isRequire={!id}
                        />
                      </div>
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="contact_person"
                          values={values}
                          type="text"
                          label="contact person"
                          placeholder="Contact person"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          isRequire={true}
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap mt-4 -mx-3">
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="password"
                          values={values}
                          type="password"
                          label="Password"
                          placeholder="password"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          isRequire={!id}
                        />
                      </div>
                    </div>
                    {/* row 3 */}
                    <div className="flex flex-wrap mt-4 -mx-3">
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="address_line1"
                          values={values}
                          type="text"
                          label="address line1"
                          placeholder="address line1"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          isRequire={true}
                        />
                      </div>
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="address_line2"
                          values={values}
                          type="text"
                          label="address line2"
                          placeholder="address line2"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>
                    {/* row 4 */}
                    <div className="flex flex-wrap mt-4 -mx-3">
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="city"
                          values={values}
                          type="text"
                          label="city"
                          placeholder="city"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          isRequire={true}
                        />
                      </div>
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="zip_code"
                          values={values}
                          type="number"
                          label="zip code"
                          placeholder="zip code"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          isRequire={true}
                        />
                      </div>
                    </div>
                    {/* row 5 */}
                    <div className="flex flex-wrap mt-4 -mx-3">
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="state"
                          values={values}
                          type="text"
                          label="State"
                          placeholder="State"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          isRequire={true}
                        />
                      </div>
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="country"
                          values={values}
                          type="text"
                          label="country"
                          placeholder="country"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          isRequire={true}
                        />
                      </div>
                    </div>

                    <div className="flex justify-end gap-3 flex-wrap">
                      <div className="flex mt-6">
                        <ResetButton event={() => nav("/brokerage")} />
                      </div>
                      <div className="flex mt-6">
                        <SubmitButton
                          isLoading={createLoading || updateLoading}
                          text={id ? " Update Brokerage" : " Create Brokerage"}
                          width="w-46"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default CreateBrokerageForm;
