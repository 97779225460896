import toast from "react-hot-toast";
import { useFollowUnfollowMutation } from "store/Actions/guestFollow";

const FollowCard = ({ search, setFilter, ListQuery }) => {
  const { data, refetch } = ListQuery;
  const [vesselUnfollow] = useFollowUnfollowMutation();

  const vesselUnfollowBtn = (id) => {
    vesselUnfollow(id).then((res) => {
      if (res.error) {
        toast.error(res.error.data.message);
      } else {
        toast.success(res.data?.message);
        if (data?.data.length === 1) {
          setFilter((filter) => ({
            ...filter,
            page: data.last_page - 1,
          }));
        } else {
          refetch();
        }
      }
    });
  };

  return (
    <div className="w-full max-w-full mb-6 md:w-6/12  lg:w-4/12  md:flex-none xl:mb-0 xl:w-3/12 p-5 ">
      {/* <div className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
      <div className="flex-auto p-5">
        <div className="flex justify-between ">
          <div>
            <h4 className="mb-0 font-normal"> {`${search.DisplayLengthFeet }'  ${search.Manufacturer } ${search.Year }`}</h4>
          </div>
          <div className="flex flex-col gap-1">
            <XMarkIcon className="h-6 w-6 font-bold cursor-pointer text-red-650 " onClick={()=>vesselUnfollowBtn(search.id)} />
           
          </div>
        </div>

      
      </div>
    </div> */}
      <div
        className="col-span-6 lg:col-span-3 xl:col-span-3 p-4  relative flex flex-col min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border "
        id="yachts_for_sale_vessel_div_2787599"
      >
        <div className="type-box relative">
          <div className="hidden"></div>
          <div className="qodef-m-image p-1 w-full ">
            <img
              className="img-fluid vessel_image w-full   rounded-xl"
              src={
                `https://cdn.yachtbroker.org/images/thumbnails/` +
                search.DisplayPicture
              }
              alt=" "
              title={`${search.DisplayLengthFeet} ft ${search.Manufacturer} ${search.Year}  ${search.Country} ${search.ID} `}
            />
          </div>

          <div className="flex justify-end my-3 ">
            <div className="flex flex-col gap-1">
              <button
                type="button"
                className="inline-block px-6 py-2  font-bold text-center uppercase align-middle transition-all bg-transparent border rounded-lg cursor-pointer border-red-500 leading-pro text-xxs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-red-500"
                value={search.ID}
                onClick={() => vesselUnfollowBtn(search.ID)}
              >
                unfollow
              </button>
              {/* <XMarkIcon className="h-6 w-6 font-bold cursor-pointer text-red-650 " onClick={()=>vesselUnfollowBtn(search.id)} /> */}
            </div>
          </div>
          <div>
            <h4 className="mb-0 text-xl font-semibold">
              {" "}
              {`${search.DisplayLengthFeet} '  ${search.Manufacturer} ${search.Year}`}
            </h4>
            <div className="flex justify-between mt-2">
              <div className="max-w-6/10 text-xs break-words">
                <div>
                  <span>{search.City && `${search.City} ,`} </span>{" "}
                  <span>{search.State && `${search.State} ,`}</span>
                  <span>{search.Country}</span>
                </div>
                <div>
                  <b>$</b> {search.PriceUSD.toLocaleString()} USD
                </div>
              </div>
              <div className="max-w-4/10 text-xs break-words">
                <div className="text-3xs  font-semibold ">Brokered By</div>
                <div>{search.ListingOwnerBrokerageName}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowCard;
