import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import EditSearchFilter from "Components/Forms/EditSearchFilter";
// import { XMarkIcon } from "@heroicons/react/24/outline"
import { Fragment } from "react";

const EditModal = ({ openModal, setOpenModal, defaultData }) => {
  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog as="div" className="relative z-50 " onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80 " />
        </Transition.Child>

        <div className="fixed inset-0 flex justify-end">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className="relative flex w-full md:max-w-lg flex-1">
              {" "}
              <div className="flex grow flex-col gap-y-5   bg-white fli ring-white/10 overflow-x-auto">
                <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                  <div className="flex items-center">
                    <div>
                      <h5 className=" dark:text-white font-bold text-3xl text-blue-800 mb-0">
                        Notification Parameters
                      </h5>
                      <p className="mb-0 leading-normal text-sm"></p>
                    </div>
                    <div className="my-auto flex ml-auto lg:mt-0 ">
                      <div className="my-auto ml-auto">
                        <XMarkIcon
                          className="h-8 w-8 text-red-800 cursor-pointer"
                          aria-hidden="true"
                          onClick={() => setOpenModal(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-8">
                  <EditSearchFilter
                    defaultData={defaultData}
                    setOpenModal={setOpenModal}
                  />
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditModal;
