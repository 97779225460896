import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useBlogDetailQuery } from "store/Actions/blogs";
import moment from "moment";
import ComponentLoader from "Components/Loader/ComponentLoader";
import Thumbnail from "Assets/thumbnail.jpeg";
import { getBlogImagePrefix } from "utils/functions";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
const BlogDetail = () => {
  const { blogId } = useParams();
  const [blogData, setBlogData] = useState({});
  const { data, isFetching } = useBlogDetailQuery(blogId);

  useEffect(() => {
    if (data) {
      setBlogData(data.data);
    }
  }, [data]);

  if (isFetching) {
    return <ComponentLoader />;
  }

  return (
    <div className="flex flex-wrap my-6 -mx-3">
      <style>
        {`
          h1 {
            font-size: 2em;
            font-weight: bold;
          }
          h2 {
            font-size: 1.5em;
            font-weight: bold;
          }
          h3 {
            font-size: 1.17em;
            font-weight: bold;
          }
        `}
      </style>
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="flex items-center mb-5 gap-2 montserrat-font">
              <Link
                to="/blogs"
                className="flex items-center px-4 py-2 text-black rounded focus:outline-none"
              >
                <ArrowLeftIcon className="w-5 h-5 mr-2" /> Back
              </Link>
            </div>
            <div className="lg:flex">
              <div className="w-full">
                <img
                  src={
                    blogData?.image
                      ? getBlogImagePrefix(blogData?.image)
                      : Thumbnail
                  }
                  alt="Blog Detail"
                  style={{ height: "600px" }}
                  className=" rounded-t-2xl w-full "
                />
                <div className="p-4">
                  <h5 className="mb-2 dark:text-white font-bold text-3xl text-blue-800">
                    {blogData?.title}
                  </h5>
                  <div className="mb-4 text-gray-700">
                    {moment(blogData?.date).format("MMMM Do YYYY")}
                  </div>
                  <div
                    className="text-gray-700"
                    dangerouslySetInnerHTML={{ __html: blogData?.content }}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
