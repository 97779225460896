import SettingForm from "Components/Forms/SettingForm";

const Setting = () => {
  return (
    <div className="w-full my-6 mx-auto">
      <div className="flex flex-wrap -mx-3">
        <div className="w-full max-w-full px-3 flex-0 ">
          <div className="mb-12">
            <div className="flex flex-wrap -mx-3">
              <div className="w-full max-w-full px-3 m-auto flex-0 lg:w-full ">
                <div className="relative mb-32">
                  <SettingForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
