// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating brokerManagement by enhancing and injecting endpoints using serverApi
const boatInquiry = serverApi
  .enhanceEndpoints({ tagTypes: ["BoatInquiries"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of brokers
      boatInquiryBrokerage: builder.query({
        query(filter) {
          return {
            url: `boat-inquiries-brokerage?page=${filter.page || 1}&per_page=${
              filter?.perPage || 50
            }&sort_field=${filter?.sort || "created_at"}&sort_order=${
              filter?.order || "desc"
            }`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        keepUnusedDataFor: 1,
        providesTags: ["BoatInquiries"],
      }),
      boatInquiryBroker: builder.query({
        query(filter) {
          return {
            url: `boat-inquiries-broker?page=${filter.page || 1}&per_page=${
              filter?.perPage || 50
            }&sort_field=${filter?.sort || "created_at"}&sort_order=${
              filter?.order || "desc"
            } `,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        keepUnusedDataFor: 1,
        providesTags: ["BoatInquiries"],
      }),
      boatInquiryGuest: builder.query({
        query(filter) {
          return {
            url: `boat-inquiries-guest?page=${filter.page || 1}&per_page=${
              filter?.perPage || 50
            }&sort_field=${filter?.sort || "created_at"}&sort_order=${
              filter?.order || "desc"
            } `,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        keepUnusedDataFor: 1,
        providesTags: ["BoatInquiries"],
      }),
      boatInquiryAdmin: builder.query({
        query(filter) {
          return {
            url: `boat-inquiries-admin?page=${filter.page || 1}&per_page=${
              filter?.perPage || 50
            }&sort_field=${filter?.sort || "created_at"}&sort_order=${
              filter?.order || "desc"
            } `,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        keepUnusedDataFor: 1,
        providesTags: ["BoatInquiries"],
      }),
    }),
  });

export const {
  useBoatInquiryBrokerageQuery,
  useBoatInquiryBrokerQuery,
  useBoatInquiryGuestQuery,
  useBoatInquiryAdminQuery,
} = boatInquiry;
