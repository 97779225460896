import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
/**
 * ? condition that  if there is  token is not there the children is render
 */
const ProtectedLogout = ({ children, name }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const showTitle = name ?? "";
    document.title = showTitle ? `Yachtr | ${showTitle}` : "Yachtr";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
  if (!localStorage.getItem("token")) {
    return children;
  }
  return <Navigate to="/" replace />;
};

export default ProtectedLogout;
