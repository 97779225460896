import DataTable from "Components/Table/Index";
import moment from "moment";
import { useState } from "react";
import { useActivityLogQuery } from "store/Actions/adminAction";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";
import { currentDate, currentMonth, currentYear } from "utils/functions";
const ActivityLog = () => {
  const [filter, setFilter] = useState({
    sort: "created_at",
    order: "desc",
    perPage: 50,
    page: 1,
    start_date: `${currentMonth()}-01-${currentYear()}`,
    end_date: `${currentMonth()}-${currentDate()}-${currentYear()}`,
  });
  const { data, isLoading, isFetching } = useActivityLogQuery(filter);
  const columns = [
    {
      header: "Description",
      accessor: "description",
    },
    {
      header: "User Name",
      accessor: "name",
      Cell: ({ cell: { row } }) => row.original.user.name,
    },
    {
      header: "IP Address",
      accessor: "ip",
      Cell: ({ cell: { row } }) => row.original.ip_address,
    },
    {
      header: "User Agent",
      accessor: "user_agent",
    },
    {
      header: "Created At",
      accessor: "created_at",
      Cell: ({ cell: { value } }) => {
        return (
          <span className={``}>
            {moment(value).format("MM-DD-YYYY HH:mm:ss")}
          </span>
        );
      },
    },
  ];
  const setDate = (newValue) => {
    const startDate = moment(newValue[0]).format("MM-DD-Y");
    const endDate = moment(newValue[1]).format("MM-DD-Y");
    setFilter(() => {
      return {
        ...filter,
        start_date: startDate,
        end_date: endDate,
        // daterange: `${startDate} - ${endDate}`,
      };
    });
  };
  return (
    <div className="flex flex-wrap my-6 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 break-words bg-white p-6 border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6  pb-0">
            <div className="lg:flex ">
              <div>
                <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                  Activity Log
                </h5>
              </div>
            </div>
            <div className="my-auto flex ml-auto lg:mt-0 ">
              <div className="my-auto ml-auto">
                <div className=" flex">
                  <div className=" min-h-6 pl-7 flex w-full items-center gap-2"></div>
                  <Flatpickr
                    options={{
                      mode: "range",
                      dateFormat: "m-d-Y",
                      defaultDate: [
                        `${currentMonth()}-01-${currentYear()}`,
                        `${currentMonth()}-${currentDate()}-${currentYear()}`,
                      ],
                    }}
                    fromdateid="DashboardEndDatePicker"
                    selectvalue={[]}
                    className="form-control clickable  focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-fit appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mx-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                    onChange={setDate}
                    placeholder="Select Date Range 🗓️"
                  />
                </div>
              </div>
            </div>
          </div>

          <DataTable
            ListQuery={{ data: data?.data, isLoading, isFetching }}
            filterProps={{ filter, setFilter }}
            columns={columns}
            sortingField={["description", "name", "user_agent", "created_at"]}
            searchFields={["name", "ip"]}
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;
