import { useEffect, useState } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useBlogDetailQuery, useUpdateBlogMutation } from "store/Actions/blogs";
import UploadBlogImage from "./UploadBlogImage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";
import ComponentLoader from "Components/Loader/ComponentLoader";
import moment from "moment";
import { getBlogImagePrefix } from "utils/functions";
const schema = Yup.object({
  title: Yup.string().required("Title is required"),
  date: Yup.date().required("Date is required"),
  content: Yup.string().required("Content is required"),
});

export default function BlogEditForm({ closeModal, id, refetch }) {
  const { data: blogData, isLoading: getBlogLoading } = useBlogDetailQuery(id, {
    skip: null || !id,
  });
  const [updateBlog, { isLoading: updateLoading }] = useUpdateBlogMutation();
  const [initialValues, setInitialValues] = useState({
    title: "",
    date: "",
    content: "",
  });

  useEffect(() => {
    if (blogData) {
      setInitialValues({
        title: blogData?.data?.title || "",
        date: moment(blogData?.data?.date).format("YYYY-MM-DD") || "",
        content: blogData?.data?.content || "",
      });
    }
  }, [blogData]);
  return (
    <>
      {getBlogLoading ? (
        <ComponentLoader />
      ) : (
        <div className="min-h-70-screen">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={async (values) => {
              if (initialValues != values) {
                const payload = {
                  title: values.title,
                  date: values.date,
                  content: values.content,
                };
                !updateLoading &&
                  updateBlog({ id, data: payload }).then((res) => {
                    if (res?.error) {
                      toast.error(res?.error?.data?.message);
                    } else {
                      toast.success(res?.data?.message);
                      closeModal();
                      refetch();
                    }
                  });
              } else {
                closeModal();
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              // handleChange,
              // handleBlur,
              handleSubmit,
              setFieldValue,
              dirty,
            }) => (
              <form onSubmit={handleSubmit} className="flex flex-col gap-5">
                <div className="flex flex-col lg:flex-row gap-2">
                  <div className="flex flex-col w-full lg:w-2/3 gap-4 p-2 shadow-soft-xl ">
                    <div>
                      <label
                        htmlFor="title"
                        className="block text-sm  font-normal"
                      >
                        Title
                      </label>
                      <Field
                        type="text"
                        name="title"
                        className="w-full px-3 py-2 border rounded"
                        placeholder="Enter Title"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="date"
                        className="block text-sm font-normal "
                      >
                        Date
                      </label>
                      <Flatpickr
                        options={{
                          dateFormat: "Y-m-d",
                        }}
                        value={values.date}
                        onChange={(date) => {
                          setFieldValue("date", date[0]);
                        }}
                        placeholder="select Date"
                        className="form-control clickable  focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-fit appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none"
                      />
                      {errors.date && touched.date ? (
                        <div className="error text-red-600">{errors.date}</div>
                      ) : null}
                    </div>
                    <div>
                      <div className="mb-4 App">
                        <label className="block text-sm font-medium text-gray-700">
                          Content
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={values.content}
                          config={{
                            placeholder: "Blog content here...",
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "link",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                              "|",
                              "undo",
                              "redo",
                            ],
                            heading: {
                              options: [
                                {
                                  model: "paragraph",
                                  title: "Paragraph",
                                  class: "ck-heading_paragraph",
                                },
                                {
                                  model: "heading1",
                                  view: "h1",
                                  title: "Heading 1",
                                  class: "ck-heading_heading1",
                                },
                                {
                                  model: "heading2",
                                  view: "h2",
                                  title: "Heading 2",
                                  class: "ck-heading_heading2",
                                },
                                {
                                  model: "heading3",
                                  view: "h3",
                                  title: "Heading 3",
                                  class: "ck-heading_heading3",
                                },
                              ],
                            },
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFieldValue("content", data);
                          }}
                        />
                        {touched.content && errors.content && (
                          <p className="mt-2 text-sm text-red-600">
                            {errors.content}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/3 flex flex-col items-center ">
                    <UploadBlogImage
                      imageUrl={getBlogImagePrefix(blogData?.data?.image)}
                      id={blogData?.data?.id}
                    />
                  </div>
                </div>
                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-blue-500 hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    disabled={updateLoading || !dirty}
                  >
                    {updateLoading ? "Updating..." : "Update"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}
