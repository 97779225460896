import ButtonLoader from "Components/Loader/ButtonLoader";

const LoginButton = ({ isLoading, text }) => {
  return (
    <button
      type="submit"
      className="inline-block w-full px-6 py-3 mt-6 mb-0 font-bold  text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer hover:scale-102 active:opacity-85 hover:shadow-soft-xs bg-gradient-to-tl from-yachtrDarkBlue to-yachtrBlue leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25  text-center justify-center"
    >
      {isLoading ? <ButtonLoader /> : text}
    </button>
  );
};

export default LoginButton;
