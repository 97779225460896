import ComponentLoader from "Components/Loader/ComponentLoader";
import Pagination from "Components/Pagination/Pagination";
import { perPageCardOptions, perPageFollowCardOptions } from "utils/data";
import Card from "./Card";
import FollowCard from "./FollowCard";
import { useEffect, useState } from "react";

const CardList = ({
  ListQuery,
  filterProps,
  setOpenModal,
  setDefaultData,
  type,
}) => {
  const { data, isLoading } = ListQuery;
  const { filter, setFilter } = filterProps;
  const [options, setOptions] = useState([]);
  useEffect(() => {
    type === "followYacht"
      ? setOptions(perPageFollowCardOptions)
      : setOptions(perPageCardOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="dataTable-wrapper">
        {data?.data?.length ? (
          <div className="dataTable-top">
            <div className="dataTable-dropdown">
              <label>
                <select
                  className="dataTable-selector"
                  value={filter.perPage}
                  onChange={(e) => {
                    setFilter((prev) => ({
                      ...prev,
                      ...{ perPage: e.target.value, page: 1 },
                    }));
                  }}
                >
                  {options?.map((option) => (
                    <option key={option.name} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>{" "}
                entries per page
              </label>
            </div>
          </div>
        ) : (
          <></>
        )}
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div className=" overflow-x-auto p-3">
            {data?.data?.length ? (
              <div className=" flex flex-wrap">
                {data?.data?.map((search, key) =>
                  type === "followYacht" ? (
                    <FollowCard
                      setFilter={setFilter}
                      ListQuery={ListQuery}
                      search={search}
                      key={key}
                      setOpenModal={setOpenModal}
                      setDefaultData={setDefaultData}
                    />
                  ) : (
                    <Card
                      setFilter={setFilter}
                      ListQuery={ListQuery}
                      search={search}
                      key={key}
                      setOpenModal={setOpenModal}
                      setDefaultData={setDefaultData}
                    />
                  ),
                )}
              </div>
            ) : (
              <div className="text-2xl text-red-900 text-center font-semibold p-5">
                Data Not Found
              </div>
            )}
          </div>
        )}
        {data?.data?.length ? (
          <div className="dataTable-bottom mt-5">
            <div className="dataTable-info">
              {isLoading
                ? "Loading..."
                : `Showing ${data?.from} to ${data?.to} of ${data?.total} entries`}
            </div>
            <Pagination
              currentPage={data?.current_page}
              totalCount={data?.total}
              pageSize={data?.per_page}
              onPageChange={(page) => {
                setFilter((prev) => ({
                  ...prev,
                  ...{ page: page },
                }));
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default CardList;
