import { HomeIcon } from "@heroicons/react/24/outline";
import { routesData } from "Routes/RoutesData";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function AddressBar() {
  const nav = useNavigate();
  const { pathname } = useLocation();

  const [pathSegments, setPathSegments] = useState([]);
  useEffect(() => {
    const segments = pathname.split("/").slice(1);

    // Check if "edit" is present in the segments
    const editIndex = segments.indexOf("edit");
    const viewIndex = segments.indexOf("view");

    if (editIndex !== -1) {
      // Remove "edit" and the following segment
      setPathSegments(segments.slice(0, editIndex + 1));
    } else if (viewIndex !== -1) {
      setPathSegments(segments.slice(0, viewIndex + 1));
    } else {
      // Keep the entire array
      setPathSegments(segments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const generatedPaths = [];

  pathSegments.forEach((keyword, index) => {
    let path = "";
    for (let i = 0; i <= index; i++) {
      path += "/" + pathSegments[i];
    }
    generatedPaths.push(path);
  });

  const filteredRoutes = routesData.filter((route) => {
    return generatedPaths.some((generatedPath) => {
      if (generatedPath.includes("view") || generatedPath.includes("edit")) {
        return route?.path.includes(generatedPath);
      }
      return route.path == generatedPath;
    });
  });
  return (
    filteredRoutes.length > 0 && (
      <div className="flex items-center w-fit  gap-x-2 p-3.5 lg:px-5 lg:py-3 rounded-3xl text-slate-600/70">
        <>
          <div className="flex gap-x-2 text-sm font-medium justify-center items-center">
            <div onClick={() => nav("/")}>
              <HomeIcon className="w-6 h-6 cursor-pointer hover:text-slate-600" />
            </div>
            {/* <ChevronRightIcon className="w-6 h-6 cursor-pointer" /> */}/
            {filteredRoutes?.map((segment, i) => {
              return (
                <React.Fragment key={segment.path}>
                  <>
                    <span
                      className="uppercase tracking-wider cursor-pointer"
                      onClick={() => {
                        if (i !== filteredRoutes.length - 1) {
                          nav(`${filteredRoutes[i]?.path}`);

                        }
                      }}
                    >
                      <span
                        key={segment}
                        className={`hover:text-slate-800 rounded-full ${
                          i + 2 === pathname.split("/").length &&
                          "text-slate-900"
                        }`}
                      >
                        {segment?.name.split("-").join(" ") || ""}
                      </span>
                    </span>
                    {filteredRoutes.length > 1 &&
                      i + 1 !== filteredRoutes.length &&
                      "/"}
                  </>
                </React.Fragment>
              );
            })}
          </div>
        </>
      </div>
    )
  );

  // return (
  // <Box>
  //   <Breadcrumb
  //     fontWeight="medium"
  //     separator={<ChevronRightIcon color="gray.500" />}
  //   >
  //     {path.map((route, index) => (
  //       <BreadcrumbItem
  //         isCurrentPage={index === path.length - 1}
  //         key={route.name}
  //       >
  //         <BreadcrumbLink
  //           as={Link}
  //           to={index === path.length - 1 ? pathname : route.path}
  //         >
  //           {route.name}
  //         </BreadcrumbLink>
  //       </BreadcrumbItem>
  //     ))}
  //   </Breadcrumb>
  // </Box>
  // <></>
  // );
}
