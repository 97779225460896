import Select from "react-select";

const CustomSelect = ({
  values,
  options,
  changeEvent,
  name,
  isMulti,
  components,
  placeholder,
}) => {
  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      justifyContent: "center",
    }),
  };
  return (
    <Select
      isMulti={isMulti || false}
      placeholder={placeholder || ""}
      options={[...(options || [])]}
      name={name || ""}
      isClearable
      value={values[name] || ""}
      styles={!isMulti && customStyles}
      components={components}
      className="basic-multi-select focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg  bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none  "
      classNamePrefix="select"
      onChange={(e) => {
        changeEvent(e);
      }}
    />
  );
};

export default CustomSelect;
