import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import UploadBlogImage from "Components/Forms/UploadBlogImage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useBlogCreateMutation } from "store/Actions/blogs";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";
import moment from "moment";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
const AddBlogModal = ({ isOpen, closeModal, refetch }) => {
  const steps = [{ label: "Blog Details" }, { label: "Upload Image" }];
  const [currentStep, setCurrentStep] = useState(0);
  const [blogId, setBlogId] = useState(null);
  const [createBlog, { isLoading: createBlogLoading }] =
    useBlogCreateMutation();
  const initialValues = {
    title: "",
    date: "",
    content: "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    date: Yup.date().required("Date is required"),
    content: Yup.string().required("Content is required"),
  });

  function onCloseModel() {
    closeModal();
    if (currentStep == 1) {
      refetch();
      setBlogId(null);
      setCurrentStep(0);
    }
  }

  const handleNext = async (values) => {
    const newValues = {
      ...values,
      date: moment(values?.date).format("YYYY-MM-DD"),
    };
    if (currentStep === 0) {
      createBlog(newValues).then((res) => {
        if (res.error) {
          // toast.error(res?.error?.data?.message);
        } else {
          // toast.success(res?.data?.message);
          if (res?.data?.data?.id) {
            setBlogId(res?.data?.data?.id);
            setCurrentStep(currentStep + 1);
          }
        }
      });
    } else if (currentStep === 1) {
      onCloseModel();
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCloseModel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-70-screen p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <button
                  type="button"
                  className="absolute top-4 right-4 inline-flex items-center justify-center rounded-full p-2 text-gray-700 hover:text-gray-800 focus:outline-none"
                  onClick={onCloseModel}
                >
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {steps[currentStep]?.label}
                </Dialog.Title>

                <div className="mt-4">
                  <div className="flex justify-between mb-6">
                    {steps.map((step, index) => (
                      <div
                        key={index}
                        className={`flex-1 text-center ${index === currentStep ? "text-blue-500" : "text-gray-500"}`}
                      >
                        <div
                          className={`w-8 h-8 mx-auto mb-2 flex items-center justify-center rounded-full ${index === currentStep ? "bg-blue-500 text-white" : currentStep == 1 && index == 0 ? "bg-green-300 text-white" : "bg-gray-200"}`}
                        >
                          {index + 1}
                        </div>
                        <div className="text-sm">{step.label}</div>
                      </div>
                    ))}
                  </div>

                  <div className="bg-white p-6 rounded-lg border-2 border-gray-200">
                    {currentStep === 0 && (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleNext}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <Form>
                            <div className="mb-4">
                              <label className="block text-sm font-medium text-gray-700">
                                Blog Title
                              </label>
                              <Field
                                type="text"
                                name="title"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="Enter Title"
                              />
                              <ErrorMessage
                                name="title"
                                component="div"
                                className="mt-1 text-red-500 text-sm"
                              />
                            </div>
                            <div className="mb-4">
                              <label className="block text-sm font-medium text-gray-700">
                                Publication Date
                              </label>
                              <Flatpickr
                                options={{
                                  dateFormat: "d-m-Y",
                                }}
                                value={values.date}
                                onChange={(date) => {
                                  setFieldValue("date", date[0]);
                                }}
                                placeholder="Select Date"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              />
                              {errors.date && touched.date ? (
                                <div className="mt-1 text-red-500 text-sm">
                                  {errors.date}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-4 App">
                              <label className="block text-sm font-medium text-gray-700">
                                Content
                              </label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={values.content}
                                config={{
                                  placeholder: "Blog content here...",
                                  toolbar: [
                                    "heading",
                                    "|",
                                    "bold",
                                    "italic",
                                    "link",
                                    "bulletedList",
                                    "numberedList",
                                    "blockQuote",
                                    "|",
                                    "undo",
                                    "redo",
                                  ],
                                  heading: {
                                    options: [
                                      {
                                        model: "paragraph",
                                        title: "Paragraph",
                                        class: "ck-heading_paragraph",
                                      },
                                      {
                                        model: "heading1",
                                        view: "h1",
                                        title: "Heading 1",
                                        class: "ck-heading_heading1",
                                      },
                                      {
                                        model: "heading2",
                                        view: "h2",
                                        title: "Heading 2",
                                        class: "ck-heading_heading2",
                                      },
                                      {
                                        model: "heading3",
                                        view: "h3",
                                        title: "Heading 3",
                                        class: "ck-heading_heading3",
                                      },
                                    ],
                                  },
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue("content", data);
                                }}
                              />
                              {touched.content && errors.content && (
                                <p className="mt-2 text-sm text-red-600">
                                  {errors.content}
                                </p>
                              )}
                            </div>
                            <div className="flex justify-end mt-6">
                              {currentStep !== 0 && (
                                <button
                                  type="button"
                                  onClick={handlePrevious}
                                  className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                                  disabled={currentStep === 0}
                                >
                                  Previous
                                </button>
                              )}
                              <button
                                type="submit"
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                disabled={isSubmitting}
                              >
                                {isSubmitting || createBlogLoading
                                  ? "Next..."
                                  : currentStep === steps.length - 1
                                    ? "Finish"
                                    : "Next"}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                    {currentStep === 1 && blogId && (
                      <div>
                        <UploadBlogImage
                          id={blogId}
                          onCloseModel={onCloseModel}
                        />
                        <div className="flex justify-end mt-4">
                          <button
                            type="button"
                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            onClick={handleNext}
                          >
                            Finish
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddBlogModal;
