import Flatpickr from "react-flatpickr";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import DataTable from "Components/Table/Index";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import {
  loggedUserRole,
} from "utils/functions";
const debounce = (func, delay) => {
  let timeoutId;

  return function (...args) {
    const later = () => {
      timeoutId = null;
      func.apply(this, args);
    };

    clearTimeout(timeoutId);

    timeoutId = setTimeout(later, delay);
  };
};
const animatedComponents = makeAnimated();
const VesselsAnalytics = ({
  ListQuery,
  filterProps,
  columns,
  sortingField,
  searchFields,
  brokerList,
  SearchId,
  searchPlaceHolder,
  hideBot = false,
  searchByBrokerage = false,
  searchByGuest = false,
}) => {
  const { filter, setFilter } = filterProps;
  const selectBroker = (val) => {
    const valuesString = val?.map((item) => item.value).join(",");
    setFilter(() => ({ ...filter, broker_name: valuesString }));
  };


  const [brokerOptions, setBrokerOptions] = useState([]);
  useEffect(() => {
    setBrokerOptions(() => []);
    brokerList?.data.map((broker) => {
      setBrokerOptions((brokerOptions) => [
        ...brokerOptions,
        { value: broker, label: broker },
      ]);
    });
  }, [brokerList]);

  const setDate = (newValue) => {
    const startDate = moment(newValue[0]).format("MM-DD-YYYY");
    const endDate = moment(newValue[1]).format("MM-DD-YYYY");
    setFilter((prev) => {
      return {
        ...prev,
        start_date: startDate,
        end_date: endDate,
      };
    });
  };
  const selectGuest = useMemo(
    () =>
      debounce((key, val) => {
        setFilter((prev) => ({
          ...prev,
          [key]: val,
        }));
      }, 500),
    []
  );
  return (
    <div>
      {" "}
      <div className="flex w-full gap-4  p-6 pb-0 max-w-6/12 ">
        <div className="flex w-full gap-4 max-w-1/2">
          {loggedUserRole() === "Brokerage" ? (
            <div className="flex w-full">
              <Select
                isMulti
                placeholder="Select a Broker"
                options={brokerOptions}
                components={animatedComponents}
                className="basic-multi-select focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg  bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none  "
                classNamePrefix="select"
                onChange={selectBroker}
              />
            </div>
          ) : (
            <></>
          )}

          {SearchId && (
            <input
              className={`focus:shadow-soft-primary-outline max-w-1/2 dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block  appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-cyan-300 focus:outline-none`}
              placeholder={searchPlaceHolder || "Search By Vessel ID"}
              onChange={(e) => {
                selectGuest("vessel_id", e.target.value);
              }}
            />
          )}
          {searchByBrokerage && (
            <input
              className={`focus:shadow-soft-primary-outline max-w-1/2 dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block  appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-cyan-300 focus:outline-none`}
              placeholder={"Search by Brokerage"}
              onChange={(e) => {
                selectGuest("brokerage", e.target.value);
              }}
            />
          )}
          {searchByGuest && (
            <input
              className={`focus:shadow-soft-primary-outline max-w-1/2 dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block  appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-cyan-300 focus:outline-none`}
              placeholder={"Search by Guest"}
              onChange={(e) => {
                selectGuest("guest_id", e.target.value);
              }}
            />
          )}
        </div>
        <div className="my-auto ml-auto">
          <div className=" flex">
            {loggedUserRole() === "Admin" && !hideBot ? (
              <div className=" min-h-6 pl-7 flex w-full items-center gap-2">
                <input
                  id="checkbox-1"
                  className="w-5 h-5 ease-soft text-base -ml-7 rounded-1.4   duration-250 relative float-left  cursor-pointer  border border-solid border-slate-150 bg-white bg-contain bg-center bg-no-repeat align-top transition-all  "
                  type="checkbox"
                  onChange={(e) => {
                    setFilter((prev) => ({
                      ...prev,
                      hide_googlebot: e.target.checked,
                    }));
                  }}
                />
                <label
                  htmlFor="checkbox-1"
                  className="cursor-pointer select-none text-slate-700"
                >
                  hide Google Bot
                </label>
              </div>
            ) : (
              <></>
            )}
            <Flatpickr
              options={{
                mode: "range",
                dateFormat: "m-d-Y",
                defaultDate: [
                  `${moment(new Date()).startOf("month").format("MM-DD-YYYY")}`,
                  `${moment(new Date()).format("MM-DD-YYYY")}`,
                ],  
              }}
              fromdateid="DashboardEndDatePicker"
              selectvalue={[]}
              className="form-control clickable  focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-fit appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mx-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
              onChange={setDate}
              placeholder="Select Date Range 🗓️"
            />
          </div>
        </div>
      </div>
      <DataTable
        ListQuery={ListQuery}
        filterProps={filterProps}
        columns={columns}
        sortingField={sortingField}
        searchFields={searchFields}
      />
    </div>
  );
};

export default VesselsAnalytics;
