import { useEffect, useState } from "react";
import { loggedUserRole } from "utils/functions";
import BrokerageAnalytics from "./BrokerageAnalytics/Index";
import BrokerAnalytics from "./BrokerAnalytics/Index";
import VesselsAnalytics from "./VesselsAnalytics/Index";
const TabItem = ({duration}) => {
  const [role, setRole] = useState(null);

  useEffect(() => {
    setRole(loggedUserRole());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserRole()]);
  return (
    <div className="max-h-8/10">
      <div className="flex flex-wrap mt-6 -mx-3 ">
        {role === "Admin" && <BrokerageAnalytics duration={duration}/>}
        {(role === "Admin" ) && <BrokerAnalytics duration={duration}/>}
        {(role === "Admin" || role === "Brokerage" || role === "Broker") && <VesselsAnalytics duration={duration}/>}
      </div>
    </div>
  );
};

export default TabItem;
