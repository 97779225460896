// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating brokerManagement by enhancing and injecting endpoints using serverApi
const adminAnalytics = serverApi
  .enhanceEndpoints({ tagTypes: [""] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of brokers
      adminVessels: builder.query({
        query(filter) {
          return {
            url: `admin-vessel-analytics?page=${filter.page || 1}&per_page=${filter?.perPage || 100
              }&sort_field=${filter?.sort || "vessel_id"}&sort_order=${filter?.order || "asc"
              }&start_date=${filter?.start_date || ""}&end_date=${filter?.end_date || ""
              }${filter?.vessel_id ? `&vessels_id=${filter?.vessel_id}` : ""}${filter?.brokerage ? `&brokerage=${filter?.brokerage}` : ""}${filter?.hide_googlebot ? `&hide_googlebot=${filter?.hide_googlebot}` : ""}`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: [""],
        keepUnusedDataFor: 1,
      }),
      adminVesselsDetails: builder.query({
        query(val) {
          return {
            url: `vessel-data/${val.vesselId}?start_date=${val?.filter?.start_date || ""}&end_date=${val?.filter?.end_date || ""
              }&page=${val?.filter.page || 1}&per_page=${val?.filter?.perPage || 50
              }`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: [""],
        keepUnusedDataFor: 1,
      }),
      adminVisitor: builder.query({
        query(filter) {
          return {
            url: `admin-visitor-analytics?page=${filter.page || 1}&per_page=${filter?.perPage || 50
              }&sort_field=${filter?.sort || "visitor_counts"}&sort_order=${filter?.order || "desc"
              }&start_date=${filter?.start_date || ""}&end_date=${filter?.end_date || ""
              }${filter?.vessel_id ? `&vessels_id=${filter?.vessel_id}` : ""}${filter?.brokerage ? `&brokerage=${filter?.brokerage}` : ""}${filter?.hide_googlebot ? `&hide_googlebot=${filter?.hide_googlebot}` : ""}`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: [""],
        keepUnusedDataFor: 1,
      }),
      adminVisitorDetails: builder.query({
        query(val) {
          return {
            url: `admin-visitor-analytics-details/${val.deviceId}`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: [""],
        keepUnusedDataFor: 1,
      }),
      adminMyVisits: builder.query({
        query(val) {
          return {
            url: `my-visit/${val.deviceId}?page=${val.filter.page || 1}&per_page=${val.filter?.perPage || 50
              }`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: [""],
        keepUnusedDataFor: 1,
      }),
      adminMyInquires: builder.query({
        query(val) {
          return {
            url: `my-inquiry/${val.deviceId}?page=${val.filter.page || 1}&per_page=${val.filter?.perPage || 50
              }`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: [""],
        keepUnusedDataFor: 1,
      }),
      adminGuest: builder.query({
        query(filter) {
          return {
            url: `admin-guest-analytics?page=${filter.page || 1}&per_page=${filter?.perPage || 50
              }&sort_field=${filter?.sort || ""}&sort_order=${filter?.order || "desc"
              }&start_date=${filter?.start_date || ""}&end_date=${filter?.end_date || ""
              }${filter?.vessel_id ? `&vessels_id=${filter?.vessel_id}` : ""}${filter?.brokerage ? `&brokerage=${filter?.brokerage}` : ""}${filter?.guest_id ? `&guest_id=${filter?.guest_id}` : ""}`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: [""],
        keepUnusedDataFor: 1,
      }),
      adminGuestDetails: builder.query({
        query(val) {
          return {
            url: `admin-guest-analytics-details/${val.userId}/${val.deviceId}`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: [""],
        keepUnusedDataFor: 1,
      }),
      adminVesselsVisitor: builder.query({
        query(val) {
          return {
            url: `vessel-visitor-analytics/${val.vesselId}?start_date=${val?.filter?.start_date || ""}&end_date=${val?.filter?.end_date || ""
              }&page=${val?.filter.page || 1}&per_page=${val?.filter?.perPage || 50
              }`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: [""],
        keepUnusedDataFor: 1,
      }),
      adminVesselsGuest: builder.query({
        query(val) {
          return {
            url: `vessel-guest-analytics/${val.vesselId}?start_date=${val?.filter?.start_date || ""}&end_date=${val?.filter?.end_date || ""
              }&page=${val?.filter.page || 1}&per_page=${val?.filter?.perPage || 50
              }`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: [""],
        keepUnusedDataFor: 1,
      }),
      adminVesselsInquiry: builder.query({
        query(val) {
          return {
            url: `vessel-inquiry/${val.vesselId}?start_date=${val?.filter?.start_date || ""}&end_date=${val?.filter?.end_date || ""
              }&page=${val?.filter.page || 1}&per_page=${val?.filter?.perPage || 50
              }`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: [""],
        keepUnusedDataFor: 1,
      }),
      adminVesselsGraph: builder.query({
        query(val) {
          return {
            url: `vessel-detail-graph/${val.vesselId}?start_date=${val?.filter?.start_date || ""}&end_date=${val?.filter?.end_date || ""
              }&page=${val?.filter.page || 1}&per_page=${val?.filter?.perPage || 50
              }`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: [""],
        keepUnusedDataFor: 1,
      }),

    }),
  });

export const {
  useAdminVesselsQuery,
  useAdminVesselsDetailsQuery,
  useAdminVisitorQuery,
  useAdminVisitorDetailsQuery,
  useAdminMyVisitsQuery,
  useAdminMyInquiresQuery,
  useAdminGuestQuery,
  useAdminGuestDetailsQuery,
  useAdminVesselsVisitorQuery,
  useAdminVesselsGuestQuery,
  useAdminVesselsInquiryQuery,
  useAdminVesselsGraphQuery
} = adminAnalytics;
