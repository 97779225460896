import { serverApi } from "store/serverApi";

const vesselsAnalyticsGraph = serverApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    blogsList: builder.query({
      query(filter) {
        return {
          url: `blogs?page=${filter?.page || 1}&per_page=${filter?.perPage || 50
            }&sort_field=${filter?.sort || ""}&sort_order=${filter?.order || ""
            }${filter.title ? "&title=" + filter.title : ""}`,
          method: "GET",
        };
      },

      keepUnusedDataFor: 1,
    }),
    blogCreate: builder.mutation({
      query(data) {
        return {
          url: `blogs`,
          method: "POST",
          body: data
        };
      },

      keepUnusedDataFor: 1,
    }),
    blogDetail: builder.query({
      query(id) {
        return {
          url: `blogs/${id}`,
          method: "GET",
        };
      },
     
      keepUnusedDataFor: 1,
    }),
    blogImageUpload: builder.mutation({
      query({id, upload}) {

        const formData = new FormData();
        formData.append("image", upload);
        return {
          url: `blog-upload/${id}`,
          method: "POST",
          body: formData
        };
      },
    
      keepUnusedDataFor: 1,
    }),
    deleteBlog: builder.mutation({
      query(id) {
        return {
          url: `blogs/${id}`,
          method: "DELETE",
        };
      },
      keepUnusedDataFor: 1,

    }),
    UpdateBlog: builder.mutation({
      query({ id, data }) {
        return {
          url: `blogs/${id}`,
          body: data,
          method: "PATCH",
        };
      },
      keepUnusedDataFor: 1,

    }),
  }),
});

export const {
  useBlogsListQuery,
  useBlogCreateMutation,
  useBlogDetailQuery,
  useBlogImageUploadMutation,
  useDeleteBlogMutation,
  useUpdateBlogMutation
} = vesselsAnalyticsGraph;
