// import ComponentLoader from "Components/Loader/ComponentLoader";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useTable } from "react-table";

// eslint-disable-next-line react/prop-types
const ListTable = ({
  isFetching,
  isLoading,
  columns,
  data = [],
  handleSort,
  sortingField,
  filter,
  setFilter,
  searchFields,
}) => {
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data,
    autoResetFilters: false,
    autoResetGlobalFilter: false,
    autoResetSortBy: false,
    manualPagination: true,
    manualSortBy: true,
    manualFilters: true,
    autoResetPage: false,
  });

  const [searchTerm, setSearchTerm] = useState({});
  useEffect(() => {
    if (searchTerm?.felid) {
      const delayDebounceFn = setTimeout(() => {
        setFilter((prev) => ({
          ...prev,
          searchObj: {
            ...prev?.searchObj,
            [searchTerm?.felid]: searchTerm?.value,
          },
        }));
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);
  return (
    <>
      <table
        {...getTableProps()}
        className="items-center w-full mb-0 align-top border-gray-200 text-slate-500 dark:border-white/40   "
      >
        <thead className="align-bottom sticky top-0 bg-white">
          {headerGroups?.map((headerGroup, i) => (
            <tr
              {...headerGroup?.getHeaderGroupProps()}
              key={i}
              className="border-b-[1px] border-slate-DEFAULT-300"
            >
              {headerGroup?.headers?.map((column, index) => (
                <th
                  {...column.getHeaderProps()}
                  className={` p-2  text-left font-bold uppercase align-top bg-transparent border-b border-gray-200 border-solid shadow-none text-xxs tracking-none whitespace-nowrap text-slate-400 opacity-70 dark:border-white/40 select-none dark:text-white dark:opacity-80 ${
                    sortingField?.includes(column?.id) && "cursor-pointer"
                  }`}
                  key={index}
                >
                  <div className="flex flex-col justify-start">
                    <div
                      className="text-xs text-black"
                      onClick={() => {
                        sortingField?.includes(column?.id) &&
                          handleSort(column?.id);
                      }}
                    >
                      {`${column?.render("header")}  ${
                        filter?.sort === column?.id && filter?.order === "asc"
                          ? `⬆`
                          : filter?.sort === column?.id &&
                              filter?.order === "desc"
                            ? `⬇`
                            : ""
                      }
                  `}
                    </div>
                    <div>
                      {searchFields?.includes(column?.id) && (
                        <input
                          className="dataTable-input lg:max-w-28 focus:shadow-soft-primary-outline dark:text-white/80 ease-soft focus:outline-none focus:transition-shadow border-px rounded-1 border-slate-150 "
                          placeholder="Search..."
                          type="text"
                          name="filter"
                          id="filter"
                          onChange={(e) => {
                            setSearchTerm(() => ({
                              felid: column.id,
                              value: e.target.value,
                            }));
                          }}
                        />
                      )}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {isLoading || isFetching ? (
          <></>
        ) : (
          <tbody {...getTableBodyProps()} className="  w-full">
            {data?.length ? (
              rows?.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row?.getRowProps()}
                    className="p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40"
                    key={i}
                  >
                    {row?.cells?.map((cell, index) => (
                      <td
                        {...cell?.getCellProps()}
                        className="p-2 font-normal leading-normal text-sm"
                        key={index}
                      >
                        {cell.render("Cell") ? cell.render("Cell") : "-"}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr className="text-2xl text-red-900 text-center font-semibold ">
                <td colSpan={headerGroups[0].headers.length} className="py-5">
                  Data Not Found
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
      {isLoading || isFetching ? (
        <div className="min-h-50 flex justify-center items-center">
          <div className="flex items-center justify-center">
            <ClipLoader size={70} color={`#4821d1`} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ListTable;
