// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating serachHistory by enhancing and injecting endpoints using serverApi
const serachHistory = serverApi
  .enhanceEndpoints({ tagTypes: ["history"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of search
      searchHistoryList: builder.query({
        query(filter) {
          var filte = { ...filter };
          if (filter.sort == "created_at_list") {
            filte.sort = "created_at";
          }
          if (filter.sort == "updated_at_list") {
            filte.sort = "updated_at";
          }
          return {
            url: `search-histories?page=${filter.page || 1}&per_page=${filter?.perPage || 50
              }&sort_field=${filte?.sort || "created_at_list"}&sort_order=${filter?.order || "desc"
              }${filter.user_type ? "&user_type=" + filter.user_type : ""
              }`,
            method: "GET",
          };
        },
        // Provides search tags for caching search list data
        providesTags: ["history"],
      }),
      // Mutation endpoint for deleting a search
      updateSearch: builder.mutation({
        query(val) {
          return {
            url: `search - histories / ${val?.id} `,
            method: "PUT",
            body: val?.payload,
          };
        },
        // Invalidates search tags after deleting a search
        invalidatesTags: ["history"],
      }),
      // Mutation endpoint for deleting a search
      deleteSearch: builder.mutation({
        query(id) {
          return {
            url: `search - histories / ${id} `,
            method: "DELETE",
          };
        },
        // Invalidates search tags after deleting a search
        // invalidatesTags: ["history"],
      }),
      userList: builder.mutation({
        query(value) {
          return {
            url: `search - user - to - assign ? search_by_email_or_name = ${value} `,
            method: "GET",
          };
        },
        // Invalidates search tags after deleting a search
        invalidatesTags: ["history"],
      }),
    }),
  });

// Destructuring followVesselManagement to get individual hooks for components
export const {
  useSearchHistoryListQuery,
  useDeleteSearchMutation,
  useUpdateSearchMutation,
  useUserListMutation,
} = serachHistory;
