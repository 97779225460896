// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating brokerManagement by enhancing and injecting endpoints using serverApi
const brokerManagement = serverApi
  .enhanceEndpoints({ tagTypes: ["broker"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of brokers
      brokerList: builder.query({
        query(filter) {
          return {
            url: `broker?page=${filter.page || 1}&per_page=${
              filter?.perPage || 50
            }&sort_field=${filter?.sort || "first_name"}&sort_order=${
              filter?.order || "asc"
            }&search_value=${filter.searchValue || ""}&search_field=${
              filter.searchField || " "
            }   `,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: ["broker"],
      }),

      // Mutation endpoint for creating a new broker
      brokerCreate: builder.mutation({
        query(body) {
          return {
            url: `broker`,
            method: "POST",
            body: body,
          };
        },
        // Invalidates broker tags after creating a new broker
        invalidatesTags: ["broker"],
      }),

      // Mutation endpoint for deleting a broker
      brokerDelete: builder.mutation({
        query(id) {
          return {
            url: `broker/${id}`,
            method: "DELETE",
          };
        },
        // Invalidates broker tags after deleting a broker
        // invalidatesTags: ["broker"],
      }),

      // Mutation endpoint for retrieving details of a specific broker
      brokerDetails: builder.mutation({
        query(id) {
          return {
            url: `broker/${id}`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker details data
        providesTags: ["broker"],
      }),

      // Mutation endpoint for updating a broker
      brokerUpdate: builder.mutation({
        query(data) {
          return {
            url: `broker/${data?.id}`,
            method: "PUT",
            body: data?.values,
          };
        },
        // Invalidates broker tags after updating a broker
        invalidatesTags: ["broker"],
      }),

      brokerProfile: builder.query({
        query(val) {
          return {
            url: `broker-details/${val?.id}?page=${
              val?.filter.page || 1
            }&per_page=${val?.filter?.perPage || 50}&sort_field=${
              val?.filter?.sort || ""
            }&sort_order=${val?.filter?.order}&search_value=${
              val?.filter.searchValue || " "
            }&search_field=${val?.filter.searchField || ""}`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker details data
        providesTags: ["broker"],
      }),
    }),
  });

// Destructuring brokerManagement to get individual hooks for components
export const {
  useBrokerListQuery,
  useBrokerCreateMutation,
  useBrokerDeleteMutation,
  useBrokerDetailsMutation,
  useBrokerUpdateMutation,
  useBrokerProfileQuery,
} = brokerManagement;
