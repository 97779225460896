import { useEffect, useState } from "react";
import { loggedUserRole } from "utils/functions";
import {
  useBoatInquiryAdminQuery,
  useBoatInquiryBrokerQuery,
  useBoatInquiryBrokerageQuery,
  useBoatInquiryGuestQuery,
} from "store/Actions/boatInquiry";
import BoatInquiryTable from "./BoatInquiryTable";

const BoatInquiry = () => {
  const [role, setRole] = useState(null);
  useEffect(() => {
    setRole(loggedUserRole());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserRole()]);
  const [filter, setFilter] = useState({
    sort: "created_at",
    order: "desc",
    perPage: 50,
    page: 1,
    searchField: " ",
    searchValue: " ",
  });
  const {
    data: adminData,
    isLoading: adminIsLoading,
    isFetching: adminIsFetching,
    isError: adminIsError,
  } = useBoatInquiryAdminQuery(filter, { skip: !(role === "Admin") });
  const {
    data: brokerageData,
    isLoading: brokerageIsLoading,
    isFetching: brokerageIsFetching,
    isError: brokerageIsError,
  } = useBoatInquiryBrokerageQuery(filter, { skip: !(role === "Brokerage") });
  const {
    data: brokerData,
    isLoading: brokerIsLoading,
    isFetching: brokerIsFetching,
    isError: brokerIsError,
  } = useBoatInquiryBrokerQuery(filter, { skip: !(role === "Broker") });
  const {
    data: guestData,
    isLoading: guestIsLoading,
    isFetching: guestIsFetching,
    isError: guestIsError,
  } = useBoatInquiryGuestQuery(filter, { skip: !(role === "Guest") });
  if (adminIsError || brokerageIsError || brokerIsError || guestIsError) {
    return;
  }

  return (
    <BoatInquiryTable
      ListQuery={{
        data:
          adminData?.data ||
          brokerageData?.data ||
          brokerData?.data ||
          guestData?.data,
        isLoading:
          adminIsLoading ||
          brokerageIsLoading ||
          brokerIsLoading ||
          guestIsLoading,
        isFetching:
          adminIsFetching ||
          brokerageIsFetching ||
          brokerIsFetching ||
          guestIsFetching,
      }}
      filterProps={{ filter, setFilter }}
    />
  );
};

export default BoatInquiry;
