import { serverApi } from "store/serverApi";

const vesselsAnalyticsGraph = serverApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    brokerageAnalyticsGraph: builder.query({
      query(filter) {
        return {
          url: `admin-brokerage-vessel-analytics?start_date=${filter?.start_date || ""
            }&end_date=${filter?.end_date || ""}${filter?.hide_googlebot ? "&hide_googlebot=" + filter?.hide_googlebot : ""}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
    }),
    brokerAnalyticsGraph: builder.query({
      query(val) {
        return {
          url: `admin-brokeragewise-vessel-analytics/${val?.name} ?start_date = ${val?.filter?.start_date || ""
            } & end_date= ${val?.filter?.end_date || ""}& hide_googlebot=${val?.filter.hide_googlebot || ""
            } `,
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
    }),
    vesselsAnalyticsGraph: builder.query({
      query(val) {
        return {
          url: `admin-brokerwise-vessel-analytics/${val?.name}?start_date = ${val?.filter?.start_date || ""
            }& end_date=${val?.filter?.end_date || ""}& hide_googlebot=${val?.filter.hide_googlebot || ""
            } `,
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
    }),
    allBrokerAnalyticsGraph: builder.query({
      query(filter) {
        return {
          url: `admin-broker-vessel-analytics?start_date=${filter?.start_date || ""
            }& end_date=${filter?.end_date || ""} `,
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
    }),
  }),
});

export const {
  useBrokerageAnalyticsGraphQuery,
  useBrokerAnalyticsGraphQuery,
  useVesselsAnalyticsGraphQuery,
  useAllBrokerAnalyticsGraphQuery,
} = vesselsAnalyticsGraph;
