import VesselDetails from "pages/DetailsPage/VesselDetails";
import { useEffect, useState } from "react";
import { loggedUserRole } from "utils/functions";

const VesselsDetails = () => {
    const [role, setRole] = useState(null);
    useEffect(() => {
      setRole(loggedUserRole());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedUserRole()]);
  
    if (role === "Brokerage") {
      return <VesselDetails />;
    } else if (role === "Broker") {
      return <VesselDetails />;
    }
}

export default VesselsDetails