import ComponentLoader from "Components/Loader/ComponentLoader";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";
import { useEffect, useState } from "react";
import "../../Assets/css/soft-ui-dashboard-tailwind.css";

import Highcharts from "highcharts/highstock";

import HighChart from "Components/Chart/HighChart";
import CustomEvents from "highcharts-custom-events";
import { useBrandsGraphQuery } from "store/Actions/BrandAnalytics";

CustomEvents(Highcharts);
const BrandsChart = ({ setBrandsId, filter }) => {
  const { data, isLoading, isFetching } = useBrandsGraphQuery(filter);

  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    if (data?.data?.data && !isLoading && !isFetching) {
      const brands = Object?.keys(data?.data?.data);
      const sData = Object?.keys(data?.data?.data)?.map((val, index) => ({
        name: brands[index],
        y: Number(data?.data?.data[brands[index]]),
      }));

      const options = {
        rangeSelector: {
          selected: 4,
        },
        chart: {
          renderTo: "chartContainer",
          type: "column",
          showAxes: true,
          marginRight: 0,
          height: 480,
          spacingRight: 0,
          zoomType: "x",
        },
        title: {
          text: "Brands Analytics",
        },
        xAxis: {
          min: 0,
          max: sData.length > 100 ? 100 : null,
          categories: [...brands],
          tickLength: 0,
          lineColor: "#ededed",
          labels: {
            rotation: -90,
            align: "right",
            style: {
              fontSize: "13px",
              fontFamily: "Verdana, sans-serif",
            },

            events: {
              click: function () {
                setBrandsId(() => this.value);
              },
            },
          },
        },
        scrollbar: { enabled: true },
        yAxis: {
          allowDecimals: false,
          showFull: true,
          title: {
            text: "",
          },
          stackLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.total, 0, "", "");
            },
          },
        },
        tooltip: {
          enabled: true,
          formatter: function () {
            let tooltipContent = `<div style="height:130px"> <b>${this?.x}</b><br/>`;

            tooltipContent += ` Vessels: ${this?.total}<br/></div> `;

            return tooltipContent;
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            cursor: "pointer",
            turboThreshold: 5000, //set it to a larger threshold, it is by default to 1000
          },
          column: {
            stacking: "normal",
            point: {
              events: {
                click: function () {
                  setBrandsId(() => this.category);
                },
              },
            },
          },
        },
        series: [
          {
            name: "Vessels",
            colorByPoint: true,
            data: [...sData],
          },
        ],
      };

      setChartOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {" "}
      {isLoading || isFetching ? (
        <ComponentLoader />
      ) : (
        <HighChart chartOptions={chartOptions} />
      )}
    </>
  );
};

export default BrandsChart;
