
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const SkeletonLoader = () => {
  return (
    <div className='max-h-full overflow-hidden'>

    <div className="p-5 rotate-90 overflow-hidden">
    <Skeleton count={4}   height={80} width={360}  />
  </div>
    </div>
  )
}

export default SkeletonLoader