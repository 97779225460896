import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDeleteSaveSearchMutation } from "store/Actions/guestSaved";
import Swal from "sweetalert2";

const Card = ({
  search,
  setOpenModal,
  setDefaultData,
  setFilter,
  ListQuery,
}) => {
  const { data, refetch } = ListQuery;
  const [dataArray, setDataArray] = useState([]);

  const pairs = search?.data?.split("&");

  useEffect(() => {
    const dataArrayCopy = [];
    pairs?.forEach((pair) => {
      const [key, value] = pair.split("=");
      dataArrayCopy.push({ [key]: value });
    });
    // setDataArray(dataArrayCopy)
    setDataArray(() =>
      [...dataArrayCopy]?.sort(
        (b, a) => a[Object.keys(a)[0]]?.length - b[Object.keys(b)[0]]?.length,
      ),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [deleteSave] = useDeleteSaveSearchMutation();

  const handelDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSave(id).then((res) => {
          if (res.error) {
            toast.error(res.error.data.message);
          } else {
            toast.success(res.data?.message);
            if (data?.data.length === 1) {
              setFilter((filter) => ({ ...filter, page: data.last_page - 1 }));
            } else {
              refetch();
            }
          }
        });
      }
    });
  };

  return (
    <div className="w-full max-w-full mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-4/12 p-5">
      <div className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="flex-auto p-5">
          <div className="flex justify-between ">
            <div>
              <h4 className="mb-0 text-xl font-semibold">
                {search.search_name}
              </h4>
              <p className="text-sm">
                {" "}
                Date-Time:{" "}
                {moment(search.created_at).format("MM-DD-YYYY HH:mm:ss")}{" "}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <XMarkIcon
                className="h-6 w-6 font-bold cursor-pointer text-red-650 "
                onClick={() => handelDelete(search.id)}
              />
              <PencilIcon
                className="h-6 w-6 font-bold cursor-pointer text-sky-700 "
                onClick={() => {
                  setOpenModal(true);
                  setDefaultData(search);
                }}
              />
            </div>
          </div>

          <p className="mb-6 leading-normal text-xl flex flex-wrap">
            {dataArray?.map((filter, key) => (
              <span
                key={key}
                className={` py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-bold bg-slate-200 text-slate-700 capitalize leading-none m-1 flex flex-wrap`}
              >
                <span className="font-normal">{Object.keys(filter)[0]}-</span>
                <span className="font-semibold">
                  {filter[Object.keys(filter)[0]]}
                </span>
              </span>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
