/* eslint-disable no-undef */
import userImage from "Assets/user.png";
import ComponentLoader from "Components/Loader/ComponentLoader";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useBrokerageProfileQuery } from "store/Actions/brokerageManagment";
import { useSwitchToBrokerageMutation } from "store/Actions/switchAction";

const BrokerageDetail = () => {
  const nav = useNavigate();
  const [brokerageDetails, setBrokerageDetails] = useState({});
  const { id } = useParams();
  const { data, isSuccess, isError, isLoading } = useBrokerageProfileQuery(id);
  useEffect(() => {
    if (isError) {
      nav("/brokerage");
    }
    if (isSuccess) {
      setBrokerageDetails(data?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data, isError]);

  const [switchToBrokerage] = useSwitchToBrokerageMutation();
  const switchUser = (id) => {
    switchToBrokerage(id).then((res) => {
      localStorage.setItem("token", res.data?.data?.token);
      if (res.error) {
        toast.error(res.error.data.message);
      } else {
        localStorage.setItem("userInfo", JSON.stringify(res.data?.data));
        toast.success(res?.data?.message);
        nav("/broker");
      }
    });
  };

  return isLoading ? (
    <ComponentLoader />
  ) : (
    <div>
      <div className="w-full px-6 mx-auto">
        <div
          className={`min-h-32 relative mt-6 flex items-center overflow-hidden rounded-2xl banner bg-cover bg-center p-0`}
        >
          <span className="absolute inset-y-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-yachtrDarkBlue to-yachtrBlue opacity-60"></span>
        </div>
        <div className="relative flex flex-col flex-auto min-w-0 p-4 mx-6 -mt-16 overflow-hidden break-words border-0 shadow-blur dark:shadow-soft-dark-xl dark:bg-gray-950 rounded-2xl bg-white/80 bg-clip-border backdrop-blur-2xl backdrop-saturate-200">
          <div className="flex flex-wrap -mx-3">
            <div className="flex-none w-auto max-w-full px-3">
              <div className="text-base ease-soft-in-out h-19 w-19 relative inline-flex items-center justify-center rounded-xl text-white transition-all duration-200">
                <img
                  src={
                    brokerageDetails?.profile
                      ? brokerageDetails?.profile.startsWith("https://")
                        ? brokerageDetails?.profile
                        : process.env.REACT_APP_UPLOADS +
                          brokerageDetails?.profile
                      : userImage
                  }
                  alt="profile_image"
                  className="w-full h-full shadow-soft-sm rounded-xl object-cover preview-img"
                />
              </div>
            </div>
            <div className="flex-none w-auto max-w-full px-3 my-auto">
              <div className="h-full">
                <h5 className="mb-1 dark:text-white">
                  {brokerageDetails?.brokerage_name || "no name"}
                </h5>
                {/* <p className="mb-0 font-semibold leading-normal text-sm dark:text-white dark:opacity-60">
                  {currentUser?.email}
                </p> */}
              </div>
            </div>
            <div className="w-full max-w-full px-3 mx-auto mt-4 sm:my-auto sm:mr-0 md:w-1/2 md:flex-none lg:w-3/12">
              <div className="relative right-0">
                <ul
                  className="relative flex flex-wrap p-1 gap-3 list-none bg-transparent rounded-xl"
                  role="list"
                >
                  <li className="z-30 flex-auto text-center">
                    <button
                      className="z-30 block w-full px-0 py-1 mb-0 transition-all rounded-lg ease-soft-in-out bg-white  text-slate-700 dark:text-white"
                      role="tab"
                      aria-selected="true"
                      onClick={() => nav(`/brokerage/edit/${id}`)}
                    >
                      <span className="ml-1">edit</span>
                    </button>
                  </li>

                  <li className="z-30 flex-auto text-center">
                    <button
                      className="z-30 block w-full px-0 py-1 mb-0 transition-colors  border-px border-slate-500  rounded-lg ease-soft-in-out bg-white  text-slate-700"
                      role="tab"
                      aria-selected="false"
                      onClick={() => switchUser(id)}
                    >
                      <span className="ml-1">switch</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full  mx-auto">
        <div className="flex flex-wrap -mx-3">
          <div className="w-full max-w-full px-3 ">
            {brokerageDetails?.brokers?.length ? (
              <div className="relative flex flex-col min-w-0 z-0 overflow-y-visible  break-words  border-0 rounded-2xl bg-clip-border">
                <h5 className="mb-0 px-12  dark:text-white font-bold text-3xl text-blue-800">
                  Brokers:
                </h5>
                <div className="flex flex-auto flex-wrap  px-6 mr-2">
                  {/* App tab */}
                  {brokerageDetails?.brokers?.map((broker) => (
                    <div
                      key={broker.id}
                      className="w-6/12 min-w-16 mb-5 text-center  sm:w-6/12 md:w-3/12 lg:w-2/12"
                    >
                      <div className="bg-white my-3 mx-8 shadow-soft-lg rounded-xl py-3">
                        <div
                          className={`ease-soft-in-out w-24 h-24 text-sm rounded-circle inline-flex items-center justify-center  border-solid  text-white transition-all border-4 duration-200  ${
                            broker?.status === "active"
                              ? "border-lime-500"
                              : "border-red-500"
                          }`}
                        >
                          <img
                            src={
                              broker?.profile
                                ? broker?.profile.startsWith("https://")
                                  ? broker?.profile
                                  : process.env.REACT_APP_UPLOADS +
                                    broker?.profile
                                : userImage
                            }
                            alt="Image placeholder"
                            className="w-full p-1 rounded-circle"
                          />
                        </div>
                        <hr className="h-px my-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />
                        <p className="mb-0 leading-normal  dark:text-white dark:opacity-60">
                          {broker?.first_name} {broker?.last_name}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap  p-6 ">
        <div className="max-w-full px-3 lg:flex-0 lg:w-8/12">
          <div className="relative flex flex-col min-w-0 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="flex items-center px-6 py-4 border-b border-solid rounded-t-2xl border-b-slate-100">
              <div className="flex items-center">
                <div className="mx-4">
                  <a className="leading-normal text-sm text-slate-700 dark:text-white">
                    {brokerageDetails?.email || "loading..."}
                  </a>
                </div>
              </div>
              <div className="ml-auto text-right">
                <span
                  className={`py-2.2 px-3.6 text-xs rounded-1.8 inline-block whitespace-nowrap text-center ${
                    brokerageDetails?.status === "active"
                      ? "bg-lime-200 text-lime-600 "
                      : "bg-red-200 text-red-600 "
                  } align-baseline font-bold capitalize leading-none`}
                >
                  {brokerageDetails?.status}
                </span>
              </div>
            </div>
            <div className="flex-auto p-6">
              <div className="mb-1">
                <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                  <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal border-0 rounded-t-lg text-sm text-inherit">
                    <strong className="text-slate-700 dark:text-white">
                      Brokerage Name:
                    </strong>{" "}
                    {brokerageDetails?.brokerage_name}
                  </li>
                  <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal border-0 rounded-t-lg text-sm text-inherit">
                    <strong className="text-slate-700 dark:text-white">
                      Brokerage Id:
                    </strong>{" "}
                    {brokerageDetails?.related_entity_id}
                  </li>
                  <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal border-0 rounded-t-lg text-sm text-inherit">
                    <strong className="text-slate-700 dark:text-white">
                      Contact Person:
                    </strong>{" "}
                    {brokerageDetails?.contact_person}
                  </li>

                  <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal border-0 rounded-t-lg text-sm text-inherit">
                    <strong className="text-slate-700 dark:text-white">
                      Number of Broker:
                    </strong>{" "}
                    {brokerageDetails?.brokers?.length || "0"}
                  </li>
                  {/* <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal border-0 rounded-t-lg text-sm text-inherit">
                    <strong className="text-slate-700 dark:text-white">
                       Name:
                    </strong>{" "}
                    {brokerageDetails?.brokerage_name}
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full max-w-full px-3 flex-0 lg:w-4/12"></div>
      </div>
    </div>
  );
};

export default BrokerageDetail;
