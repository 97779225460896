// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating brokerageManagement by enhancing and injecting endpoints using serverApi
const brokerageManagement = serverApi
  .enhanceEndpoints({ tagTypes: ["brokerage"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of brokerages
      brokerageList: builder.query({
        query(filter) {
          // Encode the object's data into URL parameters
          const queryString = new URLSearchParams(filter?.searchObj).toString();
          return {
            url: `brokerage?page=${filter.page || 1}&per_page=${filter?.perPage || 50
              }&sort_field=${filter?.sort || "brokerage_name"}&sort_order=${filter?.order || "asc"
              }&${queryString && queryString}`,
            method: "GET",
          };
        },
        // Provides brokerage tags for caching brokerage list data
        providesTags: ["brokerage"],
      }),

      // Mutation endpoint for creating a new brokerage
      brokerageCreate: builder.mutation({
        query(body) {
          return {
            url: `brokerage`,
            method: "POST",
            body: body,
          };
        },
        // Invalidates brokerage tags after creating a new brokerage
        invalidatesTags: ["brokerage"],
      }),

      // Mutation endpoint for deleting a brokerage
      brokerageDelete: builder.mutation({
        query(id) {
          return {
            url: `brokerage/${id}`,
            method: "DELETE",
          };
        },
        // Invalidates brokerage tags after deleting a brokerage
        // invalidatesTags: ["brokerage"],
      }),

      // Mutation endpoint for retrieving details of a specific brokerage
      brokerageDetails: builder.mutation({
        query(id) {
          return {
            url: `brokerage/${id}`,
            method: "GET",
          };
        },
        // Provides brokerage tags for caching brokerage details data
        providesTags: ["brokerage"],
      }),

      // Mutation endpoint for updating a brokerage
      brokerageUpdate: builder.mutation({
        query(data) {
          return {
            url: `brokerage/${data?.id}`,
            method: "PUT",
            body: data?.values,
          };
        },
        // Invalidates brokerage tags after updating a brokerage
        invalidatesTags: ["brokerage"],
      }),

      // Mutation endpoint for retrieving details of a specific brokerage for view page
      brokerageProfile: builder.query({
        query(id) {
          return {
            url: `brokerage-details/${id}`,
            method: "GET",
          };
        },
        // Provides brokerage tags for caching brokerage details data
        providesTags: ["brokerage"],
      }),
    }),
  });

// Destructuring brokerageManagement to get individual hooks for components
export const {
  useBrokerageListQuery,
  useBrokerageCreateMutation,
  useBrokerageDetailsMutation,
  useBrokerageUpdateMutation,
  useBrokerageDeleteMutation,
  useBrokerageProfileQuery,
} = brokerageManagement;
