import ResetButton from "Components/Butttons/ResetButton";
import SubmitButton from "Components/Butttons/SubmitButton";
import CustomSelect from "Components/Input/CustomSelect";
import CustomInput from "Components/Input/Input";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useCreateSearchMutation,
  useModifySearchMutation,
  useSearchFormDataQuery,
} from "store/Actions/guestSaved";

function updateObjectValues(obj) {
  let sortedObject = Object.keys(obj)
    .sort()
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
  return Object.fromEntries(
    Object.keys(sortedObject)
      .map((key) => {
        const value = obj[key];

        if (typeof value === "object" && value !== null) {
          return [key, value.value];
        } else {
          return [key, value];
        }
      })
      .filter(
        // eslint-disable-next-line no-unused-vars
        ([_, value]) => value !== null && value !== undefined && value !== "",
      ),
  );
}

const EditSearchFilter = ({ defaultData, setOpenModal }) => {
  const { data } = useSearchFormDataQuery();

  const [initialVal, setInitialVal] = useState({});
  const [searchTitle, setSearchTitle] = useState("");
  const pairs = defaultData?.data?.split("&");

  useEffect(() => {
    const dataObjectCopy = pairs?.reduce((acc, pair) => {
      const [key, value] = pair.split("=");
      return { ...acc, [key]: value };
    }, {});
    setInitialVal(dataObjectCopy);
    setSearchTitle(defaultData?.search_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modifySearch, { isLoading: updateLoading }] =
    useModifySearchMutation();
  const [createSearch, { isLoading: createLoading }] =
    useCreateSearchMutation();
  const [dropdownData, setDropdownData] = useState({});

  useEffect(() => {
    if (data) {
      setDropdownData(() => ({
        condition: [
          { value: "new", label: "NEW" },
          { value: "used", label: "USED" },
        ],
      }));
      [
        "state",
        "city",
        "country",
        "hullmaterial",
        "manufacturer",
        "regions",
      ].map((felid) => {
        data[`${felid}Data`].map((val) => {
          setDropdownData((prev) => ({
            ...prev,
            [felid]: [
              ...(prev[felid] || []),
              { value: `${val}`, label: `${val}` },
            ],
          }));
        });
      });
      Object.keys(data.categoriesData).map((type) => {
        setDropdownData((prev) => ({
          ...prev,
          type: [...(prev.type || []), { value: `${type}`, label: `${type}` }],
        }));
        Object.keys(data.categoriesData[type]).map((category) => {
          setDropdownData((prev) => ({
            ...prev,
            category: {
              ...(prev.category || {}),
              [type]: [
                ...(prev.category?.[type] || []),
                { value: `${category}`, label: `${category}` },
              ],
            },
          }));
        });
      });
      Object.keys(data.sortBy).map((val) => {
        setDropdownData((prev) => ({
          ...prev,
          sortby: [
            ...(prev.sortby || []),
            { value: `${val}`, label: data.sortBy[val] },
          ],
        }));
      });
      data.eventData.map((event) => {
        setDropdownData((prev) => ({
          ...prev,
          events: [
            ...(prev.events || []),
            { value: event.EventID, label: event.EventName },
          ],
        }));
      });
    }
  }, [data]);

  return (
    <div className=" flex flex-col visible w-full h-auto min-w-0 px-4 break-words  ">
      <Formik
        enableReinitialize
        initialValues={{
          condition: dropdownData?.condition?.filter(
            (data) => data.value == initialVal?.condition,
          )[0],
          type: dropdownData?.type?.filter(
            (data) => data.value == initialVal?.type,
          )[0],
          category: dropdownData?.category?.[initialVal?.type || ""]?.filter(
            (data) => data.value == initialVal?.category,
          )[0],
          manufacturer: dropdownData?.manufacturer?.filter(
            (data) => data.value == initialVal?.manufacturer,
          )[0],
          hullmaterial: dropdownData?.hullmaterial?.filter(
            (data) => data.value == initialVal?.hullmaterial,
          )[0],
          vnames: initialVal?.vnames || "",
          vmodel: initialVal?.vmodel || "",
          vid: initialVal?.vid || "",
          pmin: initialVal?.pmin || "",
          pmax: initialVal?.pmax || "",
          lmin: initialVal?.lmin || "",
          lmax: initialVal?.lmax || "",
          ymin: initialVal?.ymin || "",
          ymax: initialVal?.ymax || "",
          city: dropdownData?.city?.filter(
            (data) => data.value == initialVal?.city,
          )[0],
          state: dropdownData?.state?.filter(
            (data) => data?.value == initialVal?.state,
          )[0],
          country: dropdownData?.country?.filter(
            (data) => data.value == initialVal?.country,
          )[0],
          regions: dropdownData?.regions?.filter(
            (data) => data.value == initialVal?.regions,
          )[0],
          events: dropdownData?.events?.filter(
            (data) => data.value == initialVal?.events,
          )[0],
          sortby: dropdownData?.sortby?.filter(
            (data) => data.value == initialVal?.sortby,
          )[0],
        }}
        onSubmit={async (values) => {
          const requestedVal = Object.keys(updateObjectValues(values))
            .map((key) => key + "=" + updateObjectValues(values)[key])
            .join("&");
          if (defaultData) {
            modifySearch({
              values: { search_name: searchTitle, data: requestedVal },
              id: defaultData.id,
            }).then((res) => {
              if (res.error) {
                toast.error(res.error.data.message);
              } else {
                toast.success(res.data?.message);
                setOpenModal(false);
              }
            });
          } else {
            createSearch({
              values: { search_name: searchTitle, data: requestedVal },
              id: defaultData.id,
            }).then((res) => {
              if (res.error) {
                toast.error(res.error.data.message);
              } else {
                toast.success(res.data?.message);
                setOpenModal(false);
              }
            });
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <div>
                  {/* row 1 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full  px-3 flex-0 ">
                      <input
                        defaultValue={searchTitle}
                        className="focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                        type="text"
                        placeholder="Name Your Search - Enter A Title"
                        onChange={(e) => {
                          setSearchTitle(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/* row 2 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomSelect
                        values={values}
                        options={dropdownData?.condition}
                        placeholder="NEW or USED"
                        changeEvent={(e) => {
                          setFieldValue("condition", e);
                        }}
                        name="condition"
                      />
                    </div>
                    <div className="w-full max-w-full px-3 mt-3 flex-0 sm:mt-0 sm:w-6/12 ">
                      <CustomSelect
                        values={values}
                        options={dropdownData?.type}
                        placeholder="Type"
                        changeEvent={(e) => {
                          if (e !== values.type) {
                            setFieldValue("type", e);
                            setFieldValue("category", null);
                          }
                        }}
                        name="type"
                      />
                    </div>
                  </div>
                  {/* row 3 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full  px-3 flex-0 ">
                      <CustomSelect
                        values={values}
                        options={
                          values?.type?.value
                            ? dropdownData?.category[values?.type?.value]
                            : []
                        }
                        placeholder="Category (Select Type First)"
                        changeEvent={(e) => {
                          setFieldValue("category", e);
                        }}
                        name="category"
                      />
                    </div>
                  </div>
                  {/* row 4 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full  px-3 flex-0 ">
                      <CustomSelect
                        values={values}
                        options={dropdownData?.manufacturer}
                        placeholder="Manufacturer"
                        changeEvent={(e) => {
                          setFieldValue("manufacturer", e);
                        }}
                        name="manufacturer"
                      />
                    </div>
                  </div>
                  {/* row 5 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomInput
                        name="vmodel"
                        values={values}
                        type="text"
                        placeholder="Model"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        customStyle="text-center"
                      />
                    </div>
                    <div className="w-full max-w-full px-3 mt-3 flex-0 sm:mt-0 sm:w-6/12 ">
                      <CustomSelect
                        values={values}
                        options={dropdownData?.hullmaterial}
                        placeholder="Hull Material"
                        changeEvent={(e) => {
                          setFieldValue("hullmaterial", e);
                        }}
                        name="hullmaterial"
                      />
                    </div>
                  </div>

                  {/* row 6 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomInput
                        name="vnames"
                        values={values}
                        type="text"
                        placeholder="Yacht Name"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        customStyle="text-center"
                      />
                    </div>
                    <div className="w-full max-w-full px-3 mt-3 flex-0 sm:mt-0 sm:w-6/12 ">
                      <CustomInput
                        name="vid"
                        values={values}
                        type="text"
                        placeholder="Yacht ID"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        customStyle="text-center"
                      />
                    </div>
                  </div>
                  {/* row 7 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomInput
                        name="pmin"
                        values={values}
                        type="text"
                        placeholder="Price From"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        customStyle="text-center"
                      />
                    </div>
                    <div className="w-full max-w-full px-3 mt-3 flex-0 sm:mt-0 sm:w-6/12 ">
                      <CustomInput
                        name="pmax"
                        values={values}
                        type="text"
                        placeholder="Price To"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        customStyle="text-center"
                      />
                    </div>
                  </div>
                  {/* row 8 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomInput
                        name="lmin"
                        values={values}
                        type="text"
                        placeholder="Length From"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        customStyle="text-center"
                      />
                    </div>
                    <div className="w-full max-w-full px-3 mt-3 flex-0 sm:mt-0 sm:w-6/12 ">
                      <CustomInput
                        name="lmax"
                        values={values}
                        type="text"
                        placeholder="Length To"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        customStyle="text-center"
                      />
                    </div>
                  </div>
                  {/* row 9 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomInput
                        name="ymin"
                        values={values}
                        type="text"
                        placeholder="Year From"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        customStyle="text-center"
                      />
                    </div>
                    <div className="w-full max-w-full px-3 mt-3 flex-0 sm:mt-0 sm:w-6/12 ">
                      <CustomInput
                        name="ymax"
                        values={values}
                        type="text"
                        placeholder="Year To"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        customStyle="text-center"
                      />
                    </div>
                  </div>
                  {/* row 10 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomSelect
                        values={values}
                        options={dropdownData?.city}
                        placeholder="City"
                        changeEvent={(e) => {
                          setFieldValue("city", e);
                        }}
                        name="city"
                      />
                    </div>
                    <div className="w-full max-w-full px-3 mt-3 flex-0 sm:mt-0 sm:w-6/12 ">
                      <CustomSelect
                        values={values}
                        options={dropdownData?.state}
                        placeholder="State"
                        changeEvent={(e) => {
                          setFieldValue("state", e);
                        }}
                        name="state"
                      />
                    </div>
                  </div>
                  {/* row 11 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomSelect
                        values={values}
                        options={dropdownData?.country}
                        placeholder="Country"
                        changeEvent={(e) => {
                          setFieldValue("country", e);
                        }}
                        name="country"
                      />
                    </div>
                    <div className="w-full max-w-full px-3 mt-3 flex-0 sm:mt-0 sm:w-6/12 ">
                      <CustomSelect
                        values={values}
                        options={dropdownData?.regions}
                        placeholder="Regions"
                        changeEvent={(e) => {
                          setFieldValue("regions", e);
                        }}
                        name="regions"
                      />
                    </div>
                  </div>
                  {/* row 12 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full  px-3 flex-0 ">
                      <CustomSelect
                        values={values}
                        options={dropdownData?.events}
                        placeholder="----Events----"
                        changeEvent={(e) => {
                          setFieldValue("events", e);
                        }}
                        name="events"
                      />
                    </div>
                  </div>
                  {/* row 13 */}
                  <div className="flex flex-wrap mt-3 -mx-3">
                    <div className="w-full max-w-full  px-3 flex-0 ">
                      <CustomSelect
                        values={values}
                        options={dropdownData?.sortby}
                        placeholder="----Sort By----"
                        changeEvent={(e) => {
                          setFieldValue("sortby", e);
                        }}
                        name="sortby"
                      />
                    </div>
                  </div>

                  <div className="flex mt-4 w-full">
                    <SubmitButton
                      isLoading={createLoading || updateLoading}
                      text={defaultData ? "Save Search" : "Create Search"}
                      width="w-full"
                    />
                  </div>
                  <div className="flex mt-4">
                    <ResetButton
                      event={() => {
                        handleReset();
                      }}
                      text="Reset"
                      width="w-full"
                    />
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditSearchFilter;
