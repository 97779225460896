import { Formik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import Thumbnail from "Assets/thumbnail.jpeg";
import { useBlogImageUploadMutation } from "store/Actions/blogs";

function UploadBlogImage({ id, imageUrl, onCloseModel = false }) {
  const schema = Yup.object({});
  const [upload, setUpload] = useState("");
  const [image, setImage] = useState(imageUrl);
  const [uploadProfile, { isLoading }] = useBlogImageUploadMutation();

  async function uploadImage() {
    await uploadProfile({ id, upload }).then((res) => {
      if (res.error) {
        toast.error(res?.error?.data?.message);
      } else {
        toast.success(res?.data?.message);
        if (onCloseModel) {
          onCloseModel();
        }
      }
    });
  }
  return (
    <div className="flex flex-col items-center w-full h-auto p-4 m-4 bg-white border rounded-2xl dark:bg-gray-950">
      <Formik
        enableReinitialize
        initialValues={{ profile: imageUrl || "" }}
        onSubmit={uploadImage}
        validationSchema={schema}
      >
        {({ handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="w-full">
            <div className="flex justify-center w-full mb-5">
              <img
                src={image || Thumbnail}
                alt="blog image"
                className="w-50 h-50 rounded-md"
              />
            </div>
            <div className="flex items-center justify-center mb-5">
              <input
                name="profile"
                type="file"
                accept="image/png, image/gif, image/jpeg, image/jpg"
                onChange={(e) => {
                  setUpload(e.target.files[0]);
                  setImage(URL.createObjectURL(e.target.files[0]));
                }}
                onBlur={handleBlur}
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg p-1 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
            <button
              type="button"
              onClick={uploadImage}
              disabled={isLoading}
              className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
            >
              {isLoading ? "Uploading..." : "Upload"}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default UploadBlogImage;
