import { BeatLoader } from "react-spinners";

const ButtonLoader = () => {
  return (
    <BeatLoader
      color="#fff"
      cssOverride={{ width: "100%" }}
      size={6}
      speedMultiplier={2}
    />
  );
};

export default ButtonLoader;
