// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating followVesselManagement by enhancing and injecting endpoints using serverApi
const followVesselManagement = serverApi
  .enhanceEndpoints({ tagTypes: ["follow"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of brokers
      followList: builder.query({
        query(filter) {
          return {
            url: `following?page=${filter.page || 1}&per_page=${
              filter?.perPage || 50
            }  `,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: ["follow"],
      }),
      // Mutation endpoint for deleting a broker
      followUnfollow: builder.mutation({
        query(id) {
          return {
            url: `unfollow/${id}`,
            method: "PATCH",
          };
        },
        // Invalidates broker tags after deleting a broker
        invalidatesTags: ["follow"],
      }),
    }),
  });

// Destructuring followVesselManagement to get individual hooks for components
export const { useFollowListQuery, useFollowUnfollowMutation } =
  followVesselManagement;
