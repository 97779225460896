// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating searchTextManagement by enhancing and injecting endpoints using serverApi
const searchTextManagement = serverApi
  .enhanceEndpoints({ tagTypes: ["searchText"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of brokers
      searchTextList: builder.query({
        query(filter) {
          return {
            url: `search-texts?page=${filter.page || 1}&per_page=${
              filter?.perPage || 50
            }&sort_field=${filter?.sort || "count"}&sort_order=${
              filter?.order || "desc"
            }`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: ["searchText"],
      }),
      // Mutation endpoint for deleting a broker
      deleteSearchText: builder.mutation({
        query(id) {
          return {
            url: `search-texts/${id}`,
            method: "DELETE",
          };
        },
        // Invalidates broker tags after deleting a broker
        // invalidatesTags: ["searchText"],
      }),
    }),
  });

// Destructuring followVesselManagement to get individual hooks for components
export const { useSearchTextListQuery, useDeleteSearchTextMutation } =
  searchTextManagement;
