import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";
import moment from "moment";
import { useState } from "react";
import Flatpickr from "react-flatpickr";
import CategoryChart from "./CategoryChart";
import CategoryVesselsChart from "./CategoryVesselsChart";
import { currentDate, currentMonth, currentYear } from "utils/functions";

const CategoriesAnalytics = () => {
  const [categoryId, setCategoryId] = useState(null);

  const [filter, setFilter] = useState({
    // daterange:`${currentMonth()}/01/${currentYear()} -${currentMonth()}/${currentDate()}/${currentYear()}`,
    // hide_googlebot: false,
    start_date: `${moment(new Date()).startOf("month").format("MM-DD-YYYY")}`,
    end_date: `${moment(new Date()).format("MM-DD-YYYY")}`,
  });
  const setDate = (newValue) => {
    // const startDate = moment(newValue[0]).format("MM/DD/YYYY");
    // const endDate = moment(newValue[1]).format("MM/DD/YYYY");
    setFilter(() => {
      return {
        ...filter,
        // daterange: `${startDate} - ${endDate}`,
        start_date: moment(newValue[0]).format("MM-DD-YYYY"),
        end_date: moment(newValue[1]).format("MM-DD-YYYY"),
      };
    });
  };
  return (
    <div className="flex flex-wrap my-6 -mx-3 ">
      <div className="w-full max-w-full px-3 flex-0 ">
        <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div>
                <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                  Categories Analytics
                </h5>
              </div>
              <p className="mb-0 leading-normal text-sm"></p>
            </div>
            <div className="my-auto flex ml-auto lg:mt-0 ">
              {categoryId ? (
                <div>
                  <span
                    className="text-blue-800 hover:underline cursor-pointer"
                    onClick={() => setCategoryId(null)}
                  >
                    Category
                  </span>{" "}
                  /{" "}
                  <span dangerouslySetInnerHTML={{ __html: categoryId }}></span>
                </div>
              ) : (
                <></>
              )}
              <div className="my-auto ml-auto">
                <div className=" flex">
                  <div className=" min-h-6 pl-7 flex w-full items-center gap-2">
                    <input
                      id="checkbox-1"
                      className="w-5 h-5 ease-soft text-base -ml-7 rounded-1.4   duration-250 relative float-left  cursor-pointer  border border-solid border-slate-150 bg-white bg-contain bg-center bg-no-repeat align-top transition-all  "
                      type="checkbox"
                      onChange={(e) => {
                        setFilter((prev) => ({
                          ...prev,
                          hide_googlebot: e.target.checked,
                        }));
                      }}
                    />
                    <label
                      htmlFor="checkbox-1"
                      className="cursor-pointer select-none text-slate-700"
                    >
                      hide Google Bot
                    </label>
                  </div>
                  <Flatpickr
                    options={{
                      mode: "range",
                      dateFormat: "m-d-Y",
                      defaultDate: [
                        `${currentMonth()}-01-${currentYear()}`,
                        `${currentMonth()}-${currentDate()}-${currentYear()}`,
                      ],
                    }}
                    fromdateid="DashboardEndDatePicker"
                    selectvalue={[]}
                    className="form-control clickable  focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-fit appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2  mx-2  font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                    onChange={setDate}
                    placeholder="Select Date Range 🗓️"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-y-auto krunal.b@webcodegenie.g">
            {categoryId ? (
              <CategoryVesselsChart categoryId={categoryId} filter={filter} />
            ) : (
              <CategoryChart setCategoryId={setCategoryId} filter={filter} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesAnalytics;
