// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating savedSearch by enhancing and injecting endpoints using serverApi
const savedSearch = serverApi
  .enhanceEndpoints({ tagTypes: ["follow"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of brokers
      saveSearchList: builder.query({
        query(filter) {
          return {
            url: `saved-searches?page=${filter.page || 1}&per_page=${
              filter?.perPage || 50
            } `,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: ["follow"],
      }),
      // Mutation endpoint for deleting a broker
      deleteSaveSearch: builder.mutation({
        query(id) {
          return {
            url: `saved-searches/${id} `,
            method: "DELETE",
          };
        },
        // Invalidates broker tags after deleting a broker
        invalidatesTags: ["follow"],
      }),

      searchFormData: builder.query({
        query() {
          return {
            url: `open_form `,
            method: "GET",
          };
        },
        // Invalidates broker tags after deleting a broker
        // invalidatesTags: ["follow"],
      }),
      modifySearch: builder.mutation({
        query(val) {
          return {
            url: `saved-searches/${val.id} `,
            body: val.values,
            method: "PATCH",
          };
        },
        // Invalidates broker tags after deleting a broker
        invalidatesTags: ["follow"],
      }),
      createSearch: builder.mutation({
        query(val) {
          return {
            url: `saved-searches`,
            body: val.values,
            method: "POST",
          };
        },
        // Invalidates broker tags after deleting a broker
        invalidatesTags: ["follow"],
      }),
    }),
  });

// Destructuring followVesselManagement to get individual hooks for components
export const {
  useSaveSearchListQuery,
  useDeleteSaveSearchMutation,
  useSearchFormDataQuery,
  useModifySearchMutation,
  useCreateSearchMutation,
} = savedSearch;
