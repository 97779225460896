/* eslint-disable no-undef */
import { Tab } from "@headlessui/react";
import ChangePasswordFrom from "Components/Forms/ChangePasswordForm";
import ProfileLoginDetails from "Components/Forms/ProfileLoginDetails";
import ProfileUserDetails from "Components/Forms/ProfileUserDetails";
import UploadProfileForm from "Components/Forms/UploadProfileForm";
import { useEffect, useState } from "react";

import userImage from "Assets/user.png";
import toast from "react-hot-toast";
import {
  useDeleteGuestAccountMutation,
  useUserProfileQuery,
} from "store/Actions/authAction";
import Swal from "sweetalert2";
import { loggedUserRole } from "utils/functions";
import VesselList from "./VesselList";
import ComponentLoader from "Components/Loader/ComponentLoader";
import Skeleton from "react-loading-skeleton";
// import { useUserProfileQuery } from "store/Actions/authAction";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = ["User Details", "Login Details", "Change Password"];
const Profile = () => {
  const { data, isLoading, isFetching } = useUserProfileQuery();

  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    setCurrentUser(data?.data.user);
  }, [data]);
  const [deleteGuestAccount] = useDeleteGuestAccountMutation();

  const handelDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteGuestAccount(localStorage.getItem("token")).then((res) => {
          if (res.error) {
            toast.error(res.error.data.message);
          } else {
            toast.success(res.data?.message);
            localStorage.clear();
            nav("/login");
          }
        });
      }
    });
  };

  return (
    <div>
      <div className="w-full px-6 mx-auto">
        <div
          className={`min-h-32 relative mt-6 flex items-center overflow-hidden rounded-2xl banner banner-profile  bg-cover bg-center p-0`}
        >
          <span className="absolute inset-y-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-yachtrDarkBlue to-yachtrBlue opacity-60"></span>
        </div>
        <div className="relative flex flex-col flex-auto min-w-0 p-4 mx-6 -mt-16 overflow-hidden break-words border-0 shadow-blur dark:shadow-soft-dark-xl dark:bg-gray-950 rounded-2xl bg-white/80 bg-clip-border backdrop-blur-2xl backdrop-saturate-200">
          <div className="flex flex-wrap -mx-3">
            <div className="flex-none w-auto max-w-full px-3">
              <div className="text-base ease-soft-in-out h-19 w-19 relative inline-flex items-center justify-center rounded-xl text-white transition-all duration-200">
                <img
                  src={
                    currentUser?.profile
                      ? currentUser?.profile.startsWith("https://")
                        ? currentUser?.profile
                        : process.env.REACT_APP_UPLOADS + currentUser?.profile
                      : userImage
                  }
                  alt="profile_image"
                  className="w-full h-full shadow-soft-sm rounded-xl object-cover preview-img"
                />
              </div>
            </div>
            <div className="flex-none w-auto max-w-full px-3 my-auto">
              {isLoading || isFetching ? (
                <Skeleton height={50} width={300} />
              ) : (
                <div className="h-full">
                  <h5 className="mb-1 dark:text-white font-semibold">
                    {" "}
                    {loggedUserRole() == "Brokerage"
                      ? currentUser?.brokerage_name || ""
                      : (currentUser?.first_name || currentUser?.last_name) &&
                        currentUser?.first_name + " " + currentUser?.last_name}
                  </h5>
                  <p className="mb-0 font-semibold lowercase leading-normal text-sm dark:text-white dark:opacity-60">
                    {currentUser?.email}
                  </p>
                </div>
              )}
            </div>
            <div className="w-full max-w-full flex justify-end px-3 mx-auto mt-4 sm:my-auto sm:mr-0 md:w-1/2 md:flex-none lg:w-4/12">
              {loggedUserRole() == "Guest" && (
                <div className="">
                  {" "}
                  <button
                    type="button"
                    className="mr-3 inline-block px-6 py-2 font-bold text-center bg-gradient-to-tl from-red-600 to-rose-400 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white"
                    onClick={() => handelDelete(currentUser?.id)}
                  >
                    Delete Account
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full  px-10 pt-5 sm:px-0">
        <Tab.Group>
          <Tab.List className="flex flex-col sm:flex-row  space-x-1 mx-4 rounded-xl  p-1">
            {tabs.map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    "w-auto rounded-lg p-4 mx-2 text-sm  font-medium leading-5",
                    "inline-block px-8 py-2 m-0 text-sm font-bold text-center   align-middle transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft  bg-150 bg-x-25 hover:scale-102 active:opacity-85 ",
                    selected
                      ? " text-blue-50  bg-gradient-to-tl from-yachtrDarkBlue to-yachtrBlue shadow-soft-md"
                      : " hover:bg-white/[0.12] hover:text-gray-800"
                  )
                }
              >
                {category}
              </Tab>
            ))}
            {loggedUserRole() === "Broker" && (
              <Tab
                className={({ selected }) =>
                  classNames(
                    "w-auto rounded-lg p-4 mx-2 text-sm  font-medium leading-5",
                    "inline-block px-8 py-2 m-0 text-sm font-bold text-center   align-middle transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft  bg-150 bg-x-25 hover:scale-102 active:opacity-85 ",
                    selected
                      ? " text-blue-50  bg-gradient-to-tl from-yachtrDarkBlue to-yachtrBlue shadow-soft-md"
                      : " hover:bg-white/[0.12] hover:text-gray-800"
                  )
                }
              >
                Vessels
              </Tab>
            )}
          </Tab.List>
          <Tab.Panel>
            {isLoading || isFetching ? (
              <ComponentLoader />
            ) : (
              <div className="flex flex-col  lg:flex-row">
                <ProfileUserDetails /> <UploadProfileForm />
              </div>
            )}
          </Tab.Panel>
          <Tab.Panel>
            {isLoading || isFetching ? (
              <ComponentLoader />
            ) : (
              <div className="flex flex-col lg:flex-row">
                <ProfileLoginDetails /> <UploadProfileForm />
              </div>
            )}
          </Tab.Panel>
          <Tab.Panel>
            <div className="flex flex-col lg:flex-row">
              <ChangePasswordFrom /> <UploadProfileForm />
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="">
              <VesselList />
            </div>
          </Tab.Panel>
        </Tab.Group>
      </div>
    </div>
  );
};

export default Profile;
