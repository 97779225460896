/* eslint-disable no-undef */
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  Cog8ToothIcon,
} from "@heroicons/react/24/outline";
import userImage from "Assets/user.png";
import { AddressBar } from "Components/BreadCrumb/BreadCrumb";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  useLogOutMutation,
  useUserProfileQuery,
} from "store/Actions/authAction";
import { loggedUserCode, loggedUserRole } from "utils/functions";

export default function Header({ setSidebarOpen }) {
  const [logout] = useLogOutMutation();
  const { data } = useUserProfileQuery(localStorage.getItem("token"));
  const [currentUser, setCurrentUser] = useState(localStorage.getItem("user"));
  useEffect(() => {
    setCurrentUser(data?.data.user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const nav = useNavigate();
  const onDropDown = (path) => {
    if (path === "logout") {
      logout().then((res) => {
        if (res.data) {
          toast.success(res?.data?.message);
          localStorage.clear();
          window.location.href = process.env.REACT_APP_FRONT_URL;
        }
      });
    } else {
      nav(path);
    }
  };

  const userNavigation = [
    {
      name: "Setting",
      href: "/setting",
      icon: Cog8ToothIcon,
      accessTo: [1000],
    },

    {
      name: "Log out",
      href: "logout",
      icon: ArrowRightOnRectangleIcon,
      accessTo: [1000, 1001, 1003, 1004],
    },
  ];
  return (
    <>
      <div className="lg:pl-80">
        <nav className="relative flex flex-wrap items-center justify-between px-0 py-2 mx-6  transition-all shadow-none duration-250 ease-soft-in rounded-2xl lg:flex-nowrap lg:justify-start">
          <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
            <AddressBar />
            <div
              className="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto"
              id="navbar"
            >
              <div className="flex items-center md:ml-auto "></div>
              <ul className="flex flex-row justify-end pl-0 mb-0 list-none md-max:w-full  text-slate-500">
                <li className="flex items-center md:ml-auto ">
                  {" "}
                  {currentUser
                    ? loggedUserRole() == "Brokerage"
                      ? currentUser?.brokerage_name || ""
                      : (currentUser?.first_name || currentUser?.last_name) &&
                        currentUser?.first_name + " " + currentUser?.last_name
                    : ""}
                </li>
                <li className="flex items-center cursor-pointer ">
                  <Menu as="div" className="relative">
                    <Menu.Button className="-m-1.5 flex items-center p-1.5">
                      <span className="sr-only">Open user menu</span>

                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-slate-500"
                        aria-hidden="true"
                      >
                        <img
                          src={
                            currentUser?.profile
                              ? currentUser?.profile.startsWith("https://")
                                ? currentUser?.profile
                                : process.env.REACT_APP_UPLOADS +
                                  currentUser?.profile
                              : userImage
                          }
                          className=" inline-flex items-center justify-center w-10 h-10     mr-2 text-white transition-all duration-200 ease-in-out text-sm rounded-circle object-contain"
                        />
                      </span>
                    </Menu.Button>
                    {loggedUserCode() !== 1002 && (
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-990 w-50 mt-2.5 origin-top-right rounded-md bg-white py-2 shadow-lg ring-0 focus:outline-none">
                          {userNavigation.map(
                            (item) =>
                              item?.accessTo?.includes(loggedUserCode()) && (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <span
                                      onClick={() => onDropDown(item.href)}
                                      className={`${
                                        active
                                          ? " "
                                          : "text-gray-800 hover:text-gray-500 "
                                      }
                              group cursor-pointer flex gap-x-3 rounded-r-md p-2.5 text-sm leading-6 font-semibold `}
                                    >
                                      <item.icon
                                        className="h-6 w-6 shrink-0"
                                        aria-hidden="true"
                                      />
                                      <span>{item.name}</span>
                                    </span>
                                  )}
                                </Menu.Item>
                              )
                          )}
                        </Menu.Items>
                      </Transition>
                    )}
                  </Menu>
                </li>
                <li className="flex items-center pl-4 xl:hidden ">
                  <div
                    className="w-8 overflow-hidden lg:hidden cursor-pointer"
                    onClick={() => setSidebarOpen(true)}
                  >
                    <Bars3Icon />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />
      </div>
    </>
  );
}
