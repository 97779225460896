import { Formik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import {
  useUpdateSearchMutation,
  useUserListMutation,
} from "store/Actions/searchHistory";
import * as Yup from "yup";

import Select from "react-select";
import makeAnimated from "react-select/animated";
// Creating schema
const schema = Yup.object({});

const animatedComponents = makeAnimated();
export default function SearchHistoryEdit({ closeModal, id }) {
  const [userOptions, setUserOptions] = useState([]);

  const [userList] = useUserListMutation();
  const handleInputChange = (inputValue) => {
    inputValue &&
      userList(inputValue).then((res) => {
        if (res?.data?.data) {
          setUserOptions(() => []);
          res.data.data?.map((val) => {
            setUserOptions((prev) => [
              ...(prev || []),
              { value: `${val.email}`, label: `${val.email}` },
            ]);
          });
        }
      });
  };
  const [updateSearch, { isLoading }] = useUpdateSearchMutation();

  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={async (values) => {
          const payload = {
            email: values.email.value,
            user_id: id.user_id,
          };
          !isLoading &&
            updateSearch({ id: id.id, payload }).then((res) => {
              if (res.error) {
                toast.error(res.error.data.message);
              } else {
                toast.success(res.data?.message);
                closeModal();
              }
            });
        }}
        validationSchema={schema}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-5">
              <div className="mt-2 flex   justify-evenly w-full ">
                <Select
                  className="basic-multi-select focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg  bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none z-990 overflow-visible "
                  classNamePrefix="select"
                  options={userOptions}
                  noOptionsMessage={() => "No Users Found"}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    animatedComponents,
                  }}
                  onInputChange={handleInputChange}
                  placeholder="Search User"
                  name="email"
                  value={values.email || []}
                  onChange={(value) => setFieldValue("email", value)}
                />
              </div>

              <div className=" flex justify-end gap-3">
                <button
                  className="inline-flex justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-blue-500 hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2  "
                  onClick={closeModal}
                >
                  cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                >
                  Update
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
}
