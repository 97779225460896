// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating settingAdmin by enhancing and injecting endpoints using serverApi
const settingAdmin = serverApi
  .enhanceEndpoints({ tagTypes: ["setting"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of brokers
      settingData: builder.query({
        query() {
          return {
            url: `settings-edit`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: ["setting"],
      }),
      // Mutation endpoint for deleting a broker
      updateSetting: builder.mutation({
        query(body) {
          return {
            url: `settings-update`,
            method: "POST",
            body: body,
          };
        },
        // Invalidates broker tags after deleting a broker
        invalidatesTags: ["setting"],
      }),
    }),
  });

// Destructuring settingAdmin to get individual hooks for components
export const { useSettingDataQuery, useUpdateSettingMutation } = settingAdmin;
