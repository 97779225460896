import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useUpdateUserDetailsMutation,
  useUserProfileQuery,
} from "store/Actions/authAction";
import { statusOptions } from "utils/data";
import * as Yup from "yup";

import SubmitButton from "Components/Butttons/SubmitButton";
import CustomInput from "Components/Input/Input";
import "flatpickr/dist/themes/material_blue.css";
import { loggedUserRole } from "utils/functions";
// Creating schema

const ProfileUserDetails = () => {
  const schema = Yup.object({});
  const [currentUser, setCurrentUser] = useState({});

  const { data, isSuccess } = useUserProfileQuery();
  const [updateUserDetails, { isLoading }] = useUpdateUserDetailsMutation();

  useEffect(() => {
    if (isSuccess) {
      setCurrentUser(data?.data?.user);
    }
  }, [data, isSuccess]);
  return (
    <div className=" flex flex-col visible w-full h-auto min-w-0 p-4 break-words bg-white border-0 opacity-100 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border lg:w-8/12 m-4  ">
      <Formik
        enableReinitialize
        initialValues={{
          phone: currentUser?.phone || "",
          brokerage_name: currentUser?.brokerage_name || "",
          first_name: currentUser?.first_name || "",
          last_name: currentUser?.last_name || "",
          status: currentUser?.status || "Active",
          address_line1: currentUser?.address_line1 || "",
          address_line2: currentUser?.address_line2 || "",
          city: currentUser?.city || "",
          state: currentUser?.state || "",
          zip_code: currentUser?.zip_code || "",
          country: currentUser?.country || "",
        }}
        onSubmit={async (values) => {
          !isLoading &&
            updateUserDetails(values).then((res) => {
              if (res.error) {
                toast.error(res.error.data.message);
              } else {
                toast.success(res.data?.message);
              }
            });
        }}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <div>
                  {/* row 1 */}
                  <div className="flex flex-wrap mt-4 -mx-3">
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <label className="inline-block w-full mb-2 ml-1 font-bold text-slate-700 text-xs">
                        <div className="flex justify-between">
                          <span className="font-semibold tracking-wide text-sm">
                            Role
                          </span>
                        </div>
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        disabled
                        value={data?.data?.role || ""}
                        className="focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                      />
                    </div>
                    {loggedUserRole() === "Brokerage" ? (
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="brokerage_name"
                          values={values}
                          type="text"
                          label="Brokerage Name"
                          placeholder="Brokerage Name"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* row 2 */}
                  <div className="flex flex-wrap mt-4 -mx-3">
                    <div className="w-full max-w-full px-3 mt-4 flex-0 sm:mt-0 sm:w-6/12 ">
                      <label className="inline-block w-full mb-2 ml-1 font-bold text-slate-700 text-xs">
                        <div className="flex justify-between">
                          <span className="font-semibold tracking-wide text-sm">
                            Status
                          </span>
                        </div>
                      </label>
                      <select
                        name="State"
                        disabled
                        className="focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none cursor-not-allowed"
                      >
                        {statusOptions?.map((option) => (
                          <option key={option.name} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomInput
                        name="phone"
                        values={values}
                        type="text"
                        label="phone Number"
                        placeholder="phone Number"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>
                  {/* row 3 */}
                  {loggedUserRole() !== "Brokerage" && (
                    <div className="flex flex-wrap mt-4 -mx-3">
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="first_name"
                          values={values}
                          type="text"
                          label="first name"
                          placeholder="first name"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                      <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                        <CustomInput
                          name="last_name"
                          values={values}
                          type="text"
                          label="last name"
                          placeholder="last name"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>
                  )}
                  {/* Row 4*/}
                  <div className="flex flex-wrap mt-4 -mx-3">
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomInput
                        name="address_line1"
                        values={values}
                        type="text"
                        label="address line1"
                        placeholder="address line1"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomInput
                        name="address_line2"
                        values={values}
                        type="text"
                        label="address line2"
                        placeholder="address line2"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>
                  {/* Row 5 */}
                  <div className="flex flex-wrap mt-4 -mx-3">
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomInput
                        name="city"
                        values={values}
                        type="text"
                        label="city"
                        placeholder="city"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomInput
                        name="zip_code"
                        values={values}
                        type="number"
                        label="zip code"
                        placeholder="zip code"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>
                  {/* Row 6 */}
                  <div className="flex flex-wrap mt-4 -mx-3">
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomInput
                        name="state"
                        values={values}
                        type="text"
                        label="State"
                        placeholder="State"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className="w-full max-w-full px-3 flex-0 sm:w-6/12">
                      <CustomInput
                        name="country"
                        values={values}
                        type="text"
                        label="country"
                        placeholder="country"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>
                  <div className="flex mt-6">
                    <SubmitButton isLoading={isLoading} text="Update" />
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProfileUserDetails;
