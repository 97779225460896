import MiniTAble from "Components/Table/MiniTAble";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAdminVesselsInquiryQuery } from "store/Actions/adminAnalytics";

const VesselsInquires = ({ filterProps, totalCount = 0 }) => {
  //  const {filter,setFilter} = filterProps
  const [filter, setFilter] = useState({
    perPage: 50,
    page: 1,
    start_date: filterProps?.filter?.start_date,
    end_date: filterProps?.filter?.end_date,
  });
  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      end_date: filterProps?.filter?.end_date,
      start_date: filterProps?.filter?.start_date,
    }));
  }, [filterProps.filter.end_date, filterProps?.filter?.start_date]);
  const { vesselId } = useParams();
  const { data, isFetching } = useAdminVesselsInquiryQuery({
    vesselId,
    filter,
  });
  const columns = useMemo(
    () => [
      {
        header: "Vessel ID",
        accessor: "data.vesselid",
      },
      {
        header: "Contact  Details",
        accessor: "data.email",

        Cell: ({ cell: { row } }) => {
          return (
            <div className=" flex flex-wrap">
              {row.original.data.fullname && (
                <span
                  className={` py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-semibold bg-slate-200 text-slate-700  leading-none m-1 flex flex-wrap w-fit`}
                >
                  {row.original.data.fullname}
                </span>
              )}
              {/* {row.original.data.email && <a href={`mailto: ${row.original.data.email}`} className="normal-case py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-semibold bg-slate-200 text-slate-700  leading-none m-1 flex flex-wrap w-fit ">{row.original.data.email}</a>}
                  {row.original.data.phone && <a href={`tel: ${row.original.data.phone}`} className={` py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-semibold bg-slate-200 text-slate-700  leading-none m-1 flex flex-wrap w-fit`} >{row.original.data.phone}</a>}  */}
            </div>
          );
        },
      },
      {
        header: "Contacted At",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => {
          return (
            <span className={``}>
              {moment(value).format("MM-DD-YYYY HH:mm:ss")}
            </span>
          );
        },
      },
      {
        header: "Message",
        accessor: "data.message",
        Cell: ({ cell: { value } }) => {
          return (
            <div className=" w-120 whitespace-pre-wrap">
              {/* <div className="break-words w-25"></div> */}
              {value}
            </div>
          );
        },
      },
      {
        header: " Brokerage Name",
        accessor: "data.brokeragename",
      },
      {
        header: " Broker Name",
        accessor: "data.brokername",
      },
      // {
      //   header: "more info",
      //   accessor: "count",

      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <div className="text-lg text-blue-800 flex justify-between px-3">
        <span> Vessels Inquires</span>
        <span>{totalCount}</span>
      </div>
      <div>
        <MiniTAble
          ListQuery={{ data: data?.data, isLoading: isFetching }}
          filterProps={{ filter: filter, setFilter: setFilter }}
          columns={columns}
          sortingField={["created_at"]}
          SearchId={true}
          searchPlaceHolder="Vessel Id"
          // searchFields={["vessel_name", "ip_address"]}
        />
      </div>
    </div>
  );
};

export default VesselsInquires;
