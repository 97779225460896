import SubmitButton from "Components/Butttons/SubmitButton";
import CustomSelect from "Components/Input/CustomSelect";
import ComponentLoader from "Components/Loader/ComponentLoader";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import makeAnimated from "react-select/animated";
import {
  useSettingDataQuery,
  useUpdateSettingMutation,
} from "store/Actions/setting";
import { remainingWords } from "utils/functions";

const animatedComponents = makeAnimated();

const SettingForm = () => {
  const { data, isLoading } = useSettingDataQuery();
  const [updateSetting, { isLoading: updateLoading }] =
    useUpdateSettingMutation();
  const [lessPriorityOptions, setLessPriorityOptions] = useState([]);
  const [initialVal, setInitialVal] = useState({});
  useEffect(() => {
    setLessPriorityOptions(() => []);
    data?.data?.allBrokerage?.map((val) => {
      setLessPriorityOptions((lessPriorityOptions) => [
        ...lessPriorityOptions,
        { value: val.ID, label: val.BrokerageName },
      ]);
    });
    setInitialVal(() => ({}));
    Object.keys(data?.data?.categoriesImage || {}).map((id) => {
      setInitialVal((prev) => ({
        ...prev,
        [`category_${id}_category_image`]:
          data?.data?.categoriesImage[id].category_image || "",
        [`category_${id}_meta_title`]:
          data?.data?.categoriesImage[id].category_image || "",
        [`category_${id}_meta_description`]:
          data?.data?.categoriesImage[id].category_image || "",
      }));
    });
  }, [data]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        less_priority_brokerage: lessPriorityOptions?.filter((val) =>
          data?.data?.settings?.less_priority_brokerage
            .split(",")
            .includes(`${val.value}`),
        ),
        home_banner: data?.data?.settings?.home_banner || "",
        ...initialVal,
      }}
      onSubmit={async (values) => {
        values.less_priority_brokerage = values.less_priority_brokerage
          .map((obj) => obj.value)
          .join(",");

        Object.keys(values).map((val) => {
          if (val.includes("meta_title")) {
            values = { ...values, [val]: values[val].substring(0, 70) };
          } else if (val.includes("meta_description")) {
            values = { ...values, [val]: values[val].substring(0, 160) };
          }
        });
        !updateLoading &&
          updateSetting(values).then((res) => {
            if (res.error) {
              toast.error(res.error.data.message);
            } else {
              toast.success(res.data?.message);
            }
          });
      }}

      //   validationSchema={schema}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
        return (
          <>
            {" "}
            {isLoading ? (
              <ComponentLoader />
            ) : (
              <form onSubmit={handleSubmit}>
                <div
                  form="user"
                  className="absolute top-0 left-0 flex flex-col visible w-full h-auto  p-6 break-words bg-white border-0 opacity-100 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border  overflow-auto min-w-full md:max-h-85-screen "
                >
                  <h5 className="mb-0 font-bold dark:text-white text-xl">
                    Superuser Settings
                  </h5>
                  <div>
                    {/* row 0 */}
                    <div className="flex flex-wrap mt-4 -mx-3">
                      <div className="w-full max-w-full px-3 flex-0 md:w-6/12">
                        <label className="inline-block w-full mb-2 ml-1 font-bold text-slate-700 text-xs">
                          <div className="flex justify-between">
                            <span className="font-semibold tracking-wide text-sm">
                              Less Priority Brokerage
                            </span>
                          </div>
                        </label>
                        {/* <Select
                          isMulti
                          options={lessPriorityOptions}
                        
                          name="less_priority_brokerage"
                          value={values.less_priority_brokerage||[]}
                          onChange={(value) =>
                            setFieldValue("less_priority_brokerage", value)
                          }
                          // components={animatedComponents}
                          className="basic-multi-select focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg  bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none  "
                          classNamePrefix="select"
                          placeholder="Brokerage"
                          // onChange={selectBroker}
                        /> */}
                        <CustomSelect
                          values={values}
                          options={lessPriorityOptions}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            animatedComponents,
                          }}
                          isMulti={true}
                          changeEvent={(value) =>
                            setFieldValue("less_priority_brokerage", value)
                          }
                          placeholder="Less Priority Brokerage"
                          name="less_priority_brokerage"
                        />
                      </div>
                      <div className="w-full max-w-full px-3 flex-0 md:w-6/12">
                        <label className="inline-block w-full mb-2 ml-1 font-bold text-slate-700 text-xs">
                          <div className="flex justify-between">
                            <span className="font-semibold tracking-wide text-sm">
                              Home Page Banner URL
                            </span>
                          </div>
                        </label>
                        <input
                          type="text"
                          placeholder="Example: https://www.yachtbroker.org/asset/img/main-sliders/home-1/banner1.webp"
                          id="home_banner"
                          name="home_banner"
                          value={values.home_banner}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className="focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                        />
                      </div>
                    </div>
                    <h5 className="mb-0 mt-5 font-bold dark:text-white text-xl">
                      Categories Image
                    </h5>
                    <div className="flex flex-wrap mt-4 -mx-3">
                      {/* map for all */}
                      {data?.data?.allCategories.map((catg) =>
                        Object.keys(catg || {})?.map((type) =>
                          Object.keys(catg[type] || {})?.map((val, key) => {
                            let img = "";
                            let title = "";
                            let description = "";

                            Object.keys(data?.data?.categoriesImage || {}).map(
                              (vessel) => {
                                if (
                                  data?.data?.categoriesImage[vessel]
                                    .category_name === `${type}_${val}`
                                ) {
                                  img = `category_${vessel}_category_image`;
                                  title = `category_${vessel}_meta_title`;
                                  description = `category_${vessel}_meta_description`;
                                }
                              },
                            );

                            return (
                              data?.data?.categoriesImage && (
                                <div
                                  className="w-full max-w-full py-2 px-3 flex-0 md:w-6/12"
                                  key={key}
                                >
                                  <label className="inline-block w-full mb-2 ml-1 font-bold text-slate-700 text-xs">
                                    <div className="flex justify-between">
                                      <span className="font-semibold tracking-wide text-sm">
                                        {` ${type} ${val} Image URL`}
                                      </span>
                                    </div>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Example: https://www.yachtbroker.org/asset/img/main-sliders/home-1/banner1.webp"
                                    id={img}
                                    name={img}
                                    value={values[img] || ""}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                                  />
                                  <div className="flex flex-wrap">
                                    <div className="w-full max-w-full py-2 pe-0 sm:pe-3 flex-0 sm:w-6/12">
                                      <label className="inline-block w-full mb-2 ml-1 font-bold text-slate-700 text-xs">
                                        <div className="flex justify-between">
                                          <span className="font-semibold tracking-wide text-sm">
                                            meta title
                                            <span
                                              className={`text-xs font-normal ${
                                                remainingWords(
                                                  values[title] || "",
                                                  70,
                                                ).val
                                                  ? ""
                                                  : "text-red-500"
                                              }`}
                                            >
                                              {" "}
                                              :
                                              {
                                                remainingWords(
                                                  values[title] || "",
                                                  70,
                                                ).text
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      </label>
                                      <input
                                        type="text"
                                        placeholder="meta title goes here"
                                        id={title}
                                        name={title}
                                        value={values[title] || ""}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                                      />
                                    </div>
                                    <div className="w-full max-w-full py-2 ps-0 sm:ps-3 flex-0 sm:w-6/12">
                                      <label
                                        className="mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                        htmlFor="Bio"
                                      >
                                        meta description
                                        <span
                                          className={`text-xs font-normal ${
                                            remainingWords(
                                              values[description] || "",
                                              160,
                                            ).val
                                              ? ""
                                              : "text-red-500"
                                          }`}
                                        >
                                          {" "}
                                          :
                                          {
                                            remainingWords(
                                              values[description] || "",
                                              160,
                                            ).text
                                          }
                                        </span>
                                      </label>
                                      <textarea
                                        id={description}
                                        name={description}
                                        value={values[description] || ""}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        rows="2"
                                        placeholder="meta description goes here...."
                                        className="focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80  text-sm leading-5.6 ease-soft block h-auto w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none max-h-40 min-h-10"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              )
                            );
                          }),
                        ),
                      )}
                    </div>

                    <div className="flex justify-end gap-3 flex-wrap">
                      <div className="flex mt-6">
                        <SubmitButton isLoading={updateLoading} text="Submit" />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default SettingForm;
